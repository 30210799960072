<script setup lang="ts">
    import { RenderResultErrors } from '@/components';
    import { useCommand } from '@/features/api';
    import { useAdministerableAppRoles } from '@/features/auth';
    import CompanyLiveSearchAutocomplete from '@/features/companies/components/CompanyLiveSearchAutocomplete.vue';
    import { useCompany } from '@/features/companies/hooks';
    import { useDialogWidth } from '@/shared/hooks';
    import { computed, ref } from 'vue';

    const modelValue = defineModel<boolean>();
    const dialogWidth = useDialogWidth({ xs: 1, sm: 0.8, md: 0.6, lg: 0.6, xl: 0.5 });

    const form = ref<VuetifyForm>();
    const formValid = computed(() => form.value?.isValid);

    const name = ref('');
    const email = ref('');
    const selectedCompany = ref<number | null>(null);
    const role = ref('');

    const { company } = useCompany(selectedCompany);
    const { selectRoles } = useAdministerableAppRoles();
    const emailDomainMatches = computed(() => {
        if (!email.value) return true;
        if (!company.value) return true;
        if (rules.value.email[1](email.value) !== true) return true;

        return company.value.domainNames.some((domain) => email.value.endsWith(`@${domain}`));
    });

    const {
        mutateAsync,
        isLoading: saveLoading,
        error: saveError,
    } = useCommand('users/INVITE_USER', {
        invalidates: ['users/'],
        onSettled(data, error) {
            if (!error) {
                modelValue.value = false;
            }
        },
    });

    const rules = ref({
        name: [(v: string) => !!v || 'Name is required'],
        email: [
            (v: string) => !!v || 'E-mail is required',
            (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        role: [(v: string) => !!v || 'Role is required'],
        company: [(v: number | null) => !!v || 'Company is required'],
    });

    async function invite() {
        console.log(form.value);
        if (!(await form.value?.validate())?.valid) return;
        if (!selectedCompany.value) return;

        void mutateAsync({
            request: {
                name: name.value,
                email: email.value,
                role: role.value,
            },
            params: {
                companyId: selectedCompany.value,
            },
        });
    }

    function cancel() {
        modelValue.value = false;
    }
</script>

<template>
    {{ selectRoles }}
    <v-dialog
        v-model="modelValue"
        persistent
        :max-width="dialogWidth"
        :fullscreen="$vuetify.display.xs"
        eager
        @keydown.esc="cancel"
    >
        <v-card :loading="saveLoading" color="surface-container">
            <v-card-title class="text-center my-3">
                Invite User
            </v-card-title>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="name"
                                color="primary"
                                label="Full Name"
                                variant="outlined"
                                :rules="rules.name"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="email"
                                type="email"
                                :color="emailDomainMatches ? 'primary' : 'warning'"
                                label="Email"
                                variant="outlined"
                                :focused="!emailDomainMatches"
                                :rules="rules.email"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <CompanyLiveSearchAutocomplete v-model="selectedCompany" :rules="rules.company" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="role"
                                :items="selectRoles"
                                color="primary"
                                label="Role"
                                variant="outlined"
                                :rules="rules.role"
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn text="Close" color="grey" @click="cancel" />
                <v-btn text="Submit" color="primary" :loading="saveLoading" :disabled="!formValid" @click="invite" />
            </v-card-actions>
            <v-alert v-if="!emailDomainMatches" type="warning" color="primary" dismissible>
                <span>
                    The email domain does not match the selected company. Are you sure you want to invite this user?
                </span>
            </v-alert>
            <RenderResultErrors :result="saveError" />
        </v-card>
    </v-dialog>
</template>
