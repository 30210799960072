import { useApi } from '@/features/api';
import type { Ref } from 'vue';
import { computed, toValue } from 'vue';

const STALE_TIME = 30000;

export function useOrderRun(orderRunId: Ref<Nullish<string>>) {
    const enabled = computed(() => !!toValue(orderRunId));

    const { isLoading, data, error } = useApi(['orderRuns/GET_RUN', orderRunId], (endpoint, fetcher) => {
        return fetcher(endpoint, {
            runId: toValue(orderRunId)!,
        });
    }, { staleTime: STALE_TIME, enabled });

    return {
        isLoading,
        orderRun: data,
        error,
    };
}
