<script setup lang="ts">
    import type { ScreenResponse } from '../api';

    type Props = {
        onClose: () => void;
        screen: ScreenResponse;
    };

    const props = defineProps<Props>();
</script>

<template>
    <v-card density="compact">
        <v-card-title class="text-center py-1 text-secondary-lighten-2">
            <div class="text-body-1">
                {{ props.screen.title }}
            </div>
            <div v-if="props.screen.screenLocation" class="text-caption text-secondary">
                ({{ props.screen.screenLocation }})
            </div>
        </v-card-title>
        <v-divider :thickness="3" />

        <v-card-text class="text-center py-2">
            {{ props.screen.excerpt }}
        </v-card-text>
    </v-card>
</template>
