import { useBudgetablePlays } from '@/features/budget/hooks';
import type { FlightDurationParams } from '@/features/pricing/hooks';
import { useOrderCost } from '@/features/pricing/hooks';
import { resolveDuration } from '@/features/pricing/hooks/utils';
import type { Ref } from 'vue';
import { computed, toRef, toRefs } from 'vue';
import { useNumberOfPlaysRange } from './useNumberOfPlaysRange';

interface Props {
    companyId: number | undefined | null;
    campaignId: string | undefined | null;
    screenIds: Nullish<number[]>;
    adDuration: number | null | undefined;
    playsPerMonth: number | null | undefined;
    flightTime: FlightDurationParams | null;
    constrainToBudget: boolean;
}

export function useBudgetedOrderCostsAndPlays(props: Ref<Props>) {
    const { adDuration, constrainToBudget, flightTime } = toRefs(props.value);
    const { monthlyCost, rawMonthlyCost } = useOrderCost(props);
    const { minPlays, maxPlays } = useNumberOfPlaysRange(toRef(() => props.value.screenIds));
    const { isLoading: budgetLoading, playsLimits, hasBudget, hasPlaysLimits } = useBudgetablePlays(props);

    const months = computed(() => {
        if (!flightTime.value) return 0;
        return resolveDuration(flightTime.value);
    });

    const monthsExact = computed(() => {
        if (!flightTime.value) return 0;
        return resolveDuration(flightTime.value, true);
    });

    const constrainedMaxPlays = computed(() => {
        if (!adDuration.value) return maxPlays.value;
        if (!hasPlaysLimits.value) return maxPlays.value;
        if (!constrainToBudget.value) return maxPlays.value;

        return Math.min(getPlayLimit(adDuration.value), maxPlays.value);
    });

    // Ad durations which are not selectable because they are below the minimum number of plays
    const disabledDurations = computed(() => {
        if (!hasPlaysLimits.value || !minPlays.value) return [];

        const disabled = [];

        for (const [duration, _] of Object.entries(playsLimits.value)) {
            if (getPlayLimit(Number(duration)) < minPlays.value) {
                disabled.push(Number(duration));
            }
        }

        return disabled;
    });

    const maxPlaysPerDuration = computed(() => {
        if (!hasPlaysLimits.value) return {};

        const maxPlaysPerDuration: Record<number, number> = {};

        for (const [duration, _] of Object.entries(playsLimits.value)) {
            maxPlaysPerDuration[Number(duration)] = getPlayLimit(Number(duration));
        }

        return maxPlaysPerDuration;
    });

    const maxPlaysConstrainedBy = computed(() => {
        if (!constrainToBudget.value) return 'screen-count';
        if (maxPlays.value === constrainedMaxPlays.value) return 'budget';

        return 'budget';
    });

    function getPlayLimit(duration: number) {
        if (!hasPlaysLimits.value) return 0;

        const limit = (playsLimits.value as Record<number, number>)[duration];
        if (!limit) return 0;

        return Math.min(Math.round(limit / 1000) * 1000, maxPlays.value);
    }

    const isLoading = computed(() => budgetLoading.value);

    return {
        /** Flight time in months as an integer */
        months,

        /** Flight time in months as a float */
        monthsExact,
        monthlyCost,
        rawMonthlyCost,
        maxPlays: constrainedMaxPlays,
        minPlays,
        maxPlaysConstrainedBy,
        maxPlaysPerDuration,
        disabledDurations,
        hasBudget,
        hasPlaysLimits,
        isLoading,
    };
}
