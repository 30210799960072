import dayjs from 'dayjs';

type MonthParams = {
    /** The already calculated number of months */
    months: number;
};

type RangeParams = {
    /** The raw duration of the order */
    startDate: number;
    endDate: number;
};

export type FlightDurationParams = MonthParams | RangeParams;

function isMonthParams(params: MonthParams | FlightDurationParams): params is MonthParams {
    return (params as MonthParams).months !== undefined;
}

export function resolveDuration(params: FlightDurationParams, float: boolean = false) {
    if (isMonthParams(params)) {
        return params.months;
    } else {
        const start = new Date(params.startDate);
        const end = new Date(params.endDate);

        return dayjs(end).diff(dayjs(start), 'month', float);
    }
}
