<script setup lang="ts">
    import { RenderResultErrors } from '@/components';
    import { useCommand } from '@/features/api';
    import { FileStatusChip } from '@/features/files/components';
    import { useStartProcess } from '@/shared/hooks';
    import type { SimpleResult } from '@/shared/types';
    import { computed, toRef, watchEffect } from 'vue';
    import { toast } from 'vuetify-sonner';
    import { useCreativeProcessingStatus } from '../../hooks';

    type Props = {
        creativeId: string | null | undefined;
        s3FileId: string | null;
        start: boolean;
    };

    type Emits = {
        processingComplete: [value: boolean];
    };

    const props = defineProps<Props>();
    const emits = defineEmits<Emits>();

    const {
        isLoading: queueingLoading,
        error: queueError,
        isSuccess: queueSuccess,
        mutateAsync: queueCreativeProcessing,
    } = useCommand('creatives/QUEUE_CREATIVE_PROCESSING');

    const processingArgs = computed(() => {
        if (!props.creativeId) return { creativeId: null, enabled: false };

        return {
            creativeId: props.creativeId,
            enabled: props.start,
        };
    });

    const { creativeStatus, fileStatus, creativeStatusFinished, error: statusCheckError } = useCreativeProcessingStatus(
        processingArgs,
    );

    const isLoading = computed(() => {
        if (queueingLoading.value) return true;
        if (queueSuccess.value && !creativeStatusFinished.value) return true;
        return false;
    });
    const error = computed(() => {
        if (queueError.value) return queueError.value;
        if (statusCheckError.value) return statusCheckError.value;
        if (creativeStatus.value === 'Error') {
            return {
                success: false,
                message: 'Creative processing failed. Please try again.',
            } as SimpleResult;
        }

        return null;
    });

    useStartProcess(toRef(() => props.start), () => {
        if (queueSuccess.value) return;
        void startProcessFile();
    });

    async function startProcessFile() {
        if (!props.creativeId) throw new Error('creativeId is required');

        await queueCreativeProcessing({ params: { creativeId: props.creativeId } });
    }

    watchEffect(() => {
        if (creativeStatusFinished.value) {
            toast.success('Creative Processed');
            emits('processingComplete', true);
        }
    });
</script>

<template>
    <v-list-item v-if="isLoading">
        <template v-slot:prepend>
            <v-progress-circular size="38" indeterminate color="primary" />
            <v-icon v-show="false" class="i-exist-for-layout" size="40" />
        </template>
        <v-list-item-title>Process File <FileStatusChip :status="fileStatus" /> </v-list-item-title>
    </v-list-item>

    <v-list-item v-else-if="queueSuccess">
        <template v-slot:prepend>
            <v-icon icon="mdi-check-circle-outline" color="success" size="40" />
        </template>
        <v-list-item-title>Process File</v-list-item-title>
    </v-list-item>

    <v-list-item v-else-if="error" class="w-fit">
        <template v-slot:prepend>
            <v-icon icon="mdi-close-circle-outline" color="error" size="40" />
        </template>
        <template v-slot:append>
            <v-btn
                color="primary"
                append-icon="mdi-redo"
                variant="tonal"
                class="opacity-100 ml-4"
                @click="startProcessFile"
            >
                Retry
            </v-btn>
            <RenderResultErrors :result="error" />
        </template>
        <v-list-item-title>File Processing Failed</v-list-item-title>
    </v-list-item>

    <v-list-item v-else>
        <template v-slot:prepend>
            <v-icon icon="mdi-numeric-2-circle-outline" size="40" />
        </template>
        <v-list-item-title>Process File</v-list-item-title>
    </v-list-item>
</template>
