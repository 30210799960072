<script setup lang="ts">
    import type { FileMediaKind } from '@/features/files';
    import { computed } from 'vue';

    type Props = {
        value: FileMediaKind | undefined;
        shade?: 'light' | 'dark' | undefined;
        variant?: 'flat' | 'text' | 'elevated' | 'tonal' | 'outlined' | 'plain';
        size?: 'x-large' | 'large' | 'default' | 'small' | 'x-small';
    };

    const props = withDefaults(defineProps<Props>(), {
        shade: undefined,
        variant: 'outlined',
        size: 'default',
    });

    const icon = computed(() => {
        switch (props.value) {
            case 'Image':
                return 'mdi-image-outline';
            case 'Video':
                return 'mdi-filmstrip';
            default:
                return 'mdi-file-question-outline';
        }
    });

    const color = computed(() => {
        let shade = '';
        if (props.shade) {
            shade = props.shade === 'light' ? '-lighten-2' : '-darken-2';
        }

        switch (props.value) {
            case 'Image':
                return `green${shade}`;
            case 'Video':
                return `blue${shade}`;
            default:
                return `grey${shade}`;
        }
    });
</script>

<template>
    <v-chip :color="color" :prepend-icon="icon" :variant="variant" :size="size">
        {{ props.value }}
    </v-chip>
</template>
