<script setup lang="ts">
    import { CompanyLiveSearchAutocomplete } from '@/features/companies/components';
    import { FileDropZone } from '@/features/files/components';
    import type { FileDropzoneModel } from '@/features/files/components/types';

    const companyId = defineModel<number>('companyId', { default: null });
    const fileModel = defineModel<FileDropzoneModel | null>('fileModel', { default: null });
</script>

<template>
    <v-row justify="center">
        <v-col cols="12" md="6">
            <CompanyLiveSearchAutocomplete v-model="companyId" label="Company" hide-details />
        </v-col>
        <v-col cols="12">
            <FileDropZone v-model="fileModel" />
        </v-col>
    </v-row>
</template>

<style scoped lang="scss">
:deep(.uploading-overlay .v-overlay__scrim) {
    opacity: 0.9;
}

.absolute-top-left {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
</style>
