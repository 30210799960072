import { useDropZone, useFileDialog } from '@vueuse/core';
import type { MaybeRefOrGetter } from 'vue';
import { computed, ref } from 'vue';

const fileTypes = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4'];

interface Props {
    target: MaybeRefOrGetter<HTMLElement | null | undefined>;
    onFileInput?: (file: File) => void;
    onFileClear?: () => void;
}

export function useFileSelect({ target, onFileInput, onFileClear }: Props) {
    const files = ref<File[]>([]);
    const fileObjectUrl = ref<string | null>(null);

    const {
        open: openFileDialog,
        reset,
        onChange,
    } = useFileDialog({
        accept: fileTypes.join(','),
        multiple: false,
        directory: false,
    });

    const { isOverDropZone } = useDropZone(target, {
        dataTypes: fileTypes,
        onDrop: (droppedFiles) => {
            setFiles(droppedFiles);
        },
    });

    onChange((fileList) => {
        if (!fileList) return;
        setFiles(Array.from(fileList));
    });

    const file = computed(() => files.value[0] || null);

    function clear() {
        reset();
        files.value = [];
        if (fileObjectUrl.value) {
            URL.revokeObjectURL(fileObjectUrl.value);
        }

        onFileClear?.();
    }

    function setFiles(inputFiles: File[] | null) {
        files.value = inputFiles || [];

        if (fileObjectUrl.value) {
            URL.revokeObjectURL(fileObjectUrl.value);
        }

        if (inputFiles && inputFiles.length > 0) {
            fileObjectUrl.value = URL.createObjectURL(inputFiles[0]);
        }

        if (onFileInput) {
            onFileInput(files.value[0] || null);
        }
    }

    return {
        openFileDialog,
        isOverDropZone,
        file,
        fileObjectUrl,
        clear,
    };
}
