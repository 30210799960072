import {
    abbreviateNumber,
    formatCurrency,
    formatDate,
    formatNumber,
    formatPercent,
    formatTimespan,
    formatTimespanDays,
    pluralize,
    prettyBytes,
} from '@/shared/formatters';
import type { App } from 'vue';

export const filtersPlugin = {
    install(app: App) {
        app.config.globalProperties.$filters = {
            number: formatNumber,
            percent: formatPercent,
            currency: formatCurrency,
            pluralize: pluralize,
            abbreviate: abbreviateNumber,
            date: formatDate,
            bytes: prettyBytes,
            timeSpan: formatTimespan,
            timeDays: formatTimespanDays,
        };
    },
};
