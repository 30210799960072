<script setup lang="ts">
    import { toRef } from 'vue';
    import type { InvoiceStatus } from '../api/invoiceApiResponseModels';
    import { useInvoiceStatusUiConfig } from '../hooks';

    type Props = {
        status: InvoiceStatus;
    };

    const props = defineProps<Props>();
    const config = useInvoiceStatusUiConfig(toRef(() => props.status));
</script>

<template>
    <v-chip :color="config.color" size="small" rounded="md" :prepend-icon="config.icon" x-small>
        {{ config.text }}
    </v-chip>
</template>
