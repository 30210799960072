import { useApi } from '@/features/api/hooks';
import type { Ref } from 'vue';
import { computed, toValue } from 'vue';

const STALE_TIME = 1000 * 60; // 1 minute

export function useCreativeDownloadUrl(
    creativeId: string | undefined | Ref<string | undefined>,
    isDownload: boolean,
    enabled: Ref<boolean> | undefined = undefined,
) {
    const isEnabled = computed(() => {
        if (enabled === undefined) return true;
        if (!toValue(creativeId)) return false;

        return toValue(enabled);
    });

    const endpoint = isDownload ? 'creatives/GET_DOWNLOAD_URL' : 'creatives/GET_VIEW_URL';

    const { isLoading, data, error } = useApi([endpoint, creativeId], (endpoint, fetcher) => {
        return fetcher(endpoint, { creativeId: toValue(creativeId)! });
    }, { keepPreviousData: true, staleTime: STALE_TIME, enabled: isEnabled });

    return {
        isLoading,
        error,
        url: data,
    };
}
