<script setup lang="ts">
    import { useFileUploader } from '@/features/files/hooks';
    import { useStartProcess } from '@/shared/hooks';
    import { toRef, watch } from 'vue';
    import type { NewCreativeResponse } from '../../api';

    type Props = {
        file: File;
        initialCreative: NewCreativeResponse | null;
        companyId: number;
        start: boolean;
    };

    type Emits = {
        'update:fileId': [value: string];
        uploadComplete: [value: boolean];
        isUploading: [value: boolean];
    };

    const props = defineProps<Props>();
    const emits = defineEmits<Emits>();

    const {
        uploadFile,
        cancelUpload,
        uploadProgress,
        uploadSpeed,
        uploadState,
        fileId,
        isUploading,
        getPresignedUrlError,
    } = useFileUploader({
        companyId: props.companyId,
        onSuccess: () => {
            emits('uploadComplete', true);
        },
    });

    // When start changes to true start the upload process
    useStartProcess(toRef(() => props.start), () => {
        void startUpload();
    });

    watch(() => isUploading.value, (newVal) => {
        emits('isUploading', newVal);
    });

    watch(() => getPresignedUrlError.value, (newVal) => {
        if (newVal) {
            emits('isUploading', false);
        }
    });

    watch(() => fileId.value, (newVal) => {
        if (!newVal) return;

        emits('update:fileId', newVal);
    });

    function startUpload() {
        if (!props.initialCreative) {
            throw new Error('Initial Creative is required to start upload');
        }
        void uploadFile(props.file, props.initialCreative.presignedUrl);
    }
</script>

<template>
    <v-list-item v-if="getPresignedUrlError" class="w-fit">
        <template v-slot:prepend>
            <v-icon icon="mdi-close-circle-outline" color="error" size="40" />
        </template>
        <template v-slot:append>
            <v-btn color="primary" append-icon="mdi-redo" variant="tonal" class="opacity-100 ml-4" @click="startUpload">
                Retry
            </v-btn>
        </template>
        <v-list-item-title>Failed to Get Upload URL</v-list-item-title>
    </v-list-item>
    <v-list-item v-else-if="uploadState === 'started'" class="w-fit">
        <template v-slot:prepend>
            <v-progress-circular size="38" :model-value="uploadProgress" color="primary" class="mb-2" />
            <v-icon v-show="false" class="i-exist-for-layout" size="40" />
        </template>
        <template v-slot:append>
            <v-btn
                color="red"
                append-icon="mdi-close-circle-outline"
                variant="tonal"
                class="ml-4"
                @click="cancelUpload"
            >
                Cancel
            </v-btn>
        </template>

        <v-list-item-title>
            Uploading File ({{ uploadProgress }}%)
        </v-list-item-title>
        <v-list-item-subtitle>
            {{ $filters.bytes(uploadSpeed) }}/s
        </v-list-item-subtitle>
    </v-list-item>

    <v-list-item v-else-if="uploadState === 'failed'" class="w-fit">
        <template v-slot:prepend>
            <v-icon icon="mdi-close-circle-outline" color="error" size="40" />
        </template>
        <template v-slot:append>
            <v-btn color="primary" append-icon="mdi-redo" variant="tonal" class="opacity-100 ml-4" @click="startUpload">
                Retry
            </v-btn>
        </template>
        <v-list-item-title>Upload Failed</v-list-item-title>
    </v-list-item>

    <v-list-item v-else-if="uploadState === 'aborted'" class="w-fit">
        <template v-slot:prepend>
            <v-icon icon="mdi-close-circle-outline" color="error" size="40" />
        </template>
        <template v-slot:append>
            <v-btn color="primary" append-icon="mdi-redo" variant="tonal" class="opacity-100 ml-4" @click="startUpload">
                Restart
            </v-btn>
        </template>
        <v-list-item-title>Upload Cancelled</v-list-item-title>
    </v-list-item>

    <v-list-item v-else-if="uploadState === 'successful'" class="w-fit">
        <template v-slot:prepend>
            <v-icon icon="mdi-check-circle-outline" color="success" size="40" />
        </template>

        <v-list-item-title>
            Upload File
        </v-list-item-title>
    </v-list-item>

    <v-list-item v-else class="w-fit">
        <template v-slot:prepend>
            <v-icon icon="mdi-numeric-1-circle-outline" size="40" />
        </template>

        <v-list-item-title>
            Upload File
        </v-list-item-title>
    </v-list-item>
</template>
