<script setup lang="ts">
    import { useCommand } from '@/features/api/hooks/useCommand';
    import { useQueryInvalidation } from '@/features/api/hooks/useQueryInvalidation';
    import { useDialogWidth } from '@/shared/hooks';
    import { onMounted, ref } from 'vue';
    import StripeCheckout from './StripeCheckout.vue';

    type Props = {
        companyId: number;
    };

    const props = defineProps<Props>();

    const modelValue = defineModel<boolean>();
    const dialogWidth = useDialogWidth({ md: 1, lg: 1, xl: 1 });

    const clientSecret = ref<string | null>(null);

    const invalidate = useQueryInvalidation();
    const { mutateAsync: getPaymentSession, isLoading: paymentSessionLoading } = useCommand(
        'billing/GET_PAYMENT_SETUP_SESSION',
        {
            onSuccess: (result) => {
                clientSecret.value = result.clientSecret;
            },
        },
    );

    function close() {
        modelValue.value = false;
    }

    function completed() {
        void invalidate('billing/GET_PAYMENT_METHODS');
        close();
    }

    onMounted(() => {
        void getPaymentSession({
            params: {
                companyId: props.companyId,
            },
        });
    });
</script>
<template>
    <v-dialog v-model="modelValue" :width="dialogWidth" max-width="550px" max-height="100vh" scrollable>
        <v-card :loading="paymentSessionLoading" class="bg-surface-container" rounded="md">
            <v-card-title class="pb-0 d-flex align-center justify-space-between">
                Add Payment Method
            </v-card-title>
            <v-card-text style="min-height: 50vh" class="bg-surface d-flex flex-column">
                <StripeCheckout v-if="clientSecret" :client-secret="clientSecret" @complete="completed" />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="grey" size="large" @click="close">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
