import { useApi } from '@/features/api/hooks';
import { expandBoundingBox, getBoundingBoxCenter, makeNormalizedBoundingBox } from '@/features/gis';
import { computed } from 'vue';

const STALE_TIME = 5 * 60 * 1000; // 5mins

export function useScreensBounds() {
    const { isLoading, data } = useApi('screens/GET_SCREENS_BOUNDS', (endpoint, fetcher) => {
        return fetcher(endpoint);
    }, { staleTime: STALE_TIME });

    const center = computed(() => {
        if (!data.value) return null;

        return getBoundingBoxCenter(data.value);
    });

    // Expand boundary by 10Km
    const bounds = computed(() => {
        if (!data.value) return null;

        return expandBoundingBox(makeNormalizedBoundingBox(data.value), 50000);
    });

    return {
        isLoading,
        bounds,
        center,
    };
}
