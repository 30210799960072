export const formatNumber = (num: Nullish<number | string>, significantDigits: number = 0) => {
    if (num === undefined || num === null) {
        return num;
    }
    let number = num;
    if (typeof number === 'string') {
        number = Number.parseFloat(number);
        if (isNaN(number)) {
            return num;
        }
    }

    if (significantDigits > 0) {
        const digits = Math.pow(10, significantDigits);
        number = Math.round(number * digits) / digits;
    } else {
        number = Math.round(number);
    }

    return number.toLocaleString(undefined, { maximumFractionDigits: significantDigits || undefined });
};

export const formatPercent = (num: Nullish<number | string>) => {
    if (num === undefined || num === null) {
        return num;
    }
    let number = num;
    if (typeof number === 'string') {
        number = Number.parseFloat(number);
        if (isNaN(number)) {
            return num;
        }
    }

    return number.toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 2 });
};

export const abbreviateNumber = (num?: number, significantDigits: number = 0) => {
    if (num === undefined) {
        return num;
    }
    let number = num;
    if (typeof number === 'string') {
        number = Number.parseInt(number);
        if (isNaN(number)) {
            return num;
        }
    }

    if (number > 999999) {
        return formatNumber(number / 1000000, significantDigits) + 'M';
    } else if (number > 999) {
        return formatNumber(number / 1000, significantDigits) + 'K';
    }

    return formatNumber(number, significantDigits);
};

export function prettyBytes(num: number) {
    // jacked from: https://github.com/sindresorhus/pretty-bytes
    if (typeof num !== 'number' || isNaN(num)) {
        return 'N/A';
    }

    let exponent = 0;
    let unit = '';
    const neg = num < 0;
    const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    if (neg) {
        num = -num;
    }

    if (num < 1) {
        return (neg ? '-' : '') + num + ' B';
    }

    exponent = Math.min(Math.floor(Math.log(num) / Math.log(1024)), units.length - 1);
    num = Number((num / Math.pow(1024, exponent)).toFixed(2)) * 1;
    unit = units[exponent];

    return (neg ? '-' : '') + num + ' ' + unit;
}
