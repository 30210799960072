<script setup lang="ts">
    import type { Anchor } from './useLocation';
    import { useLocation } from './useLocation';

    type Props = {
        location: Anchor;
    };

    const { location } = defineProps<Props>();
    const { locationStyles } = useLocation({ location });
</script>

<template>
    <div class="absolute" :style="locationStyles">
        <slot />
    </div>
</template>
