<script setup lang="ts">
    import { ref } from 'vue';

    const modelValue = defineModel<string | null>();
    const showPassword = ref(false);
</script>

<template>
    <v-text-field
        v-model="modelValue"
        density="comfortable"
        autocomplete="off"
        required
        variant="outlined"
        color="primary"
        hide-details="auto"
        class="mb-4 sentry-mask"
        :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        @click:append-inner="showPassword = !showPassword"
    />
</template>
