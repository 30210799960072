import type { Ref } from 'vue';
import { ref, watchEffect } from 'vue';

export function useStartProcess(value: Ref<boolean>, effect: () => void) {
    const started = ref(false);

    watchEffect(() => {
        if (value.value && !started.value) {
            started.value = true;
            effect();
        }
    });
}
