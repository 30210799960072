import type { Ref } from 'vue';
import { computed } from 'vue';
import { useDiscountMultiple } from './useDiscountMultiple';
import { usePricingConfig } from './usePricingConfig';
import type { FlightDurationParams } from './utils';

export function usePricesPerPlay(
    orderDuration: Ref<FlightDurationParams | null | undefined>,
) {
    const { isLoading: configLoading, pricingConfig } = usePricingConfig();
    const { isLoading: discountLoading, discountMultiple } = useDiscountMultiple(orderDuration);

    const isLoading = computed(() => configLoading.value || discountLoading.value);

    const pricesPerPlay = computed(() => {
        if (!discountMultiple.value || !pricingConfig.value) return null;

        return {
            10: pricingConfig.value.costPerPlay[10] * discountMultiple.value,
            15: pricingConfig.value.costPerPlay[15] * discountMultiple.value,
            30: pricingConfig.value.costPerPlay[30] * discountMultiple.value,
        };
    });

    return {
        isLoading,
        pricesPerPlay,
    };
}
