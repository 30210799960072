<script setup lang="ts">
    import LinkButton from '@/components/LinkButton.vue';
    import { useDialogWidth } from '@/shared/hooks';
    import { toRef } from 'vue';
    import { computed } from 'vue';
    import { toast } from 'vuetify-sonner';
    import { useOrderSummaryLink } from '../hooks';

    interface Props {
        orderId: string;
    }

    const props = defineProps<Props>();
    const modelValue = defineModel<boolean>();

    const { isLoading, link } = useOrderSummaryLink(toRef(() => props.orderId));

    const dialogWidth = useDialogWidth({ md: 0.75, lg: 0.6, xl: 0.6 });
    const displayLink = computed(() => {
        if (!link.value) return '';
        const first50Chars = link.value.slice(0, 50);
        const last15Chars = link.value.slice(-15);

        return `${first50Chars} ...${last15Chars}`;
    });

    async function copyLink() {
        if (!link.value) return;
        await navigator.clipboard.writeText(link.value);
        toast.info('Link copied to clipboard');
    }

    function close() {
        modelValue.value = false;
    }
</script>

<template>
    <v-dialog v-model="modelValue" :max-width="dialogWidth">
        <v-card color="surface-container-high" rounded="md">
            <v-card-title class="pb-0 d-flex align-center justify-space-between">
                Shareable Order Summary Link
            </v-card-title>
            <v-card-subtitle class="text-caption">
                Share this link with your customers to view the order summary
            </v-card-subtitle>

            <v-divider thickness="2px" />
            <v-card-text class="d-flex align-center justify-center bg-surface-container">
                <v-card :loading="isLoading" variant="flat" rounded="sm">
                    <v-card-text class="py-3 d-flex gap-md">
                        <span>{{ displayLink }}</span>
                        <v-icon icon="mdi-content-copy" title="Copy" @click="copyLink" />
                    </v-card-text>
                </v-card>

                <LinkButton :href="link" target="_blank" icon="mdi-arrow-top-right-thick" class="ml-4" />
            </v-card-text>
            <v-card-text class="d-flex align-center justify-center bg-surface-container pt-0">
                Send this link to your clients to view the order summary. This link will be valid for 24 hours after it
                was first generated.
            </v-card-text>
            <v-card-actions>
                <v-btn color="grey" rounded="md" @click="close">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
