<template>
    <v-overlay v-if="loading" :model-value="true" scrim="black" class="d-flex justify-center align-center">
        <v-progress-circular color="primary" indeterminate size="64" />
    </v-overlay>
    <RouterView v-else />
    <VSonner position="bottom-right" />
    <GlobalDialogs />
</template>

<script setup lang="ts">
    import { GlobalDialogs } from '@/features/globalDialogs';
    import { onMounted } from 'vue';
    import { useAuthStore } from './features/auth';

    import { VSonner } from 'vuetify-sonner';
    import 'vuetify-sonner/style.css';
    import { storeToRefs } from 'pinia';

    const { loading } = storeToRefs(useAuthStore());
    const { init } = useAuthStore();

    onMounted(() => {
        void init();
    });
</script>
