<script setup lang="ts">
    import { CompanyLiveSearchAutocomplete } from '@/features/companies/components';

    type ViewKind = 'grid' | 'table' | 'list';

    type Props = {
        enabledViews?: ViewKind[];
        search?: boolean;
        companySelect?: boolean;
    };

    defineProps<Props>();

    const searchTerm = defineModel<string | null>('searchTerm');
    const companyId = defineModel<number | null>('companyId');
    const activeView = defineModel<ViewKind>('activeView');

    function showGrid() {
        activeView.value = 'grid';
    }

    function showTable() {
        activeView.value = 'table';
    }

    function showList() {
        activeView.value = 'list';
    }
</script>

<template>
    <v-card rounded="0" color="transparent" variant="flat">
        <v-card-text class="d-flex py-2 bg-surface-container-high">
            <v-row>
                <v-col v-if="search" cols="auto">
                    <v-text-field
                        v-model="searchTerm"
                        prepend-inner-icon="mdi-magnify"
                        clearable
                        placeholder="Search"
                        density="compact"
                        hide-details
                        dense
                        variant="outlined"
                        color="primary"
                        class="bg-shaded-1"
                        style="min-width: 200px;"
                    />
                </v-col>
                <v-col v-if="companySelect" cols="auto">
                    <CompanyLiveSearchAutocomplete
                        v-model="companyId"
                        hide-details
                        density="compact"
                        clearable
                        class="bg-shaded-1"
                        style="min-width: 250px;"
                    />
                </v-col>
                <slot name="filters" />
                <v-spacer v-if="enabledViews" />
                <v-col v-if="enabledViews" cols="auto" class="d-flex align-center gap-xs">
                    <slot name="actions" />

                    <v-divider vertical thickness="3" class="mx-2" />
                    <v-icon
                        v-if="enabledViews.includes('grid')"
                        v-ripple
                        :color="activeView === 'grid' ? 'primary' : 'on-surface'"
                        icon="mdi-grid"
                        size="large"
                        class="rounded-sm"
                        @click="showGrid"
                    />
                    <v-icon
                        v-if="enabledViews.includes('table')"
                        v-ripple
                        :color="activeView === 'table' ? 'primary' : 'on-surface'"
                        icon="mdi-list-box-outline"
                        size="x-large"
                        class="rounded-sm"
                        @click="showTable"
                    />
                    <v-icon
                        v-if="enabledViews.includes('list')"
                        v-ripple
                        :color="activeView === 'list' ? 'primary' : 'on-surface'"
                        icon="mdi-format-list-bulleted-square"
                        size="x-large"
                        class="rounded-sm"
                        @click="showList"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
