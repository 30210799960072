<script setup lang="ts">
    import LinkButton from '@/components/LinkButton.vue';
    import RenderResultErrors from '@/components/RenderResultErrors.vue';
    import { useCommand } from '@/features/api/hooks/useCommand';
    import { useDialogWidth } from '@/shared/hooks';
    import { ref } from 'vue';

    interface Props {
        orderRunId: string;
        orderId: string;
    }

    const props = defineProps<Props>();
    const modelValue = defineModel<boolean>();
    const dialogWidth = useDialogWidth({ md: 0.6, lg: 0.5, xl: 0.5 });

    const { isLoading, mutateAsync: putOnHold, error } = useCommand('orderRuns/PUT_ON_HOLD', {
        invalidates: ['campaigns/', 'orders/LIST_ORDERS'],
        onSettled: (_data, error) => {
            if (!error) {
                close();
            }
        },
    });

    const formRef = ref<VuetifyForm>();
    const notes = ref<string>('');

    async function handlePutOnHold() {
        if (!formRef.value) throw new Error('Form ref is not defined');
        if (!(await formRef.value.validate()).valid) return;
        await putOnHold({ params: { runId: props.orderRunId }, request: notes.value });
    }

    function close() {
        modelValue.value = false;
    }
</script>

<template>
    <v-dialog v-model="modelValue" :max-width="dialogWidth">
        <v-card>
            <v-card-title class="pt-2 bg-surface-container d-flex justify-space-between align-center">
                <span class="text-h2">Put Run On Hold</span>
                <LinkButton @click="$triggerDialog('viewOrder', { orderId: orderId })">Order</LinkButton>
            </v-card-title>
            <v-divider thickness="2px" />
            <v-card-subtitle class="bg-surface-container text-wrap">
                This will pause the run, preventing ads from playing. If the run ends while on hold it will be
                considered Terminated.
            </v-card-subtitle>
            <v-divider thickness="2px" />
            <v-card-text class="mt-4">
                <v-form ref="formRef">
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                v-model="notes"
                                label="Hold Reason"
                                hint="Why is their run being put on hold?"
                                :rules="[(v: string) => !!v || 'Hold notes are required']"
                                persistent-hint
                                required
                                outlined
                                dense
                                clearable
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="grey" size="large" @click="() => (modelValue = false)">
                    Cancel
                </v-btn>
                <v-spacer />
                <v-btn color="red" size="large" :loading="isLoading" @click="handlePutOnHold">
                    Put On Hold
                </v-btn>
            </v-card-actions>
            <RenderResultErrors :result="error" />
        </v-card>
    </v-dialog>
</template>
