<script setup lang="ts">
    import { RenderResultErrors } from '@/components';
    import { useCommand } from '@/features/api';
    import { useStartProcess } from '@/shared/hooks';
    import { getBlobBase64Buffer } from '@/shared/utilities';
    import { ref, toRef } from 'vue';
    import type { NewCreativeResponse } from '../../api';

    type Props = {
        file: File;
        creativeInfo: {
            name: string;
            duration: number;
            notes: string;
        };
        companyId: number;
        start: boolean;
    };

    type Emits = {
        'creative-initialized': [value: NewCreativeResponse];
    };

    const props = defineProps<Props>();
    const emits = defineEmits<Emits>();

    const {
        mutateAsync: createCreative,
        isLoading,
        error,
        isSuccess,
    } = useCommand('creatives/CREATE_CREATIVE', { invalidates: ['creatives/'] });
    const created = ref<boolean>(false);

    useStartProcess(toRef(() => props.start), () => {
        console.log('Start process');
        void handleCreateCreative();
    });

    async function handleCreateCreative() {
        if (created.value) {
            console.error('Attempted to create creative when already created.');
            console.trace();
        }
        const b64Buffer = await getBlobBase64Buffer(props.file);

        const newCreative = await createCreative({
            request: {
                name: props.creativeInfo.name,
                duration: props.creativeInfo.duration,
                notes: props.creativeInfo.notes,
                fileRequest: {
                    companyId: props.companyId,
                    fileName: props.file.name,
                    b64Buffer,
                    fileKind: 'Media',
                },
                requiresAudio: false,
            },
            params: { companyId: props.companyId },
        });

        if (newCreative) {
            created.value = true;
            emits('creative-initialized', newCreative);
        }
    }
</script>

<template>
    <v-list-item v-if="isLoading">
        <template v-slot:prepend>
            <v-progress-circular size="38" indeterminate color="primary" />
            <v-icon v-show="false" class="i-exist-for-layout" size="40" />
        </template>
        <v-list-item-title>Get Upload URL</v-list-item-title>
    </v-list-item>

    <v-list-item v-else-if="isSuccess">
        <template v-slot:prepend>
            <v-icon icon="mdi-check-circle-outline" color="success" size="40" />
        </template>
        <v-list-item-title>Get Upload URL</v-list-item-title>
    </v-list-item>

    <v-list-item v-else-if="error" class="w-fit">
        <template v-slot:prepend>
            <v-icon icon="mdi-close-circle-outline" color="error" size="40" />
        </template>
        <template v-slot:append>
            <v-btn
                color="primary"
                append-icon="mdi-redo"
                variant="tonal"
                class="opacity-100 ml-4"
                @click="handleCreateCreative"
            >
                Retry
            </v-btn>
            <RenderResultErrors :result="error" />
        </template>
        <v-list-item-title>Failed to Get URL</v-list-item-title>
    </v-list-item>

    <v-list-item v-else>
        <template v-slot:prepend>
            <v-icon icon="mdi-numeric-3-circle-outline" size="40" />
        </template>
        <v-list-item-title>Get Upload URL</v-list-item-title>
    </v-list-item>
</template>
