import { ref, toRaw, watch } from 'vue';
import type { UserResponse } from '../api';
import { useMyUser } from './useMyUser';

export function useEditableMyUser() {
    const { isLoading, myUser } = useMyUser();

    const editable = ref<UserResponse | null>(null);

    watch(myUser, (newVal) => {
        if (newVal && editable.value === null) {
            editable.value = structuredClone(toRaw(newVal));
        }
    }, { immediate: true });

    return {
        isLoading,
        myUser: editable,
    };
}
