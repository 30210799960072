<script setup lang="ts">
    import { DatePicker } from '@/components';
    import { MaskedMoneyInput } from '@/components/inputs';
    import CompanyLiveSearchAutocomplete from '@/features/companies/components/CompanyLiveSearchAutocomplete.vue';
    import { computed, ref, watchEffect } from 'vue';

    import type { CompanySearchRequest } from '@/features/companies/api';
    import { useMyCompany } from '@/features/companies/hooks';

    import { useBudget } from '@/features/budget/hooks';
    import { watch } from 'vue';
    import type { CampaignFormData } from './types';

    type Props = {
        disabledCompanySelect?: boolean;
    };

    type Emits = {
        'update:formRef': [value: VuetifyForm];
    };

    defineProps<Props>();
    const formData = defineModel<CampaignFormData>({ required: true });
    const emits = defineEmits<Emits>();

    const selectedCompanyId = computed(() => formData.value.companyId);

    const { isLoading: budgetLoading, companyBudget } = useBudget(selectedCompanyId);

    const { isRoot, isLoading: loadingMyCompany } = useMyCompany();
    const companySearchFilter = computed<CompanySearchRequest | undefined>(() => {
        if (loadingMyCompany.value) return undefined;

        if (!isRoot.value) {
            return { type: ['SubCompany'] };
        }

        return undefined;
    });

    const form = ref<VuetifyForm>();
    const rules = ref({
        name: [(v: string) => !!v || 'Name is required'],
        startDate: [(v: Date | null) => !!v || 'Campaign Start is required'],
        company: [(v: number | null) => !!v || 'Company is required'],
    });

    const moneyInputHint = computed(() => {
        if (!companyBudget.value?.hasBudget) {
            return 'The budget ceiling for this campaign.';
        }

        return `The budget ceiling for this campaign. Available budget: $${companyBudget.value?.remainingCampaignBudget}`;
    });

    const disableTotalBudget = computed(() => {
        return companyBudget.value?.hasBudget;
    });

    watch(disableTotalBudget, (newVal) => {
        if (newVal) {
            formData.value.budget.kind = 'Monthly';
        }
    });

    watchEffect(() => {
        if (
            formData.value.duration.startDate && formData.value.duration.endDate
            && formData.value.duration.startDate > formData.value.duration.endDate
        ) {
            formData.value.duration.endDate = null;
        }
    });

    watchEffect(() => {
        if (form.value) {
            emits('update:formRef', form.value);
        }
    });
</script>

<template>
    <v-form ref="form">
        <v-row align="center">
            <v-col cols="12" md="6">
                <v-row justify="center">
                    <v-col cols="12" class="text-center">
                        <h2>Select Company</h2>
                    </v-col>
                    <v-col cols="12" sm="8" md="8" lg="8">
                        <CompanyLiveSearchAutocomplete
                            v-model="formData.companyId"
                            :disabled="disabledCompanySelect"
                            :rules="rules.company"
                            :filter="companySearchFilter"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row justify="center">
                    <v-col cols="12" class="d-flex justify-center align-center gap-md">
                        <h2>Budget</h2>
                        <v-btn-toggle
                            v-model="formData.budget.kind"
                            mandatory
                            color="primary"
                            rounded="sm"
                            variant="outlined"
                            class="left"
                        >
                            <v-btn value="Monthly">
                                Monthly
                            </v-btn>
                            <v-btn value="Total" :disabled="disableTotalBudget">
                                Total
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" sm="8" md="8" class="d-flex">
                        <MaskedMoneyInput
                            v-model="formData.budget.budget"
                            :max="companyBudget?.remainingCampaignBudget"
                            :loading="budgetLoading"
                            :hint="moneyInputHint"
                            label="Budget (optional)"
                        >
                            <template v-slot:append-inner>
                                <span
                                    v-if="formData.budget.kind == 'Monthly'"
                                    class="font-weight-bold text-nowrap"
                                >/month</span>
                            </template>
                        </MaskedMoneyInput>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="text-center">
                <h2>Campaign Details</h2>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4">
                <v-text-field
                    v-model="formData.name"
                    :rules="rules.name"
                    label="Name"
                    variant="outlined"
                    color="primary"
                />
            </v-col>
            <v-col cols="12" sm="8" md="8" lg="8">
                <v-text-field v-model="formData.description" label="Description" variant="outlined" color="primary" />
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4">
                <DatePicker
                    v-model="formData.duration.startDate"
                    :min="new Date()"
                    :rules="rules.startDate"
                    label="Campaign Start"
                    hint="The date when this campaign should start. Orders cannot be made before this date."
                />
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4">
                <DatePicker
                    v-model="formData.duration.endDate"
                    :min="formData.duration.startDate"
                    label="Campaign End (optional)"
                    clearable
                    hint="If selected, marks the end of this campaign. Orders cannot be made past this date."
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<style scoped lang="scss">
.fit-content {
    width: fit-content;
}

:deep(.v-data-table__tr:has(input[type="checkbox"]:checked)) {
    background-color: rgb(var(--v-theme-surface-container-low));
}
</style>
