<script setup lang="ts">
    import { computed } from 'vue';
    import { useAdDurationsInput } from './useAdDurationsInput';

    type Props = {
        disabled?: boolean;
        readonly?: boolean;
    };

    const props = withDefaults(defineProps<Props>(), { disabled: false, readonly: false });
    const model = defineModel<number | null>({ required: true, default: null });

    const { isLoading, selectOptions } = useAdDurationsInput(model);

    const label = computed(() => {
        return props.readonly ? 'Ad Duration (readonly)' : 'Ad Duration';
    });
</script>

<template>
    <v-skeleton-loader :loading="isLoading" type="input" class="w-100 mx-auto justify-center">
        <v-select
            v-model="model"
            variant="outlined"
            name="Ad Duration"
            :items="selectOptions"
            :label="label"
            :rules="[v => !!v || 'Item is required']"
            color="primary"
            :disabled="props.disabled"
            :readonly="props.readonly"
            required
        />
    </v-skeleton-loader>
</template>
