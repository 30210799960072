<script setup lang="ts">
    import NoDataImage from '@/assets/images/no-data.png';
    import { LoaderOverlay } from '@/components';
    import RenderResultErrors from '@/components/RenderResultErrors.vue';
    import { AdDurationSelection } from '@/features/campaigns/components/inputs';
    import { FileDropZone } from '@/features/files/components';
    import type { FileDropzoneModel } from '@/features/files/components/types';
    import { computed, ref, toRef } from 'vue';

    import { useCommand } from '../../api';
    import { useEditableCreative } from '../hooks';
    import MediaKindChip from './MediaKindChip.vue';

    type Props = {
        creativeId: string;
    };

    const props = defineProps<Props>();
    const modelValue = defineModel<boolean>();

    const { isLoading, creative } = useEditableCreative(toRef(() => props.creativeId));
    const { isLoading: saveLoading, mutateAsync, error } = useCommand('creatives/EDIT_CREATIVE', {
        invalidates: ['creatives/'],
        onSettled(data, error) {
            if (!error) {
                modelValue.value = false;
            }
        },
    });

    const dropZoneModel = ref<FileDropzoneModel | null>(null);

    const formRef = ref<VuetifyForm>();
    const rules = ref({
        name: [
            (v: string) => !!v || 'Creative name is required',
        ],
    });

    const thumbnailUrl = computed(() => creative.value?.thumbnailUrl || NoDataImage);

    function clickCancel() {
        modelValue.value = false;
    }

    async function clickSave() {
        if (!creative.value) return;
        if (!(await formRef.value?.validate())?.valid) return;

        await mutateAsync({
            request: {
                name: creative.value.name,
                notes: creative.value.notes,
                duration: creative.value.duration,
            },
            params: {
                creativeId: creative.value.id,
            },
        });
    }
</script>

<template>
    <v-dialog v-model="modelValue" max-width="700px" min-width="250px" persistent>
        <v-card class="p-relative">
            <LoaderOverlay :active="isLoading" />
            <v-card-title class="text-center pt-2 bg-surface-container">
                <span class="text-h2">Edit Creative</span>
            </v-card-title>
            <v-card-text>
                <v-form v-if="creative" ref="formRef" lazy-validation class="">
                    <v-row justify="center">
                        <v-col cols="12" sm="6" class="p-relative d-flex justify-center">
                            <v-card color="transparent" width="fit-content" rounded="sm">
                                <v-img width="320px" :src="thumbnailUrl" class="p-relative">
                                    <div class="absolute-top-left">
                                        <MediaKindChip :value="creative.mediaKind" shade="dark" variant="elevated" />
                                        <v-chip class="ma-2 font-weight-bold" color="primary" variant="elevated">
                                            {{ $filters.bytes(creative.fileSize) }}
                                        </v-chip>
                                    </div>
                                </v-img>

                                <!-- <v-img v-if="mediaKind === 'Image'" :src="fileObjectUrl" />
                                <video v-else-if="mediaKind === 'Video'" controls class="w-100">
                                    <source :src="fileObjectUrl" :type="file.type">
                                </video> -->
                            </v-card>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            class="p-relative d-flex justify-center"
                            @click="$notImplemented('Replace File')"
                        >
                            <FileDropZone v-model="dropZoneModel" fluid min-height="200px" disabled />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="creative.name"
                                label="Name"
                                variant="outlined"
                                color="secondary"
                                :rules="rules.name"
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-textarea
                                v-model="creative.notes"
                                label="Notes"
                                rows="4"
                                variant="outlined"
                                color="secondary"
                            />
                        </v-col>

                        <v-col cols="12" class="p-relative">
                            <div class="text-h2 text-center font-weight-regular mb-2">
                                Ad Duration
                            </div>
                            <AdDurationSelection
                                v-model="creative.duration"
                                size="large"
                                :disabled="creative.mediaKind !== 'Image'"
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="my-2">
                <v-btn size="large" color="grey" :disabled="saveLoading" @click="clickCancel">
                    Cancel
                </v-btn>
                <v-spacer />
                <v-btn size="large" color="primary" :disabled="saveLoading" @click="clickSave">
                    Save
                </v-btn>
            </v-card-actions>
            <RenderResultErrors :result="error" />
        </v-card>
    </v-dialog>
</template>

<style scoped lang="scss">
:deep(.uploading-overlay .v-overlay__scrim) {
    opacity: 0.9;
}

.absolute-top-left {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
</style>
