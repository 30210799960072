import { useApi } from '@/features/api/hooks';
import type { Ref } from 'vue';
import { computed, toValue } from 'vue';

export function useUserCompanyInfo(userId: string | Ref<Nullish<string>>) {
    const isEnabled = computed(() => !!toValue(userId));

    const { isLoading, data, error } = useApi(['users/GET_USER_COMPANY_INFO', userId], (endpoint, fetcher) => {
        return fetcher(endpoint, { userId: toValue(userId)! });
    }, { staleTime: 2500, enabled: isEnabled });

    return {
        isLoading,
        userCompanyInfo: data,
        error,
    };
}
