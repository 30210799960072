import { useApi } from '@/features/api/hooks';
import type { Ref } from 'vue';
import { computed } from 'vue';

export function useInvoice(invoiceId: Ref<Nullish<string>>) {
    const isEnabled = computed(() => !!invoiceId.value);

    const { isLoading, data, error } = useApi(['invoice/GET_INVOICE', invoiceId], (endpoint, fetcher) => {
        return fetcher(endpoint, { invoiceId: invoiceId.value! });
    }, { enabled: isEnabled });

    return {
        invoice: data,
        error,
        isLoading,
    };
}
