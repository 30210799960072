<script setup lang="ts">
    import { IndustrySectorIcon } from '@/features/industries/components';
    import type { ScreenResponse } from '../../api';

    type Props = {
        screens: ScreenResponse[];
    };
    defineProps<Props>();
    const modelValue = defineModel<boolean>();
</script>

<template>
    <v-dialog v-model="modelValue" max-width="500px" scrollable>
        <v-card max-height="700px" rounded="md">
            <v-card-title class="bg-surface-container text-center">
                <span class="text-h2">Conflicting Screens</span>
                <v-card-subtitle>
                    <p>
                        The following screens are in an Industry Sector which conflicts with the Industry Sector(s)
                        selected for this company and must be removed.
                    </p>
                </v-card-subtitle>
            </v-card-title>

            <v-card-text>
                <v-row dense class="gap-sm">
                    <v-col v-for="(screen, index) in screens" :key="screen.id" cols="12">
                        <v-card color="surface-container-high">
                            <v-card-text class="v-row flex-nowrap align-center">
                                <v-col cols="auto">
                                    <span class="text-h3">{{ index + 1 }}</span>
                                </v-col>
                                <v-col cols="auto">
                                    <IndustrySectorIcon :industries="screen.metadata.industries" size="h2" />
                                </v-col>
                                <v-col cols="8" class="d-flex flex-column justify-space-between">
                                    <div class="text-h4 text-primary">
                                        {{ screen.title }}
                                    </div>
                                    <div class="text-on-tertiary-container text-body-1">
                                        {{ screen.sectorName }}
                                    </div>
                                </v-col>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="bg-surface-container px-4 py-2">
                <v-spacer />
                <v-btn color="on-surface-container" variant="tonal" rounded="sm" @click="modelValue = false">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
