type Point = [number, number]; // [longitude, latitude]
type Direction = 'N' | 'S' | 'E' | 'W' | 'NE' | 'NW' | 'SE' | 'SW';
export type BoundingBox = [Point, Point]; // [SW, NE]

export function movePoint(point: Point, meters: number, direction: Direction): Point {
    const [lon, lat] = point;
    const metersPerDegreeLat = 111111; // Approximate meters per degree of latitude
    const metersPerDegreeLon = metersPerDegreeLat * Math.cos(lat * Math.PI / 180);
    let distanceLat = meters;
    let distanceLon = meters;

    // Adjust the distance for diagonal movements
    if (direction.length === 2) {
        distanceLat = meters / Math.sqrt(2);
        distanceLon = meters / Math.sqrt(2);
    }

    let newLat = lat;
    let newLon = lon;

    if (direction.includes('N')) {
        newLat += distanceLat / metersPerDegreeLat;
    } else if (direction.includes('S')) {
        newLat -= distanceLat / metersPerDegreeLat;
    }

    if (direction.includes('E')) {
        newLon += distanceLon / metersPerDegreeLon;
    } else if (direction.includes('W')) {
        newLon -= distanceLon / metersPerDegreeLon;
    }

    return [newLon, newLat];
}

export function expandBoundingBox(box: BoundingBox, meters: number): BoundingBox {
    const [sw, ne] = box;

    // Move the SW corner southwest and the NE corner northeast
    const newSw = movePoint(sw, meters, 'SW');
    const newNe = movePoint(ne, meters, 'NE');

    return [newSw, newNe];
}

export function expandBoundingBoxByPercentage(box: BoundingBox, percentage: number): BoundingBox {
    const [sw, ne] = box;
    const width = Math.abs(ne[0] - sw[0]);
    const height = Math.abs(ne[1] - sw[1]);

    // Calculate the amount to expand the box by, based on the percentage
    const widthExpand = width * percentage;
    const heightExpand = height * percentage;

    // Adjust the SW and NE points based on the expansion amounts
    const newSw: Point = [sw[0] - widthExpand / 2, sw[1] - heightExpand / 2];
    const newNe: Point = [ne[0] + widthExpand / 2, ne[1] + heightExpand / 2];

    return [newSw, newNe];
}

export function getBoundingBoxCenter(boundingBox: BoundingBox): Point {
    const [sw, ne] = boundingBox;

    const centerX = (sw[0] + ne[0]) / 2;
    const centerY = (sw[1] + ne[1]) / 2;

    return [centerX, centerY];
}

export function makeSquareBoundingBox(rectangularBox: BoundingBox): BoundingBox {
    const [sw, ne] = rectangularBox;

    const centerX = (sw[0] + ne[0]) / 2;
    const centerY = (sw[1] + ne[1]) / 2;

    const halfWidth = Math.abs(ne[0] - sw[0]) / 2;
    const halfHeight = Math.abs(ne[1] - sw[1]) / 2;

    // Use the maximum of halfWidth and halfHeight to ensure the square covers the rectangle uniformly
    const halfSize = Math.max(halfWidth, halfHeight);

    // Create the square bounding box
    const newSw: Point = [centerX - halfSize, centerY - halfSize];
    const newNe: Point = [centerX + halfSize, centerY + halfSize];

    return [newSw, newNe];
}

export function makeNormalizedBoundingBox(rectangularBox: BoundingBox): BoundingBox {
    return makeBoundingBoxWithRatio(rectangularBox, 0.80, 1); // For some reason a square map doesn't display a square bounding box
}

export function makeBoundingBoxWithRatio(
    rectangularBox: BoundingBox,
    ratioHeight: number,
    ratioWidth: number,
): BoundingBox {
    const [sw, ne] = rectangularBox;

    const centerX = (sw[0] + ne[0]) / 2;
    const centerY = (sw[1] + ne[1]) / 2;

    const width = Math.abs(ne[0] - sw[0]);
    const height = Math.abs(ne[1] - sw[1]);

    // Calculate new dimensions based on the specified ratio
    let newHeight, newWidth;
    if ((width / height) > (ratioWidth / ratioHeight)) {
        // If the rectangle is wider than the target ratio, adjust the height accordingly
        newWidth = width;
        newHeight = width * (ratioHeight / ratioWidth);
    } else {
        // If the rectangle is taller or equal in aspect ratio to the target, adjust the width accordingly
        newHeight = height;
        newWidth = height * (ratioWidth / ratioHeight);
    }

    // Calculate the new SW and NE points
    const newSw: Point = [centerX - newWidth / 2, centerY - newHeight / 2];
    const newNe: Point = [centerX + newWidth / 2, centerY + newHeight / 2];

    return [newSw, newNe];
}
