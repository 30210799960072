<script setup lang="ts">
    import { useAdDurationsInput } from './useAdDurationsInput';

    type Props = {
        size?: 'x-large' | 'large' | 'default' | 'small' | 'x-small';
        disabled?: boolean;
        disabledDurations?: number[];
    };

    const props = withDefaults(defineProps<Props>(), {
        size: 'x-large',
        disabled: false,
        disabledDurations: () => [],
    });

    const model = defineModel<number | null>({ required: true, default: null });

    const { isLoading, inputValue, adDurations } = useAdDurationsInput(model);

    function isDisabled(duration: number) {
        return props.disabled || props.disabledDurations.includes(duration);
    }
</script>

<template>
    <v-skeleton-loader :loading="isLoading" type="button, button, button" class="w-100 mx-auto justify-center">
        <v-item-group v-model="inputValue" selected-class="bg-primary-container">
            <v-row justify="center">
                <v-col v-for="duration in adDurations" :key="duration" cols="auto">
                    <v-item v-slot="{ isSelected, selectedClass, toggle }">
                        <v-btn
                            variant="outlined"
                            color="primary"
                            class="ad-length-button text-capitalize"
                            :class="selectedClass"
                            :size="props.size"
                            :disabled="isDisabled(duration) && !isSelected"
                            @click="props.disabled ? undefined : toggle!()"
                        >
                            <div class="d-flex flex-column whitespace-break-spaces">
                                <span :class="isSelected ? 'on-primary-container' : ''">
                                    {{ duration }} Seconds
                                </span>
                            </div>
                        </v-btn>
                    </v-item>
                    <div class="d-flex justify-center">
                        <slot name="after-each" :duration="duration" class="d-block" />
                    </div>
                </v-col>
            </v-row>
        </v-item-group>
    </v-skeleton-loader>
</template>
