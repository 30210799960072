<script setup lang="ts">
    import { toRef, type UnwrapNestedRefs } from 'vue';
    import type { DataPaginationFooterData } from './types';
    import { isEasyDataTableFooterData } from './types';
    import UnifiedDataFooter from './UnifiedDataFooter.vue';
    import { useDataPagination } from './useDataPagination';

    type Props = {
        dataTableFooter: UnwrapNestedRefs<EasyDataTableFooter> | DataPaginationFooterData;
    };

    type Emits = {
        'update:currentPage': [number];
        'update:itemsPerPage': [number];
    };

    const emits = defineEmits<Emits>();
    const props = defineProps<Props>();

    const {
        currentPageStartIndex,
        currentPageEndIndex,
        itemsLength,
        itemsPerPage,
        itemsPerPageOptions,
        currentPage,
    } = useDataPagination(toRef(() => props.dataTableFooter));

    function updateCurrentPage(page: number) {
        if (isEasyDataTableFooterData(props.dataTableFooter)) {
            props.dataTableFooter.updatePage(page);
        } else {
            emits('update:currentPage', page);
        }
    }

    function updateItemsPerPage(itemsPerPage: number) {
        if (isEasyDataTableFooterData(props.dataTableFooter)) {
            props.dataTableFooter.updateRowsPerPageActiveOption(itemsPerPage);
        } else {
            emits('update:itemsPerPage', itemsPerPage);
        }
    }
</script>

<template>
    <UnifiedDataFooter
        :current-page-start-index="currentPageStartIndex"
        :current-page-end-index="currentPageEndIndex"
        :items-length="itemsLength"
        :items-per-page="itemsPerPage"
        :items-per-page-options="itemsPerPageOptions"
        :current-page="currentPage"
        @update:current-page="updateCurrentPage"
        @update:items-per-page="updateItemsPerPage"
    />
</template>
