import { useApi } from '@/features/api/hooks';

const STALE_TIME = 1000 * 60; // 60 seconds
const GC_TIME = 1000 * 30; // 30 seconds

export function useMyUser() {
    const { isLoading, data } = useApi('users/GET_ME', (endpoint, fetcher) => {
        return fetcher(endpoint);
    }, { staleTime: STALE_TIME, cacheTime: GC_TIME });

    return {
        isLoading,
        myUser: data,
    };
}
