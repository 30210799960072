<script setup lang="ts">
    import { computed, ref, watch , toRef } from 'vue';
    
    // eslint-disable-next-line @typescript-eslint/consistent-type-imports
    import type { VAutocomplete } from 'vuetify/lib/components/index.mjs';
    import type { CompanySearchRequest } from '../api';
    import { useCompanyLiveSearch } from '../hooks';

    type Props = {
        disabled?: boolean;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rules?: ((value: any) => string | boolean)[];
        label?: string;
        filter?: CompanySearchRequest;
        clearable?: boolean;
        hideDetails?: boolean;
        density?: VAutocomplete['$props']['density'];
        loading?: boolean;
    };

    const props = withDefaults(defineProps<Props>(), {
        rules: undefined,
        label: 'Company',
        filter: undefined,
        density: 'default',
    });
    const selectedCompany = defineModel<number | null>();

    const companySearchTerm = ref('');
    const { searchLoading, searchResults } = useCompanyLiveSearch(companySearchTerm, toRef(() => props.filter));

    const isLoading = computed(() => searchLoading.value || (searchLoading.value && !searchResults.value?.length));

    // If there is only 1 company in the search results, select it
    watch(searchResults, () => {
        if (searchResults.value?.length === 1 && !companySearchTerm.value) {
            selectedCompany.value = searchResults.value[0].id;
        }
    });
</script>

<template>
    <v-autocomplete
        v-model="selectedCompany"
        v-model:search="companySearchTerm"
        :disabled="disabled"
        :items="searchResults"
        :loading="isLoading"
        item-title="name"
        item-value="id"
        color="primary"
        :label="label"
        variant="outlined"
        :hide-details="hideDetails"
        :clearable="clearable"
        :density="density"
        item-color="on-primary"
        :rules="rules"
    />
</template>
