import { useApi } from '@/features/api/hooks';
import { useEditableMetaData } from '@/features/metadata/hooks';
import { computed, ref, toValue, watch } from 'vue';
import type { Ref } from 'vue';
import type { CompanyResponse, EditCompanyRequest } from '../api';

export function useCompany(companyId: number | Ref<number | null | undefined>) {
    const isEnabled = computed(() => !!toValue(companyId));

    const { isLoading, data } = useApi(['company/GET_COMPANY', companyId], (endpoint, fetcher) => {
        return fetcher(endpoint, { companyId: toValue(companyId)! });
    }, { staleTime: 5000, cacheTime: 2500, enabled: isEnabled });

    return {
        isLoading,
        company: data,
    };
}

export function useEditableCompany(companyId: number | Ref<number>) {
    const { company, isLoading } = useCompany(companyId);

    const companyMetadata = computed(() => company.value?.metadata);

    const editableCompany = ref<EditCompanyRequest | null>(null);
    const editableMetaData = useEditableMetaData(companyMetadata);

    watch(company, (newVal) => {
        if (newVal) { // TODO: Should we not be setting editableCompany if it has already been set to avoid overwriting changes?
            setEditable(newVal);
        }
    }, { immediate: true });

    function setEditable(value: CompanyResponse) {
        editableCompany.value = {
            name: value.name,
            notes: value.notes,
            metadata: {
                industryIds: value.metadata.industries.map((industry) => industry.industryId),
            },
            monthlyBudget: value.attributes.monthlyBudget,
            achFees: value.attributes.merchantProcessingFeePercent,
            partnerMarginPercent: value.attributes.partnerMarginPercent,
            domainNames: value.domainNames,
        };
    }

    function reset() {
        if (company.value) {
            setEditable(company.value);
        }
    }

    return {
        isLoading,
        company,
        editableCompany,
        editableMetaData,
        reset,
    };
}
