import { useApi } from '@/features/api/hooks';
import { useAuthStore } from '@/features/auth';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const STALE_TIME = 60 * 5 * 1000; // 5 minutes
const GC_TIME = 60000; // 1 minute

export function useMyCompany() {
    const { companyId } = storeToRefs(useAuthStore());

    const { isLoading, data } = useApi(['company/GET_COMPANY', companyId], (endpoint, fetcher) => {
        if (!companyId.value) return Promise.resolve(null);

        return fetcher(endpoint, { companyId: companyId.value });
    }, { staleTime: STALE_TIME, cacheTime: GC_TIME });

    const isRoot = computed(() => data.value?.companyType === 'RootCompany');

    return {
        isLoading,
        company: data,
        isRoot,
    };
}
