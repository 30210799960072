<script setup lang="ts">
    import { Text } from '@/components';
    import { PasswordInput } from '@/components/inputs';
    import { useCommand } from '@/features/api';
    import { useIsPasswordPwned } from '@/features/auth';
    import HibpAlert from '@/features/auth/components/HibpAlert.vue';
    import { useEditableMyUser } from '@/features/users/hooks';
    import { confirmPasswordRule, PASSWORD_FORM_RULES } from '@/shared';
    import { ref } from 'vue';

    const { myUser } = useEditableMyUser();

    const { mutateAsync: saveProfile, isLoading: saveProfileLoading } = useCommand('users/EDIT_ME');

    const { mutateAsync: changePassword, isLoading: changePasswordLoading } = useCommand('auth/CHANGE_PASSWORD', {
        onSuccess: () => {
            currentPassword.value = '';
            newPassword.value = '';
            confirmNewPassword.value = '';
        },
    });

    const currentPassword = ref('');
    const newPassword = ref('');
    const confirmNewPassword = ref('');

    const passwordRules = ref(PASSWORD_FORM_RULES);
    const confirmPasswordRules = ref(confirmPasswordRule(confirmNewPassword));

    const {
        checkIsPwned,
        isPwnedResult,
        acceptCompromisedPassword,
    } = useIsPasswordPwned(newPassword);

    async function handleSaveProfile() {
        if (!myUser.value) return;

        await saveProfile({
            request: {
                name: myUser.value.name,
            },
        });
    }

    async function handleChangePassword() {
        const pwned = await checkIsPwned();

        if (pwned.breached && !acceptCompromisedPassword.value) {
            return;
        }

        await changePassword({
            request: {
                currentPassword: currentPassword.value,
                newPassword: newPassword.value,
            },
        });
    }
</script>

<template>
    <v-card rounded="0">
        <v-card-text>
            <div class="mb-6 ml-n2">
                <Text size="lg" weight="medium">Profile Info</Text>
            </div>

            <v-skeleton-loader v-if="!myUser" type="button" width="100%" />
            <v-text-field
                v-else
                v-model="myUser.name"
                color="grey"
                label="Name"
                variant="outlined"
                density="comfortable"
                :disabled="saveProfileLoading"
            />

            <v-skeleton-loader v-if="!myUser" type="button" width="100%" />
            <v-text-field
                v-else
                v-model="myUser.email"
                color="grey"
                type="email"
                label="Email"
                readonly
                variant="outlined"
                density="comfortable"
                :disabled="saveProfileLoading"
            >
                <template v-slot:append-inner>
                    <v-btn
                        rounded="sm"
                        color="secondary"
                        variant="tonal"
                        :loading="false"
                        @click="$notImplemented('Change Email')"
                    >
                        Change
                    </v-btn>
                </template>
            </v-text-field>

            <v-btn
                :loading="saveProfileLoading"
                color="primary"
                class="text-capitalize"
                rounded="sm"
                @click="handleSaveProfile"
            >
                <Text weight="bold">Save</Text>
            </v-btn>

            <v-divider thickness="2" class="mx-n3 my-4" />
            <div class="mb-6 ml-n2">
                <Text size="lg" weight="medium">Password</Text>
                <Text size="sm" emphasis="medium">Manage your password</Text>
            </div>

            <PasswordInput v-model="currentPassword" :disabled="changePasswordLoading" label="Current Password" />

            <br>
            <PasswordInput
                v-model="newPassword"
                label="New Password"
                :rules="passwordRules"
                :disabled="!currentPassword || changePasswordLoading"
            />

            <PasswordInput
                v-model="confirmNewPassword"
                label="Confirm New Password"
                :rules="confirmPasswordRules"
                :disabled="!currentPassword || changePasswordLoading"
            />

            <HibpAlert
                v-if="isPwnedResult?.breached"
                v-model:accept-risk="acceptCompromisedPassword"
                :count="isPwnedResult.count"
            />

            <v-btn
                :loading="changePasswordLoading"
                color="primary"
                class="mt-4 text-capitalize"
                rounded="sm"
                :disabled="!currentPassword || !newPassword || !confirmNewPassword"
                @click="handleChangePassword"
            >
                <Text weight="bold">Change Password</Text>
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<style scoped>
:deep(.v-skeleton-loader__bone.v-skeleton-loader__button) {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
}
</style>
