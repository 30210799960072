/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { TinyEmitter } from '@/shared/utilities';
import type { Simplify } from 'type-fest';
import type { REGISTERED_DIALOGS } from './registeredDialogs';
import type { ComponentProps } from './types';

type DialogEvents = {
    [key in keyof typeof REGISTERED_DIALOGS]: Simplify<ComponentProps<typeof REGISTERED_DIALOGS[key]>>;
};

export const dialogsEventBus = new TinyEmitter<DialogEvents>('dialogsEventBus');
