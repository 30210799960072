import type { OrderResponse } from '@/features/campaigns/api';
import type { OrderAdditionalLineItemKind } from '@/features/campaigns/api/types';
import { useNumberOfPlaysRange } from '@/features/campaigns/hooks';
import type { Ref } from 'vue';
import { computed, ref, toRaw, toRef, watch } from 'vue';
import { useOrder } from './useOrder';

type EditableOrder = OrderResponse & {
    additionalLineItems?: Record<OrderAdditionalLineItemKind, number>;
};

export function useEditableOrder(orderId: Ref<string>) {
    const { isLoading, order } = useOrder(orderId);

    const editable = ref<EditableOrder | null>(null);

    const screenIds = computed(() => (editable.value?.screenIds) || []);

    const { maxPlays } = useNumberOfPlaysRange(toRef(() => screenIds.value));

    watch(order, (newVal) => {
        if (newVal && editable.value === null) {
            editable.value = structuredClone(toRaw(newVal));

            // Gross hack, how we edit an order should probably be revised...
            editable.value.additionalLineItems = {
                CreativeDesignFee: editable.value.pricing.revShare.creativeDesignFee,
            };
        }
    }, { immediate: true });

    watch(screenIds, () => {
        if (!editable.value) return;

        if (maxPlays.value < editable.value.playsPerMonth) {
            editable.value.playsPerMonth = maxPlays.value;
        }
    });

    return {
        isLoading,
        order: editable,
    };
}
