<script setup lang="ts" generic="TDialog extends RegisteredDialogName">
    import { useDelayedToggle } from '@/shared/hooks';
    import { ref, watch } from 'vue';
    import type { Component } from 'vue';
    import { onMounted } from 'vue';
    import { shallowRef } from 'vue';
    import { REGISTERED_DIALOGS, type RegisteredDialogName } from './registeredDialogs';

    type Props = {
        dialog: TDialog;
        dialogProps: Nullish<object>;
        uuid: string;
    };

    type Emits = {
        remove: [uuid: string];
    };

    const props = defineProps<Props>();
    const emits = defineEmits<Emits>();
    const [isOpen, isVisible] = useDelayedToggle();
    const component = shallowRef(REGISTERED_DIALOGS[props.dialog] as Component);

    watch(isVisible, (newValue, oldVal) => {
        if (newValue === oldVal) return;

        if (!newValue) {
            emits('remove', props.uuid);
        }
    });

    onMounted(() => {
        isOpen.value = true;
    });
</script>

<template>
    <component :is="component" v-bind="dialogProps" v-if="isVisible" v-model="isOpen" />
</template>
