import { useApi } from '@/features/api/hooks';
import type { Ref } from 'vue';
import { computed, ref, toValue, watchEffect } from 'vue';
import type { CreativeStatus } from '../api';

type Args = {
    creativeId: string | null | undefined;
    enabled: boolean | null;
};

const INTERVAL = 250;
const STOP_ON_STATUSES: CreativeStatus[] = ['Ok', 'Error'];

export function useCreativeProcessingStatus(props: Ref<Args>) {
    const creativeId = computed(() => props.value.creativeId);
    const enabled = computed(() => props.value.enabled);

    const fetching = ref<boolean>(false);

    const isEnabled = computed(() => !!(creativeId.value && enabled.value));

    watchEffect(() => {
        console.log('useCreativeProcessingStatus', {
            isEnabled: isEnabled.value,
            creativeId: creativeId.value,
            enabled: enabled.value,
        });
    });
    watchEffect(() => {
        console.log('useCreativeProcessingStatus-propd', {
            props: props.value,
        });
    });

    const { data, isLoading, error, refetch } = useApi(
        ['creatives/GET_PROCESSING_STATUS', creativeId],
        (endpoint, fetcher) => {
            return fetcher(endpoint, { creativeId: toValue(creativeId)! });
        },
        { enabled: false },
    );

    const creativeStatus = computed(() => data.value?.creativeStatus);
    const fileStatus = computed(() => data.value?.fileStatus);
    const fileMilestone = computed(() => data.value?.fileStatusMilestone);

    /** If the processing has completed */
    const isFinished = computed(() => data.value && STOP_ON_STATUSES.includes(data.value.creativeStatus));

    async function doFetchLoop() {
        if (!fetching.value) return;

        const fetched = await refetch.value();
        if (fetched.data && STOP_ON_STATUSES.includes(fetched.data.creativeStatus)) {
            stopFetching();
        } else {
            setTimeout(() => {
                void doFetchLoop();
            }, INTERVAL);
        }
    }

    function startFetching() {
        fetching.value = true;
        void doFetchLoop();
    }

    function stopFetching() {
        fetching.value = false;
    }

    watchEffect(() => {
        if (data.value && STOP_ON_STATUSES.includes(data.value.creativeStatus)) {
            stopFetching();
        } else if (isEnabled.value && !fetching.value) {
            startFetching();
        } else if (!isEnabled.value && fetching.value) {
            stopFetching();
        }
    });

    return {
        isLoading,
        error,
        creativeStatusFinished: isFinished,
        creativeStatus,
        fileStatus,
        fileMilestone,
    };
}
