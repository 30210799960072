import { APP_VERSION, ENVIRONMENT } from '@/enviornment';
import * as Sentry from '@sentry/vue';
import type { App } from 'vue';
import { router } from '../router';

export const sentryPlugin = {
    install(app: App) {
        if (ENVIRONMENT === 'dev') return;

        Sentry.init({
            app,
            dsn: 'https://5f7fa1bd135a4cc23ee1595de560d048@o4507173318033408.ingest.us.sentry.io/4507173320589312',
            integrations: [
                Sentry.browserTracingIntegration({ router }),
                // eslint-disable-next-line import/namespace
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                    maskAllInputs: false,
                }),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                'localhost',
                'https://staging.app.buzzzscreens.com',
                'https://app.buzzscreens.com',
            ],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            environment: ENVIRONMENT,
            release: APP_VERSION,
        });
    },
};
