<script setup lang="ts">
    import { computed } from 'vue';
    import type { FileStatus } from '../api';
    import { FILE_STATUS_UI_CONFIG } from '../configs';

    type Props = {
        status: FileStatus | undefined | null;
    };

    const props = defineProps<Props>();

    const color = computed(() => {
        if (!props.status) return FILE_STATUS_UI_CONFIG.None.color;
        return FILE_STATUS_UI_CONFIG[props.status].color;
    });

    const text = computed(() => {
        if (!props.status) return FILE_STATUS_UI_CONFIG.None.text;
        return FILE_STATUS_UI_CONFIG[props.status].text;
    });
</script>

<template>
    <v-chip :color="color" size="x-small" :text="text" variant="tonal" class="text-caption" />
</template>
