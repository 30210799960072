import { useApi } from '@/features/api/hooks';
import type { Ref } from 'vue';
import { computed, toValue } from 'vue';

const STALE_TIME = 1000 * 60 * 2; // 2 minutes
const GC_TIME = 1000 * 60 * 2; // 2 minutes

export function useCreativesDownloadUrls(
    creativeIds: Ref<string[] | undefined>,
    enabled: Ref<boolean> | undefined = undefined,
) {
    const isEnabled = computed(() => {
        if (enabled === undefined) return true;
        if (!toValue(creativeIds)) return false;

        return toValue(enabled);
    });

    const { isLoading, data, error } = useApi(['creatives/GET_DOWNLOAD_URLS', creativeIds], (endpoint, fetcher) => {
        return fetcher(endpoint, toValue(creativeIds)!);
    }, { keepPreviousData: true, staleTime: STALE_TIME, cacheTime: GC_TIME, enabled: isEnabled });

    return {
        isLoading,
        error,
        urls: data,
    };
}
