import { useApi } from '@/features/api/hooks';
import type { Ref } from 'vue';
import { computed } from 'vue';

const CACHE_TIME = 60 * 5 * 1000;
const GC_TIME = 60 * 5 * 1000;

export function useInvoiceStripeUrls(invoiceId: Ref<Nullish<string>>) {
    const isEnabled = computed(() => !!invoiceId.value);

    const { isLoading, data, error } = useApi(['invoice/GET_INVOICE_STRIPE_URLS', invoiceId], (endpoint, fetcher) => {
        return fetcher(endpoint, { invoiceId: invoiceId.value! });
    }, { enabled: isEnabled, cacheTime: CACHE_TIME, staleTime: GC_TIME });

    return {
        invoiceStripeUrls: data,
        error,
        isLoading,
    };
}
