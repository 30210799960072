<script setup lang="ts">
    import NoDataImage from '@/assets/images/no-data.png';
    import RenderResultErrors from '@/components/RenderResultErrors.vue';
    import { computed } from 'vue';
    import { useCreative } from '../hooks';
    import CreativeFileUrlButton from './CreativeFileUrlButton.vue';
    import MediaKindChip from './MediaKindChip.vue';

    type Props = {
        creativeId: string;
        canRemove?: boolean;
    };

    type Emits = {
        'click:remove': [void];
    };

    const props = defineProps<Props>();
    const emits = defineEmits<Emits>();
    const { isLoading, creative, error } = useCreative(props.creativeId);
    const thumbnailUrl = computed(() => creative.value?.thumbnailUrl || NoDataImage);
</script>

<template>
    <v-skeleton-loader v-if="isLoading" :loading="isLoading" type="card" class="w-100 mx-auto" />
    <v-card v-else-if="creative" color="surface-container-lowest" rounded="0" density="compact">
        <v-card-title class="on-surface-variant d-flex align-center gap-sm" :title="creative.name">
            <MediaKindChip :value="creative.mediaKind" size="small" />
            <span class="text-h5 text-truncate">{{ creative.name }}</span>
            <v-spacer />
            <CreativeFileUrlButton :creativeId="creative.id" :isDownload="false">
                <template v-slot="{ clickDownload }">
                    <v-icon icon="mdi-open-in-new" color="primary" size="small" title="View" @click="clickDownload" />
                </template>
            </CreativeFileUrlButton>
            <v-btn
                v-if="canRemove"
                color="red"
                icon
                variant="text"
                elevation="0"
                density="compact"
                title="Remove"
                @click="emits('click:remove')"
            >
                <v-icon icon="mdi-close" color="red" />
            </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="text-body-2 on-surface">
            <v-img :src="thumbnailUrl" />
        </v-card-text>
    </v-card>
    <RenderResultErrors v-if="error" :errors="error" />
</template>
