import type { Ref } from 'vue';
import { computed, toRefs } from 'vue';
import { usePricePerPlay } from './usePricePerPlay';
import type { FlightDurationParams } from './utils';

interface Params {
    adDuration: number | null | undefined;
    playsPerMonth: number | null | undefined;
    flightTime: FlightDurationParams | null;
}

const DEFAULT_PARAMS = {
    adDuration: null,
    playsPerMonth: null,
    flightTime: {
        months: 0,
    },
};

/** Provides the calculations for the costs of an order */
export function useOrderCost(params: Ref<Params | undefined | null>) {
    const { adDuration, playsPerMonth, flightTime } = toRefs(params.value || DEFAULT_PARAMS);
    const { pricePerPlay, rawPricePerPlay } = usePricePerPlay(adDuration, flightTime);

    const monthlyCost = computed(() => {
        if (!params) return 0;
        if (!params.value) return 0;
        if (!pricePerPlay.value) return 0;
        if (!playsPerMonth.value) return 0;

        return pricePerPlay.value * playsPerMonth.value;
    });

    const rawMonthlyCost = computed(() => {
        if (!params) return 0;
        if (!params.value) return 0;
        if (!rawPricePerPlay.value) return 0;
        if (!playsPerMonth.value) return 0;

        return rawPricePerPlay.value * playsPerMonth.value;
    });

    return {
        /** The avg monthly cost after discounts & adjustments */
        monthlyCost,
        /** The raw monthly cost without any discounts or adjustments */
        rawMonthlyCost,
    };
}
