<script setup lang="ts">
    import { STRIPE_PUBLISHABLE_KEY } from '@/enviornment';
    import type { Stripe, StripeEmbeddedCheckout } from '@stripe/stripe-js';
    import { loadStripe } from '@stripe/stripe-js';
    import { onBeforeUnmount, onMounted, shallowRef } from 'vue';

    type Props = {
        clientSecret: string;
    };

    type Emits = {
        complete: [];
    };

    const props = defineProps<Props>();
    const emits = defineEmits<Emits>();

    const stripe = shallowRef<Stripe | null>(null);
    const checkout = shallowRef<StripeEmbeddedCheckout | null>(null);

    onMounted(async () => {
        stripe.value = await loadStripe(STRIPE_PUBLISHABLE_KEY);
        if (!stripe.value) {
            throw new Error('Failed to load Stripe');
        }

        checkout.value = await stripe.value.initEmbeddedCheckout({
            clientSecret: props.clientSecret,
            onComplete: () => {
                emits('complete');
            },
        });

        checkout?.value.mount('#checkout-container');
    });

    onBeforeUnmount(() => {
        checkout.value?.destroy();
    });
</script>

<template>
    <div id="checkout-container" class="sentry-block" />
</template>
