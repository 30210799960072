/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
// import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Composables
import { M3DarkTheme, M3LightTheme } from '@/theme';
import { createVuetify } from 'vuetify';
import { md3 } from 'vuetify/blueprints';
import { VNumberInput } from 'vuetify/labs/VNumberInput';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
    blueprint: md3,
    components: {
        VNumberInput,
    },
    icons: {
        defaultSet: 'mdi',
    },
    theme: {
        defaultTheme: 'M3DarkTheme',
        themes: {
            M3LightTheme,
            M3DarkTheme,
            // light: {
            //     colors: { ...M3LightTheme.colors },
            // },
        },
    },
    // theme: {
    //     defaultTheme: 'LightTheme',
    //     variations: LIGHT_THEME_VARIATIONS,
    //     themes: {
    //         LightTheme,
    //         light: {
    //             colors: {
    //                 primary: '#1867C0',
    //                 secondary: '#5CBBF6',
    //             },
    //         },
    //     },
    // },
});
