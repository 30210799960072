/* eslint-disable prefer-const */
import type { CSSProperties } from 'vue';
import { computed } from 'vue';
import { useRtl } from 'vuetify/lib/framework.mjs';

const block = ['top', 'bottom'] as const;
const inline = ['start', 'end', 'left', 'right'] as const;
type TBlock = typeof block[number];
type TInline = typeof inline[number];

export type Anchor =
    | TBlock
    | TInline
    | 'center'
    | 'center center'
    | `${TBlock} ${TInline | 'center'}`
    | `${TInline} ${TBlock | 'center'}`;
export type ParsedAnchor =
    | { side: 'center'; align: 'center'; }
    | { side: TBlock; align: 'left' | 'right' | 'center'; }
    | { side: 'left' | 'right'; align: TBlock | 'center'; };

export interface LocationProps {
    location: Anchor | undefined;
}

const oppositeMap = {
    center: 'center',
    top: 'bottom',
    bottom: 'top',
    left: 'right',
    right: 'left',
} as const;

/** Parse a raw anchor string into an object */
export function parseAnchor(anchor: Anchor, isRtl: boolean) {
    let [side, align] = anchor.split(' ') as [TBlock | TInline | 'center', TBlock | TInline | 'center' | undefined];
    if (!align) {
        align = (block as unknown as string[]).includes(side)
            ? 'start'
            : (inline as unknown as string[]).includes(side)
            ? 'top'
            : 'center';
    }

    return {
        side: toPhysical(side, isRtl),
        align: toPhysical(align, isRtl),
    } as ParsedAnchor;
}

export function toPhysical(str: 'center' | TBlock | TInline, isRtl: boolean) {
    if (str === 'start') return isRtl ? 'right' : 'left';
    if (str === 'end') return isRtl ? 'left' : 'right';
    return str;
}

export function flipSide(anchor: ParsedAnchor) {
    return {
        side: {
            center: 'center',
            top: 'bottom',
            bottom: 'top',
            left: 'right',
            right: 'left',
        }[anchor.side],
        align: anchor.align,
    } as ParsedAnchor;
}

export function flipAlign(anchor: ParsedAnchor) {
    return {
        side: anchor.side,
        align: {
            center: 'center',
            top: 'bottom',
            bottom: 'top',
            left: 'right',
            right: 'left',
        }[anchor.align],
    } as ParsedAnchor;
}

export function flipCorner(anchor: ParsedAnchor) {
    return {
        side: anchor.align,
        align: anchor.side,
    } as ParsedAnchor;
}

export function getAxis(anchor: ParsedAnchor) {
    return (block as unknown as string[]).includes(anchor.side) ? 'y' : 'x';
}

export function useLocation(props: LocationProps, opposite = false, offset?: (side: string) => number) {
    const { isRtl } = useRtl();

    const locationStyles = computed(() => {
        if (!props.location) return {};

        const { side, align } = parseAnchor(
            props.location.split(' ').length > 1
                ? props.location
                : `${props.location} center` as Anchor,
            isRtl.value,
        );

        function getOffset(side: string) {
            return offset
                ? offset(side)
                : 0;
        }

        const styles = {} as CSSProperties;

        if (side !== 'center') {
            if (opposite) styles[oppositeMap[side]] = `calc(100% - ${getOffset(side)}px)`;
            else styles[side] = 0;
        }
        if (align !== 'center') {
            if (opposite) styles[oppositeMap[align]] = `calc(100% - ${getOffset(align)}px)`;
            else styles[align] = 0;
        } else {
            if (side === 'center') styles.top = styles.left = '50%';
            else {
                styles[
                    ({
                        top: 'left',
                        bottom: 'left',
                        left: 'top',
                        right: 'top',
                    } as const)[side]
                ] = '50%';
            }
            styles.transform = {
                top: 'translateX(-50%)',
                bottom: 'translateX(-50%)',
                left: 'translateY(-50%)',
                right: 'translateY(-50%)',
                center: 'translate(-50%, -50%)',
            }[side];
        }

        return styles;
    });

    return { locationStyles };
}
