<script setup lang="ts">
    import { useDelayedToggle } from '@/shared/hooks';
    import type { Component } from 'vue';
    import { onBeforeUnmount, onMounted, ref, shallowRef, watch } from 'vue';

    import { v4 } from 'uuid';
    import { dialogsEventBus } from './dialogsEventBus';
    import GlobalDialog from './GlobalDialog.vue';
    import { REGISTERED_DIALOG_NAMES, REGISTERED_DIALOGS, RegisteredDialogName } from './registeredDialogs';

    type ActiveDialog = {
        dialog: RegisteredDialogName;
        props: Nullish<object>;
        uuid: string;
    };

    const activeDialogs = ref<ActiveDialog[]>([]);

    const currentDialog = shallowRef<Component | null>(null);
    const currentProps = ref<object | null | undefined>(null);

    const unsubs = ref<(() => void)[]>([]);
    const [isOpen, isVisible] = useDelayedToggle();

    function remove(uuid: string) {
        activeDialogs.value = activeDialogs.value.filter((dialog) => dialog.uuid !== uuid);
    }

    watch(isVisible, (newValue) => {
        if (!newValue) {
            currentDialog.value = null;
            currentProps.value = null;
        }
    });

    onMounted(() => {
        REGISTERED_DIALOG_NAMES.forEach((dialogName) => {
            const unsub = dialogsEventBus.on(dialogName, (properties) => {
                activeDialogs.value.push({ dialog: dialogName, props: properties, uuid: v4() });

                // currentDialog.value = REGISTERED_DIALOGS[dialogName] as Component;
                // currentProps.value = properties;
                // isOpen.value = true;
            });

            unsubs.value.push(unsub);
        });
        dialogsEventBus;
    });

    onBeforeUnmount(() => {
        unsubs.value.forEach((unsub) => {
            unsub();
        });
    });
</script>

<template>
    <GlobalDialog
        v-for="dialog in activeDialogs"
        :key="dialog.uuid"
        :dialog="dialog.dialog"
        :dialogProps="dialog.props"
        :uuid="dialog.uuid"
        @remove="remove"
    />
    <!-- <component :is="currentDialog" v-bind="currentProps" v-if="currentDialog && isVisible" v-model="isOpen" /> -->
</template>
