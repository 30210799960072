import { useScreens } from '@/features/screens/hooks/useScreens';
import type { Ref } from 'vue';
import { computed } from 'vue';
import { useCampaignsConfig } from './useCampaignsConfig';

const DEFAULT_MAX_PLAYS = 20000;
const AVG_DAYS_PER_MONTH = 30;

/** Calculation for the max & min number of plays for an Order */
export function useNumberOfPlaysRange(screenIds: Ref<Nullish<number[]>>) {
    const { screens } = useScreens(screenIds);
    const { campaignsConfig } = useCampaignsConfig();

    const minPlays = computed(() => {
        return campaignsConfig.value?.orderMinMonthlyPlays;
    });

    const maxPlays = computed(() => {
        if (!campaignsConfig.value) return DEFAULT_MAX_PLAYS;
        if (!screens.value) return DEFAULT_MAX_PLAYS;

        return screens.value.reduce((acc, screen) => {
            return acc + (screen.maxPlaysPerDay * AVG_DAYS_PER_MONTH);
        }, 0);
    });

    return {
        minPlays,
        maxPlays,
    };
}
