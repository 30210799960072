<script setup lang="ts">
    import { computed, ref } from 'vue';

    import type { OrderRunStatus } from '../api/orderRunsApiResponseModels';
    import { ORDER_RUN_STATUS_UI_CONFIG } from '../config';

    interface StatusConfig {
        color: string;
        icon: string;
        text: string;
    }

    type Props = {
        status: OrderRunStatus;
        size?: 'x-small' | 'small' | 'default' | 'large' | 'x-large';
    };

    const props = withDefaults(defineProps<Props>(), {
        size: 'small',
    });

    const configGrid = ref<Record<OrderRunStatus, StatusConfig>>(ORDER_RUN_STATUS_UI_CONFIG);

    const color = computed(() => configGrid.value[props.status].color);
    const icon = computed(() => configGrid.value[props.status].icon);
    const text = computed(() => configGrid.value[props.status].text);
</script>

<template>
    <v-chip :color="color" :prepend-icon="icon" :size="size">
        {{ text }}
    </v-chip>
</template>
