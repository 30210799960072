import type { ErrorsResponse } from '@/features/api/models';
import type { Ref } from 'vue';
import { computed, ref, toValue } from 'vue';
import { useScreens } from './useScreens';

// Will use cached screens to get the requested screen, we hide all screens in memory
// TODO: Include a cache-less option to fetch the screen directly from the server
export function useScreen(screenId: number | Ref<number>) {
    const { screens, isLoading, error: screensError } = useScreens();
    const notFoundError = ref<ErrorsResponse | null>(null);

    const screen = computed(() => {
        if (isLoading.value) return null;
        if (!screens.value) return null;

        const screen = screens.value.find((s) => s.id === toValue(screenId));

        if (!screen) {
            notFoundError.value = {
                type: 'NotFound',
                errors: [{
                    message: 'Screen not found',
                }],
            };
        }

        return screen;
    });

    const errors = computed(() => {
        if (screensError.value) return screensError.value;
        if (notFoundError.value) return notFoundError.value;
        return null;
    });

    return {
        isLoading,
        screen,
        errors,
    };
}
