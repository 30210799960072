<script setup lang="ts">
    import AffixedPos from '@/components/AffixedPos/AffixedPos.vue';
    import { ref , watch , computed } from 'vue';
    
    
    import { useFileSelect } from '../hooks';
    import type { FileDropzoneModel } from './types';

    type Props = {
        fluid?: boolean;
        minHeight?: string | number;
        disabled?: boolean;
    };

    type Emits = {
        fileSelected: [FileDropzoneModel];
    };

    const props = defineProps<Props>();
    const emits = defineEmits<Emits>();
    const model = defineModel<FileDropzoneModel | null>({ required: true });

    const dropzoneRef = ref<HTMLElement>();

    const classes = computed(() => {
        if (props.fluid) {
            return 'w-100 h-100';
        }

        return '';
    });

    const { openFileDialog, isOverDropZone, file, fileObjectUrl, clear } = useFileSelect({
        target: dropzoneRef,
        onFileInput: (inputFile) => {
            if (!fileObjectUrl.value) throw new Error('File object url is not set');

            model.value = { file: inputFile, fileObjectUrl: fileObjectUrl.value };
            emits('fileSelected', { file: inputFile, fileObjectUrl: fileObjectUrl.value });
        },
        onFileClear: () => {
            model.value = null;
        },
    });

    // If the parent component sets the model to null, we should clear the file input
    watch(model, (newVal, oldVal) => {
        if (!newVal && oldVal) {
            clear();
        } else if (!newVal && file.value) {
            clear();
        }
    });
</script>

<template>
    <v-card
        ref="dropzoneRef"
        class="dropzone-container d-flex align-center justify-center font-weight-regular d-flex flex-column"
        :min-height="minHeight"
        :class="classes"
        :disabled="disabled"
        :color="isOverDropZone ? 'surface-container-highest' : 'surface-container'"
        @click="openFileDialog"
    >
        <v-btn
            v-if="file"
            position="absolute"
            location="top right"
            color="on-surface-container"
            size="large"
            density="compact"
            icon="mdi-close"
            class="mr-2 mt-2"
            @click.stop.prevent="clear"
        />

        <span v-if="!file" class="on-surface-container text-h2 ma-0">
            <slot>
                Click Or Drag File here
            </slot>
        </span>
        <span v-if="file">{{ file.size }}</span>
        <AffixedPos location="bottom center" class="w-100">
            <v-card class="rounded-t-0 pa-0 supported-card" rounded="0" width="100%">
                <v-card-text class="d-flex justify-center py-2 text-body-2">
                    <b class="mr-2"><i>Supported formats:</i></b>
                    <code>.png,.jpeg,.jpg,.mp4</code>
                </v-card-text>
            </v-card>
        </AffixedPos>
    </v-card>
</template>

<style scoped lang="scss">
.fit-content {
    width: fit-content;
}

.dropzone-container {
    border-width: 4px;
    border-style: dashed;
    height: 230px;
    padding: 16px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}

.supported-card {
    background-color: rgba(255, 255, 255, 0.05);
}
</style>
