import type { Ref } from 'vue';
import { computed } from 'vue';
import { useDiscountMultiple } from './useDiscountMultiple';
import { usePricingConfig } from './usePricingConfig';
import type { FlightDurationParams } from './utils';

export function usePricePerPlay(
    adDuration: Ref<number | null | undefined>,
    orderDuration: Ref<FlightDurationParams | null | undefined>,
) {
    const { isLoading: configLoading, pricingConfig } = usePricingConfig();
    const { isLoading: discountLoading, discountMultiple } = useDiscountMultiple(orderDuration);

    const isLoading = computed(() => configLoading.value || discountLoading.value);

    const pricePerPlay = computed(() => {
        if (!discountMultiple.value || !pricingConfig.value || !adDuration.value) return null;

        return pricingConfig.value.costPerPlay[adDuration.value] * discountMultiple.value;
    });

    const rawPricePerPlay = computed(() => {
        if (!discountMultiple.value || !pricingConfig.value || !adDuration.value) return null;

        return pricingConfig.value.costPerPlay[adDuration.value];
    });

    return {
        isLoading,
        pricePerPlay,
        rawPricePerPlay,
    };
}
