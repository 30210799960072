<script setup lang="ts">
    import { toast } from 'vuetify-sonner';

    type Props = {
        title?: string;
        value?: string | number;
    };
    const props = defineProps<Props>();

    async function copyValue() {
        if (!props.value) return;
        await navigator.clipboard.writeText(`${props.value}`);
        toast.info(`"${props.value}" copied to clipboard`);
    }
</script>

<template>
    <v-card class="container d-flex" rounded="sm" variant="flat">
        <div class="title pa-2 bg-surface-container-lowest on-surface">
            <slot name="title">{{ title }}</slot>
        </div>
        <div class="pa-2 bg-surface-container-low flex-grow-1 text-center">
            <slot>{{ value }}</slot>
        </div>
        <div v-if="value" class="d-flex bg-surface-container-low align-center pr-2">
            <v-icon icon="mdi-content-copy" title="Copy" @click="copyValue" />
        </div>
    </v-card>
</template>

<style scoped lang="scss">
.container {
    border: 2px solid rgba(var(--v-theme-on-surface), 0.175) !important;
}
.title {
    border-right: 2px solid rgba(var(--v-theme-on-surface), 0.4);
}
</style>
