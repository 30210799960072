<script setup lang="ts">
    import { computed } from 'vue';

    type Emphasis =
        | 'high'
        | 'medium'
        | 'low'
        | 'disabled'
        | 'idle'
        | '1'
        | '2'
        | '3'
        | '4'
        | '5'
        | '6'
        | '7'
        | '8'
        | '9';

    type Props = {
        color?: string;
        weight?: 'thin' | 'light' | 'regular' | 'medium' | 'bold' | 'black';
        emphasis?: Emphasis;
        charWidth?: number;
        size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
        is?: string;
        inline?: boolean;
    };

    const props = defineProps<Props>();

    const textColor = computed(() => {
        if (!props.color) return undefined;
        return `text-${props.color}`;
    });

    const classes = computed(() => {
        const classList: string[] = [];
        if (textColor.value) classList.push(textColor.value);
        if (props.emphasis) classList.push(`emphasis-${props.emphasis}`);
        if (props.weight) classList.push(`font-weight-${props.weight}`);

        return classList;
    });

    const style = computed(() => {
        const style = [];
        if (props.charWidth) style.push(`width: ${props.charWidth}ch;`);
        if (props.size) {
            style.push(
                `--text-fz: var(--app-font-size-${props.size})`,
                `--text-lh: var(--app-line-height-${props.size})`,
            );
        }

        return style;
    });

    const is = computed(() => {
        if (props.is) return props.is;
        return props.inline ? 'span' : 'p';
    });
</script>

<template>
    <component :is="is" class="custom-text" :class="classes" :style="style">
        <slot />
    </component>
</template>

<style lang="scss" scoped>
.custom-text {
    font-size: var(--text-fz);
    line-height: var(--text-lh);
}
</style>
