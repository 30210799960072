import { useApi } from '@/features/api';
import type { Ref } from 'vue';
import { computed, toValue } from 'vue';

const STALE_TIME = 30000;

export function useOrderSummaryLink(orderId: Ref<string>) {
    const enabled = computed(() => !!toValue(orderId));

    const { isLoading, data, error } = useApi(['orders/GET_ORDER_SUMMARY_LINK', orderId], (endpoint, fetcher) => {
        return fetcher(endpoint, {
            orderId: toValue(orderId)!,
        });
    }, { staleTime: STALE_TIME, enabled });

    return {
        isLoading,
        link: data,
        error,
    };
}
