import { useApi } from '@/features/api/hooks';

const CACHE_TIME = 120000; // 2 minutes

export function useCampaignsConfig() {
    const { isLoading, data } = useApi('campaigns/GET_CONFIG', (endpoint, fetcher) => {
        return fetcher(endpoint);
    }, { staleTime: CACHE_TIME, cacheTime: CACHE_TIME });

    return {
        isLoading,
        campaignsConfig: data,
    };
}
