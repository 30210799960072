import { useApi } from '@/features/api/hooks';
import { computed, type Ref, toValue } from 'vue';

export function useCompanyCreativesList(companyId: Ref<number | undefined>) {
    const enabled = computed(() => !!toValue(companyId));

    const { isLoading, data } = useApi(['creatives/LIST_CREATIVES', companyId], (endpoint, fetcher) => {
        return fetcher(endpoint, { companyId: companyId.value! });
    }, { enabled });

    return {
        isLoading,
        creatives: data,
    };
}
