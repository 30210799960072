import type { InvoiceStatus } from './api';

export interface InvoiceUiConfigItem {
    color: string;
    icon: string;
    text: string;
    /** The text using in an alert banner. If omitted, no banner should be shown */
    alertText?: string;
}

export const INVOICE_STATUS_UI_CONFIGS: Record<InvoiceStatus, InvoiceUiConfigItem> = {
    None: {
        color: 'grey',
        icon: 'mdi-alert-circle',
        text: 'N/A',
    },
    Draft: {
        color: 'grey',
        icon: 'mdi-alert-circle',
        text: 'Draft',
    },
    Open: {
        color: 'warning',
        icon: 'mdi-alert',
        text: 'Open',
    },
    Paid: {
        color: 'success',
        icon: 'mdi-check-circle',
        text: 'Paid',
        alertText: 'Invoice Paid on',
    },
    Void: {
        color: 'grey',
        icon: 'mdi-close-circle-outline',
        text: 'Void',
        alertText: 'Invoice Voided',
    },
    Uncollectable: {
        color: 'error',
        icon: 'mdi-alert-circle',
        text: 'Uncollectable',
        alertText: 'Invoice marked Uncollectable',
    },
};
