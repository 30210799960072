import { useCampaignsConfig } from '@/features/campaigns/hooks';
import type { ModelRef } from 'vue';
import { computed, ref, watch, watchEffect } from 'vue';

/** Composable specifically used for ad-duration inputs */
export function useAdDurationsInput(modelValue: ModelRef<number | null>) {
    const { campaignsConfig, isLoading } = useCampaignsConfig();
    // Used only when the input vs v-model need to be separated like an in an item group
    const inputValue = ref<number | null>(null);

    const adDurations = computed(() => {
        return campaignsConfig.value?.adDurations;
    });

    const selectOptions = computed(() => {
        if (!adDurations.value) return [];

        return adDurations.value.map((duration) => ({
            title: `${duration} seconds`,
            value: duration,
        }));
    });

    watch(() => modelValue.value, async (value) => {
        // Wait for ad durations to be loaded before setting the input value
        // This avoids the input being stuck in null state
        if (!adDurations.value) {
            await new Promise<void>((resolve) => {
                const unsubscribe = watchEffect(() => {
                    if (adDurations.value) {
                        unsubscribe();
                        resolve();
                    }
                });
            });
        }

        if (!adDurations.value) throw new Error('Ad durations not loaded');

        if (value === null) {
            inputValue.value = null;
            return;
        }

        const durationIndex = adDurations.value.indexOf(value);
        if (durationIndex == inputValue.value) return;

        inputValue.value = durationIndex;
    }, { immediate: true });

    watch(() => inputValue.value, (value) => {
        if (!adDurations.value) throw new Error('Ad durations not loaded');

        if (value === null) {
            modelValue.value = null;
        } else {
            modelValue.value = adDurations.value[value];
        }
    });

    return {
        isLoading,
        inputValue,
        selectOptions,
        adDurations,
    };
}
