import { computed } from 'vue';
import type { Ref } from 'vue';
import type { CompanyResponse } from '../api';

export function useCompanySectors(company: Ref<CompanyResponse | undefined>) {
    const companySectors = computed(() => {
        if (!company.value) return null;

        return company.value.metadata.industries.map((industry) => industry.sectorId) ?? [];
    });

    return {
        companySectors,
    };
}
