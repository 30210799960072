import { notifyNotImplemented } from '@/shared/hooks';
import type { App } from 'vue';

export const toastsPlugin = {
    install(app: App) {
        app.config.globalProperties.$notImplemented = (name: string) => {
            notifyNotImplemented(name);
        };
    },
};
