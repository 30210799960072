<script setup lang="ts">
    import { useSynced } from '@/shared/hooks';
    import { toRef } from 'vue';

    type Props = {
        count: number;
        acceptRisk: boolean;
    };

    type Emits = {
        'update:acceptRisk': [value: boolean];
    };

    const props = withDefaults(defineProps<Props>(), {
        active: false,
        count: 0,
        acceptRisk: false,
    });

    const emits = defineEmits<Emits>();

    const syncedAcceptRisk = useSynced(toRef(() => props.acceptRisk), 'update:acceptRisk', emits);
</script>

<template>
    <v-alert type="warning" color="orange" class="mb-2" dense dismissible elevation="0">
        <span class="text-body-1">This password has found in a data breach <u><b>{{ $filters.number(count) }}</b></u>
            times. Please use a different password.</span>
        <v-checkbox
            v-model="syncedAcceptRisk"
            label="I agree to use a compromised password"
            class="mt-2"
            required
            color="black"
            density="compact"
            hide-details
        />
    </v-alert>
</template>
