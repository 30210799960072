<script setup lang="ts">
    import RenderResultErrors from '@/components/RenderResultErrors.vue';
    import { useCommand } from '@/features/api';
    import { useDialogWidth } from '@/shared/hooks';
    import { ref } from 'vue';

    interface Props {
        orderRunId: string;
        orderId: string;
    }

    const props = defineProps<Props>();
    const modelValue = defineModel<boolean>();
    const dialogWidth = useDialogWidth({ md: 0.75, lg: 0.75, xl: 0.65 });

    const { isLoading, mutateAsync: resume, error } = useCommand('orderRuns/RESUME', {
        invalidates: ['campaigns/', 'orders/LIST_ORDERS'],
        onSettled: (_data, error) => {
            if (!error) {
                close();
            }
        },
    });

    const formRef = ref<VuetifyForm>();
    const notes = ref<string>('');

    async function handleResume() {
        if (!formRef.value) throw new Error('Form ref is not defined');
        if (!(await formRef.value.validate()).valid) return;

        await resume({ params: { runId: props.orderRunId }, request: notes.value });
    }

    function close() {
        modelValue.value = false;
    }
</script>

<template>
    <v-dialog v-model="modelValue" :max-width="dialogWidth">
        <v-card>
            <v-card-title class="text-center pt-2 bg-surface-container">
                <span class="text-h2">Put Run On Hold</span>
            </v-card-title>
            <v-card-subtitle class="text-center pt-2 bg-surface-container">
                This will resume the run. Ads will resume playing.
            </v-card-subtitle>
            <v-divider thickness="2px" />
            <v-card-text class="mt-4">
                <v-form ref="formRef">
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                v-model="notes"
                                label="Reason"
                                hint="Why is the run being resumed?"
                                :rules="[(v: string) => !!v || 'Resume notes are required']"
                                persistent-hint
                                required
                                outlined
                                dense
                                clearable
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="grey" size="large" @click="() => (modelValue = false)">
                    Cancel
                </v-btn>
                <v-spacer />
                <v-btn color="green" size="large" :loading="isLoading" @click="handleResume">
                    Resume
                </v-btn>
            </v-card-actions>
            <RenderResultErrors :result="error" />
        </v-card>
    </v-dialog>
</template>
