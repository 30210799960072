import type { JwtToken } from './jwtManager';

export interface TokenSource {
    getToken(): JwtToken;
}

export interface TokenProvider {
    getToken(): JwtToken;
    registerSource: (source: TokenSource) => void;
}

// This exists to avoid circular dependencies between auth & API
export class TokenProviderImpl implements TokenProvider {
    private source: TokenSource | null = null;
    private sourceGetter: (() => TokenSource) | null = null;

    getToken(): JwtToken {
        if (!this.source && !this.sourceGetter) throw new Error('Token source not registered');

        if (!this.source && this.sourceGetter) {
            this.source = this.sourceGetter();
        }

        if (!this.source) throw new Error('Token source not registered after getter called');

        return this.source.getToken();
    }

    registerDeferredSource(source: () => TokenSource): void {
        this.sourceGetter = source;
    }

    registerSource(source: TokenSource): void {
        this.source = source;
    }
}
