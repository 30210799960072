<script setup lang="ts">
    import NoDataImage from '@/assets/images/no-data.png';
    import { useCompanyCreativesList } from '@/features/creatives';
    import { MediaKindChip } from '@/features/creatives/components';
    import { useDialogWidth } from '@/shared/hooks';
    import { computed, toRef } from 'vue';

    type Props = {
        companyId: number;
        excludeIds?: string[];
        durationFilter?: number | null;
    };

    type Emits = {
        selected: [value: string];
    };

    const props = withDefaults(defineProps<Props>(), {
        excludeIds: () => [],
        durationFilter: undefined,
    });

    const emits = defineEmits<Emits>();

    const modelValue = defineModel<boolean>();

    const { isLoading, creatives } = useCompanyCreativesList(toRef(() => props.companyId));
    const dialogWidth = useDialogWidth();

    const filteredCreatives = computed(() => {
        if (!creatives.value) return [];

        return creatives.value.filter((creative) => {
            if (props.excludeIds.includes(creative.id)) {
                return false;
            }
            if (props.durationFilter && creative.mediaKind === 'Video' && creative.duration !== props.durationFilter) {
                return false;
            }
            return true;
        });
    });

    const headers = [
        { title: 'Thumbnail', key: 'thumbnailUrl', width: '89px', align: 'center', sortable: false },
        { title: 'Media', key: 'mediaKind', width: '5rem', align: 'center' },
        { title: 'Name', key: 'name' },
        { title: 'Duration', key: 'duration', width: '3rem', align: 'center' },
        { title: 'Notes', key: 'notes' },
    ] as never;

    // Actually a ItemSlotBase<> but vuetify doesn't expose this?
    function rowClick(_: never, item: { internalItem: never; toggleSelect: (item: never) => void; }) {
        // @ts-expect-error - Lack of vuetify typings makes this suck
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
        emits('selected', item.internalItem.raw.id);
        modelValue.value = false;
    }
</script>

<template>
    <v-dialog v-model="modelValue" :width="dialogWidth">
        <v-card>
            <v-card-title class="text-center mt-2">
                <span class="text-h2">Select Creative</span>
            </v-card-title>
            <v-card-text style="height: 80vh">
                <v-row justify="center">
                    <v-col cols="12">
                        <v-data-table
                            item-value="id"
                            color="primary"
                            :headers="headers"
                            :items="filteredCreatives"
                            :loading="isLoading"
                            item-key="id"
                            @click:row="rowClick"
                        >
                            <template v-slot:[`item.name`]="{ item }">
                                <div class="text-h3">
                                    {{ item.name }}
                                </div>
                            </template>
                            <template v-slot:[`item.mediaKind`]="{ item }">
                                <MediaKindChip :value="item.mediaKind" />
                            </template>
                            <template v-slot:[`item.thumbnailUrl`]="{ item }">
                                <v-card class="ma-2 pa-0" elevation="2" rounded>
                                    <v-menu
                                        origin="right center"
                                        target="parent"
                                        :open-delay="0"
                                        open-on-hover
                                        transition="scale-transition"
                                        width="300px"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-img
                                                width="89px"
                                                :src="item.thumbnailUrl || NoDataImage"
                                                v-bind="props"
                                            />
                                        </template>
                                        <v-img :src="item.thumbnailUrl || NoDataImage" />
                                    </v-menu>
                                </v-card>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="grey" @click="() => (modelValue = false)">
                    Cancel
                </v-btn>
                <v-spacer />
                <v-btn color="primary" @click="() => (modelValue = false)">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped lang="scss">
.fit-content {
    width: fit-content;
}

:deep(.v-data-table__tr:has(input[type="checkbox"]:checked)) {
    background-color: rgb(var(--v-theme-surface-container-low));
}
</style>
