import type { Capability, ResourceConstraintOption, ScopelessCapability } from '@/features/auth/perms';
import type { ListItemConfig, VisibleListItemConfig } from '@/shared/types';
import type { OrderStatus, OrderStatusChangeKind } from '../api';

type TransitionConfig = ListItemConfig & {
    capability: {
        capability: Capability | ScopelessCapability;
    } & Partial<ResourceConstraintOption>;
};

export const ORDER_STATUS_TRANSITION_UI_CONFIG: Record<OrderStatusChangeKind, TransitionConfig> = {
    // Unused
    Create: {
        color: 'grey',
        icon: 'mdi-file-document-edit-outline',
        text: 'Created',
        capability: {
            capability: 'campaign:create',
        },
    },
    ReturnToDraft: {
        color: 'grey',
        icon: 'mdi-file-document-edit-outline',
        text: 'Return to Draft',
        capability: {
            capability: 'campaign:create',
        },
    },
    Edit: { // Unused
        color: 'grey',
        icon: 'mdi-file-document-edit-outline',
        text: 'Edit',
        capability: {
            capability: 'campaign:create',
        },
    },
    Undo: { // Unused
        color: 'orange',
        icon: 'mdi-undo-variant',
        text: 'Undo',
        capability: {
            capability: 'campaign:create',
        },
    },
    SubmitForOrgReview: {
        color: 'blue',
        icon: 'mdi-arrow-top-right',
        text: 'Submit for Org Review',
        capability: {
            capability: 'campaign:create',
            sameCompany: true,
            createdBy: true,
        },
    },
    OrgApprove: {
        color: 'green',
        icon: 'mdi-check',
        text: 'Approve',
        capability: {
            capability: 'campaign:manage',
            sameCompany: true,
        },
    },
    SubmitForPlatformReview: {
        color: 'blue',
        icon: 'mdi-arrow-top-right',
        text: 'Submit for Platform Review',
        capability: {
            capability: 'campaign:manage',
            sameCompany: true,
        },
    },
    Reject: {
        hidden: true,
        color: 'red',
        icon: 'mdi-circle-off-outline',
        text: 'Reject',
        capability: {
            capability: 'campaign:manage:any',
        },
    },
    ApproveRun: {
        color: 'green',
        icon: 'mdi-check',
        text: 'Review',
        capability: {
            capability: 'campaign:manage:any',
        },
    },
    InvoiceCreated: {
        hidden: true,
        color: 'green',
        icon: 'mdi-check',
        text: 'Invoice Created',
        capability: {
            capability: 'campaign:manage:any',
        },
    },
    MadeInitialPayment: { // Should be an internal-only action, not admin-facing
        hidden: true,
        color: 'green',
        icon: 'mdi-check',
        text: 'Mark Paid',
        capability: {
            capability: 'campaign:manage:any',
        },
    },
    StartRun: {
        hidden: true,
        color: 'blue',
        icon: 'mdi-play-circle-outline',
        text: 'Start Run',
        capability: {
            capability: 'campaign:manage:any',
        },
    },
    CreateLinkedRun: {
        icon: 'mdi-clock-plus-outline',
        text: 'Create Run',
        color: 'blue',
        capability: {
            capability: 'campaign:manage:any',
        },
    },
    CancelRun: {
        color: 'red',
        icon: 'mdi-cancel',
        text: 'Cancel Run',
        capability: {
            capability: 'campaign:manage',
        },
    },
    CompleteRun: {
        color: 'green',
        icon: 'mdi-check',
        text: 'Complete Run',
        capability: {
            capability: 'campaign:manage:any',
        },
    },
};

export const ORDER_STATUS_UI_CONFIG = {
    None: {
        color: 'grey',
        icon: 'mdi-alert-circle-outline',
        text: 'ERROR',
    },
    Draft: {
        color: 'grey',
        icon: 'mdi-file-document-edit-outline',
        text: 'Draft',
    },
    PendingOrgApproval: {
        color: 'amber',
        icon: 'mdi-clock-outline',
        text: 'Pending Org Approval',
    },
    OrgApproved: {
        color: 'green',
        icon: 'mdi-progress-check',
        text: 'Org Approved',
    },
    PendingPlatformApproval: {
        color: 'amber',
        icon: 'mdi-clock-check-outline',
        text: 'Pending Platform Approval',
    },
    PlatformRejected: {
        color: 'red',
        icon: 'mdi-close-circle-outline',
        text: 'Rejected',
    },
    PlatformApproved: {
        color: 'green',
        icon: 'mdi-progress-check',
        text: 'Platform Approved',
    },
    PendingInitialPayment: {
        color: 'amber',
        icon: 'mdi-invoice-clock-outline',
        text: 'Pending Payment',
    },
    PendingRunCreation: {
        color: 'amber',
        icon: 'mdi-invoice-clock-outline',
        text: 'Pending Run',
    },
    RunScheduled: {
        color: 'blue',
        icon: 'mdi-calendar-clock',
        text: 'Run Scheduled',
    },
    Running: {
        color: 'blue',
        icon: 'mdi-play-circle-outline',
        text: 'Running',
    },
    Completed: {
        color: 'green',
        icon: 'mdi-check-circle-outline',
        text: 'Completed',
    },
    Cancelled: {
        color: 'red-lighten-2',
        icon: 'mdi-close-circle-outline',
        text: 'Cancelled',
    },
    Terminated: {
        color: 'red-lighten-2',
        icon: 'mdi-close-octagon-outline',
        text: 'Terminated',
    },
} as const satisfies Record<OrderStatus, VisibleListItemConfig>;

export const ORDER_STATUS_UI_CONFIG_LIST = Object.entries(ORDER_STATUS_UI_CONFIG).map(([status, config]) => ({
    status: status as OrderStatus,
    ...config,
}));

export function getOrderStatusColor(status: OrderStatus) {
    return ORDER_STATUS_UI_CONFIG[status].color;
}

// export const ORDER_STATUS_ACTIONS: Record<OrderStatus, string[]> = {
//     Draft: ['Edit', 'Delete', 'Submit'],
//     PendingApproval: ['Edit', 'MakeDraft', 'Approve'],
//     Approved: ['Unapprove', 'SubmitForRun'],
//     PendingRunApproval: ['ApproveRun', 'RejectRun'],
// }
