export function formatTimespan(time: number | null | undefined) {
    if (time === null || time === undefined) return 0;
    return new Date(time).toLocaleTimeString('en-US', { hour12: false, minute: 'numeric', second: '2-digit' });
}

export function formatTimespanDays(time: number | null | undefined) {
    if (time === null || time === undefined) return 0;

    const date = new Date(time);
    const days = Math.floor(date.getTime() / (1000 * 60 * 60 * 24));
    return days;
}
