<script setup lang="ts">
    import { useCommand } from '@/features/api';
    import ViewOrderCard from '@/features/campaigns/components/orders/ViewOrderCard/ViewOrderCard.vue';
    import { useDialogWidth } from '@/shared/hooks';
    import { computed, ref } from 'vue';

    interface Props {
        orderId: string;
    }

    const props = defineProps<Props>();
    const modelValue = defineModel<boolean>();

    const { isLoading: approveLoading, mutateAsync: approveOrder } = useCommand('orders/APPROVE', {
        invalidates: ['orders/GET_ORDER', 'campaigns/LIST_CAMPAIGNS', 'orders/LIST_ORDERS'],
        onSettled(data, error) {
            if (!error) {
                modelValue.value = false;
            }
        },
    });

    const { isLoading: rejectLoading, mutateAsync: rejectOrder } = useCommand('orders/REJECT', {
        invalidates: ['orders/GET_ORDER', 'campaigns/LIST_CAMPAIGNS', 'orders/LIST_ORDERS'],
        onSettled(data, error) {
            if (!error) {
                modelValue.value = false;
            }
        },
    });

    const isLoading = computed(() => approveLoading.value || rejectLoading.value);

    const dialogWidth = useDialogWidth({ md: 0.75, lg: 0.6, xl: 0.6 });

    const formRef = ref<VuetifyForm>();
    const notes = ref<string>('');

    async function approve() {
        await approveOrder({
            request: notes.value,
            params: {
                orderId: props.orderId,
            },
        });
    }

    async function reject() {
        if (!formRef.value) throw new Error('Form ref is not defined');
        if (!(await formRef.value.validate()).valid) return;

        await rejectOrder({
            request: notes.value,
            params: {
                orderId: props.orderId,
            },
        });
    }
</script>

<template>
    <v-dialog v-model="modelValue" persistent scrollable :max-width="dialogWidth">
        <ViewOrderCard :order-id="props.orderId">
            <template v-slot:content>
                <v-form ref="formRef" :fast-fail="false" class="mt-6">
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                v-model="notes"
                                label="Review Notes"
                                hint="Why are you approving or rejecting this order?"
                                :rules="[(v: string) => !!v || 'Rejection notes are required']"
                                persistent-hint
                                required
                                outlined
                                dense
                                clearable
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </template>
            <template v-slot:actions>
                <v-card-actions>
                    <v-btn color="grey" size="large" @click="() => (modelValue = false)">
                        Cancel
                    </v-btn>
                    <v-spacer />
                    <v-btn color="red" size="large" :loading="isLoading" @click="reject">
                        Reject
                    </v-btn>
                    <v-btn color="green" size="large" :loading="isLoading" @click="approve">
                        Approve
                    </v-btn>
                </v-card-actions>
            </template>
        </ViewOrderCard>
    </v-dialog>
</template>
