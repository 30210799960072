import { useApi } from '@/features/api';
import { type Ref, toValue } from 'vue';
import type { CompanySearchRequest } from '../api';

export function useCompanyLiveSearch(companySearchTerm: Ref<string>, filter: Ref<CompanySearchRequest | undefined>) {
    const {
        isLoading,
        data,
        error,
    } = useApi(['company/LIVE_SEARCH', companySearchTerm], (endpoint, fetcher) => {
        return fetcher(endpoint, toValue(filter) || {}, {
            query: companySearchTerm.value,
        });
    }, { keepPreviousData: true });

    return {
        searchLoading: isLoading,
        searchResults: data,
        searchErrors: error,
    };
}
