import type { RouteRecordRaw } from 'vue-router';

export const routes = [
    {
        path: '/',
        name: 'Root',
        meta: {
            requiresAuth: true,
        },
        component: () => import('@/layouts/main/MainLayout.vue'),
        redirect: { name: 'Home' },
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import('@/views/Home.vue'),
            },
            {
                path: 'screens',
                name: 'Screens',
                component: () => import('@/features/screens/pages/BrowseScreensPage.vue'),
            },
            {
                path: 'companies/my-company',
                name: 'ManageMyCompany',
                meta: {
                    title: 'Company Settings',
                },
                component: () => import('@/features/companies/pages/MyCompanySettings/MyCompanySettings.vue'),
            },
            {
                path: 'users',
                name: 'Users',
                component: () => import('@/features/users/pages/ManageUsers.vue'),
                redirect: { name: 'AllUsersTable' },
                children: [
                    {
                        path: '',
                        name: 'AllUsersTable',
                        component: () => import('@/features/users/components/AllUsersTable.vue'),
                    },
                    {
                        path: ':userId',
                        props: route => ({ userId: route.params.userId, isFromRoute: true }),
                        name: 'ManageUser',
                        component: () => import('@/features/users/pages/ManageUser.vue'),
                    },
                ],
            },
            {
                path: 'companies',
                name: 'Companies',
                redirect: { name: 'ManageCompanies' },
                children: [
                    {
                        path: '',
                        name: 'ManageCompanies',
                        meta: {
                            title: 'Companies',
                        },
                        component: () => import('@/features/companies/pages/ManageCompanies.vue'),
                    },
                    {
                        path: 'company/:companyId',
                        props: true,
                        name: 'ManageCompany',
                        meta: {
                            title: 'Manage Company',
                        },
                        component: () => import('@/features/companies/pages/ManageCompany.vue'),
                    },
                ],
            },
            {
                path: 'campaigns',
                name: 'Campaigns',
                component: () => import('@/features/campaigns/pages/Campaigns/Campaigns.vue'),
                redirect: { name: 'CampaignsCardsList' },
                children: [
                    {
                        path: '',
                        name: 'CampaignsCardsList',
                        meta: {
                            title: 'Campaigns',
                        },
                        component: () => import('@/features/campaigns/pages/Campaigns/CampaignsCards.vue'),
                    },
                    {
                        path: 'orders',
                        name: 'AllOrdersTable',
                        meta: {
                            title: 'Orders',
                        },
                        component: () => import('@/features/campaigns/pages/Campaigns/CampaignsOrdersTable.vue'),
                    },
                    {
                        path: ':campaignId/create-order',
                        props: true,
                        name: 'CreateOrder',
                        meta: {
                            title: 'Create Order',
                        },
                        component: () => import('@/features/campaigns/pages/CreateOrder.vue'),
                    },
                ],
            },
            {
                path: 'creatives',
                name: 'ManageCreatives',
                children: [
                    {
                        path: '',
                        name: 'CreativesDataView',
                        meta: {
                            title: 'Creatives',
                        },
                        component: () => import('@/features/creatives/pages/ManageCreatives.vue'),
                    },
                ],
            },
            {
                path: 'invoices',
                name: 'ManageInvoices',
                children: [
                    {
                        path: '',
                        name: 'InvoicesDataView',
                        meta: {
                            title: 'Invoices',
                        },
                        component: () => import('@/features/invoices/pages/ManageInvoices.vue'),
                    },
                ],
            },
            {
                path: 'campaign',
                name: 'Campaign',
                children: [
                    {
                        path: ':campaignId/create-order',
                        props: true,
                        name: 'CreateOrder',
                        meta: {
                            title: 'Create Order',
                        },
                        component: () => import('@/features/campaigns/pages/CreateOrder.vue'),
                    },
                ],
            },
        ],
    },
    {
        path: '/auth',
        component: () => import('@/features/auth/layout/AuthLayout.vue'),
        // component: () => import('@/layouts/BlankLayout.vue'),
        children: [
            {
                path: 'login',
                name: 'Login',
                component: () => import('@/features/auth/pages/LoginPage.vue'),
            },
            {
                path: 'register',
                name: 'Register',
                component: () => import('@/features/auth/pages/AuthRegister.vue'),
            },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                component: () => import('@/features/auth/pages/AuthForgotPassword.vue'),
            },
            {
                path: 'reset-check-email',
                name: 'PasswordResetCheckEmail',
                props: true,
                component: () => import('@/features/auth/pages/AuthForgotPasswordCheckMail.vue'),
            },
            {
                path: 'register-check-email',
                name: 'RegisterCheckEmail',
                props: true,
                component: () => import('@/features/auth/pages/AuthRegisterCheckMail.vue'),
            },
            {
                path: 'verify-email',
                name: 'VerifyEmail',
                props: true,
                component: () => import('@/features/auth/pages/AuthVerifyEmail.vue'),
            },
            {
                path: 'accept-invite',
                name: 'AcceptInvite',
                props: true,
                component: () => import('@/features/auth/pages/AuthAcceptInvite.vue'),
            },
            {
                path: 'reset-password',
                name: 'ResetPassword',
                props: true,
                component: () => import('@/features/auth/pages/AuthResetPassword.vue'),
            },
            {
                path: 'change-password',
                name: 'ChangePassword',
                props: true,
                component: () => import('@/features/auth/pages/AuthChangePassword.vue'),
            },
        ],
    },
] satisfies RouteRecordRaw[];
