<script setup lang="ts">
    import { downloadZip } from 'client-zip';
    import { computed, ref, toRef, watch } from 'vue';
    import { toast } from 'vuetify-sonner';
    import { useCreativesDownloadUrls } from '../hooks/useCreativesDownloadUrls';

    type Props = {
        creativeIds: string[];
        fileName: string;
        disabled?: boolean;
        size?: string | number;
        density?: 'compact' | 'comfortable';
        contentClass?: string;
    };

    type Emits = {
        downloaded: [];
    };

    const props = defineProps<Props>();
    const emits = defineEmits<Emits>();
    const enable = ref(false);
    const loadingDownload = ref(false);
    const completed = ref(false);

    const { isLoading, urls } = useCreativesDownloadUrls(toRef(() => props.creativeIds), enable);

    function clickDownload() {
        if (!enable.value) {
            enable.value = true;
        }

        if (urls.value && urls.value.length > 0) {
            void openLink(urls.value);
        }
    }

    async function openLink(localUrls: string[]) {
        loadingDownload.value = true;
        toast.info('Started Downloading Creatives...');
        // Fetch each of them at the same time and make an array of the responses

        const responses: Response[] = [];

        try {
            for (const url of localUrls) {
                const response = await fetch(url);
                responses.push(response);
            }

            const blob = await downloadZip(responses).blob();

            // make and click a temporary link to download the Blob
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${props.fileName}.zip`;
            link.click();
        } catch (error) {
            toast.error('Error downloading files');
            loadingDownload.value = false;
        }

        loadingDownload.value = false;
        completed.value = true;
        emits('downloaded');
    }

    const icon = computed(() => {
        if (completed.value) {
            return 'mdi-check';
        }

        return 'mdi-download';
    });

    const color = computed(() => {
        if (completed.value) {
            return 'success';
        }

        return 'primary';
    });

    watch(urls, (newUrls) => {
        if (newUrls && enable.value && newUrls.length > 0) {
            void openLink(newUrls);
        }
    });
</script>

<template>
    <slot :loading="isLoading || loadingDownload" :disabled="disabled" :click-download="clickDownload">
        <v-btn
            :class="contentClass"
            variant="text"
            :icon="icon"
            :loading="isLoading || loadingDownload"
            :disabled="disabled"
            :color="color"
            :size="size"
            :density="density"
            @click="clickDownload"
        />
    </slot>
</template>
