<script setup lang="ts">
    import { useApiResponseErrors } from '@/features/api/hooks';
    import type { ErrorsResponse } from '@/features/api/models';
    import type { SimpleApiResult, SimpleResult } from '@/shared/types';
    import type { PropType } from 'vue';
    import { computed, toRef } from 'vue';

    const props = defineProps({
        result: {
            type: Object as PropType<SimpleResult | SimpleApiResult | ErrorsResponse | Error | null>,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    });

    const { errorMessages, duplicateErrorCount } = useApiResponseErrors(toRef(() => props.result));

    const classes = computed(() => ({
        'greyed-out': props.disabled,
    }));
</script>

<template>
    <div v-if="errorMessages.length" :class="classes" class="fade-transition">
        <slot v-if="errorMessages.length === 1" name="error" :error="errorMessages[0]">
            <v-alert :color="disabled ? 'grey' : 'error'" class="text-center" density="comfortable" variant="outlined">
                {{ errorMessages[0] }}
                <Transition name="shaketext">
                    <div
                        v-if="duplicateErrorCount"
                        :key="duplicateErrorCount"
                        class="duplicate-error-container font-italic text-body-2 ml-2"
                    >
                        x{{ duplicateErrorCount }}
                    </div>
                </Transition>
            </v-alert>
        </slot>
        <slot v-else name="errors" :errors="errorMessages">
            <v-alert color="error" dense outlined>
                <ul class="mb-0">
                    <li v-for="error in errorMessages" :key="error">
                        {{ error }}
                    </li>
                </ul>
            </v-alert>
        </slot>
    </div>
</template>

<style scoped lang="scss">
.fade-transition {
    transition: all 0.2s ease-in-out;
}
.greyed-out {
    opacity: 0.4;
}

.duplicate-error-container {
    display: inline-block;
}
</style>
