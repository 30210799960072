import { useApi } from '@/features/api/hooks';

const STALE_TIME = 5 * 60 * 1000; // 5 minutes
const GC_TIME = 5 * 60 * 1000; // 5 minutes

export function usePricingConfig() {
    const { isLoading, data } = useApi('pricing/GET_CONFIG', (endpoint, fetcher) => {
        return fetcher(endpoint);
    }, { staleTime: STALE_TIME, cacheTime: GC_TIME });

    return {
        isLoading,
        pricingConfig: data,
    };
}
