<script setup lang="ts">
    import { LinkButton, RenderResultErrors } from '@/components';
    import LoaderOverlay from '@/components/LoaderOverlay.vue';
    import OrderStatusChip from '@/features/campaigns/components/OrderStatusChip.vue';
    import SmallCreativeCard from '@/features/creatives/components/SmallCreativeCard.vue';
    import IndustrySectorIcon from '@/features/industries/components/IndustrySectorIcon.vue';
    import OrderRunProgressBar from '@/features/orderRuns/components/OrderRunProgressBar.vue';
    import OrderRunStatusChip from '@/features/orderRuns/components/OrderRunStatusChip.vue';
    import { useOrder } from '@/features/orders/hooks';
    import { useScreens } from '@/features/screens';
    import { useUserCompanyInfo } from '@/features/users/hooks';
    import { computed, toRef } from 'vue';
    import DetailCard from './DetailCard.vue';

    type Props = {
        orderId: string;
    };

    type Emits = {
        close: [];
    };

    const props = defineProps<Props>();
    const emits = defineEmits<Emits>();

    const { isLoading: orderLoading, error: orderError, order } = useOrder(toRef(() => props.orderId));
    const { isLoading: screensLoading, screens, error: screensError } = useScreens(toRef(() => order.value?.screenIds));
    const { isLoading: userLoading, userCompanyInfo, error: userError } = useUserCompanyInfo(
        toRef(() => order.value?.createdById),
    );

    const isLoading = computed(() => orderLoading.value || screensLoading.value || userLoading.value);
    const error = computed(() => orderError.value || screensError.value || userError.value);

    const distinctIndustries = computed(() => {
        return screens.value?.reduce((acc, screen) => {
            if (!acc.includes(screen.sectorName)) {
                acc.push(screen.sectorName);
            }
            return acc;
        }, [] as string[]);
    });

    function close() {
        emits('close');
    }
</script>
<template>
    <v-card v-if="order && screens && userCompanyInfo" color="surface-container" rounded="md">
        <v-card-title class="pb-0 d-flex align-center justify-space-between">
            <div class="font-weight-medium">
                <span class="text-h3 emphasis-8">
                    Order:
                </span>
                <span class="text-h2 text-blue-darken-2">
                    {{ order.name }}
                </span>
            </div>
            <span>
                <LinkButton
                    v-if="order.runId"
                    class="mr-3"
                    @click="$triggerDialog('viewOrderRun', { orderRunId: order.runId })"
                >
                    Run
                </LinkButton>
                <OrderRunStatusChip v-if="order.run" :status="order.run.status" size="default" />
                <OrderStatusChip v-else :status="order.status" size="default" />
            </span>
        </v-card-title>
        <v-card-subtitle class="d-flex mb-1 pt-1 gap-sm">
            <span class="text-h4 font-weight-medium">
                {{ $filters.date(order.startDate) }} - {{ $filters.date(order.endDate) }} ({{ order.flightTimeDays }}
                days)</span>
            <v-spacer />
            <span class="text-h4 font-weight-medium">
                <span>
                    <b>{{ $filters.abbreviate(order.playsPerMonth) }}</b>
                    plays/mo
                </span>
            </span>
            <span class="text-h4">
                <v-divider thickness="2px" vertical class="mx-2 on-surface emphasis-low" />
            </span>
            <span class="text-h4">
                <b>${{ $filters.number(order.totalPrice / order.flightTimeMonths) }}</b>/mo
            </span>
        </v-card-subtitle>
        <v-divider thickness="2px" />
        <!-- <v-card rounded="0">
            <v-card-text>
                <div class="flex-shrink-0">
                    {{ $filters.number(order.run.plays) }} / {{ $filters.number(order.run.targetPlays) }} Plays
                </div>
                <OrderRunProgressBar :order-run="order.run" show-text />
            </v-card-text>
        </v-card> -->
        <v-alert
            v-if="order.run"
            variant="tonal"
            icon="mdi-information-outline"
            color="blue-lighten-3"
            class="text-h4"
            rounded="0"
        >
            <div class="d-flex align-center gap-md">
                <OrderRunProgressBar :order-run="order.run" show-text />
            </div>
            <template v-slot:title>
                <div class="d-flex w-100 align-center justify-space-between">
                    <div v-if="order.run.status === 'Scheduled'">
                        Scheduled To Start On: {{ $filters.date(order.run.startDate) }}
                    </div>
                    <template v-if="order.run.status === 'Running'">
                        <div>
                            Run Started
                        </div>

                        <div>{{ order.run.daysRemaining }} days remaining</div>
                    </template>
                </div>
            </template>
        </v-alert>
        <v-card-text style="min-height: 50vh" class="bg-surface d-flex flex-column">
            <div class="d-flex flex-wrap gap-md">
                <DetailCard>
                    <template v-slot:title>
                        Company:
                    </template>
                    <LinkButton @click="$notImplemented('View Company')">{{ order.companyName }}</LinkButton>
                </DetailCard>
                <DetailCard>
                    <template v-slot:title>
                        Created By:
                    </template>
                    <div>
                        <v-icon icon="mdi-account" size="small" />
                        {{ order.createdByName }}
                    </div>
                    <div class="text-body-1 d-flex pt-1 justify-center">
                        <v-icon icon="mdi-domain" size="small" class="pr-2" />
                        <span>{{ userCompanyInfo?.companyName || 'N/A' }}</span>
                    </div>
                </DetailCard>
                <DetailCard>
                    <template v-slot:title>
                        Ad Duration:
                    </template>
                    <span>{{ order.adDuration }} Seconds</span>
                </DetailCard>
                <DetailCard>
                    <template v-slot:title>
                        Price /play:
                    </template>
                    <span>{{ $filters.currency(order.pricing.perPlay.pricePerPlay) }}</span>
                </DetailCard>
                <DetailCard>
                    <template v-slot:title>
                        Total Plays:
                    </template>
                    <span>{{ $filters.number(order.totalPlays) }}</span>
                </DetailCard>
            </div>

            <div class="my-2">
                <div class="text-h3 font-weight-medium">
                    Industries:
                </div>
                <div class="d-flex flex-wrap gap-sm pt-2">
                    <v-chip v-for="industry in distinctIndustries" :key="industry" color="grey">
                        <IndustrySectorIcon :sector="industry" size="h4" />
                        <span class="pl-1">
                            {{ industry }}
                        </span>
                    </v-chip>
                </div>
            </div>

            <v-divider class="my-3" thickness="2" />

            <v-expansion-panels class="locations-expansion rounded-sm">
                <v-expansion-panel color="on-primary-fixed">
                    <v-expansion-panel-title class="py-0 rounded-t-sm bg-surface-container-high">
                        <v-icon icon="mdi-map-marker-multiple" color="primary" class="mr-2 ml-n3" />
                        <v-card color="primary" variant="text" class="h-100 w-100">
                            <span class="text-h4">
                                {{ screens.length }} Locations
                            </span>
                        </v-card>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="bg-surface-container-low rounded-b-sm">
                        <v-table
                            class="rounded-sm bg-surface-container line-item-table flex-shrink-0"
                            density="compact"
                        >
                            <thead class="bg-surface-container-high">
                                <tr class="emphasis-8">
                                    <th class="text-left">Name</th>
                                    <th class="text-left">Industry</th>
                                    <th class="text-left">Business</th>
                                </tr>
                            </thead>
                            <tbody class="text-body-2">
                                <tr v-for="screen in screens" :key="screen.id">
                                    <td>{{ screen.title }}</td>
                                    <td>
                                        <IndustrySectorIcon :sector="screen.sectorName" />
                                        {{ screen.sectorName }}
                                    </td>
                                    <td>{{ screen.business }}</td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel color="on-primary-fixed">
                    <v-expansion-panel-title class="py-0 bg-surface-container-high">
                        <v-icon icon="mdi-map-marker-multiple" color="primary" class="mr-2 ml-n3" />
                        <v-card color="primary" variant="text" class="h-100 w-100">
                            <span class="text-h4">
                                {{ order.creativeIds.length }} Creatives
                            </span>
                        </v-card>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="bg-surface-container-low rounded-b-sm">
                        <v-row>
                            <v-col v-for="creativeId in order.creativeIds" :key="creativeId" cols="12" sm="6" lg="6">
                                <SmallCreativeCard :creative-id="creativeId" />
                            </v-col>
                        </v-row>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
            <slot name="content" />
        </v-card-text>
        <slot name="actions" :close="close">
            <v-card-actions>
                <v-spacer />
                <v-btn color="grey" size="large" @click="close">
                    Close
                </v-btn>
            </v-card-actions>
        </slot>

        <RenderResultErrors :result="error" />
    </v-card>
    <v-card v-else>
        <v-card-title class="pb-0 d-flex align-center justify-space-between">
            <v-skeleton-loader class="text-h3 emphasis-8" width="100%" type="heading" />
        </v-card-title>
        <v-card-text>
            <v-skeleton-loader class="text-h4" type="card" />
            <LoaderOverlay :active="isLoading" />
        </v-card-text>
        <v-card-actions>
            <v-skeleton-loader type="button" width="6rem" />
            <v-btn color="grey" size="large" @click="close">
                Close
            </v-btn>
        </v-card-actions>
        <RenderResultErrors :result="error" />
    </v-card>
</template>

<style lang="scss" scoped>
.line-item-table {
    border: 1px solid rgb(var(--v-theme-surface-container-highest));

    th {
        color: rgb(var(--v-theme-on-surface-container-low));
    }

    td {
        color: rgb(var(--v-theme-on-surface-container));
    }
}
</style>
