<script setup lang="ts">
    import AffixedPos from '@/components/AffixedPos/AffixedPos.vue';
    import { DataCompositeControls, DataCompositeFooter } from '@/components/DataTable';
    import type { BoundingBox } from '@/features/gis';
    import type { ScreenResponse } from '@/features/screens/api';
    import ScreensMap from '@/features/screens/components/ScreensMap.vue';
    import { useScreens } from '@/features/screens/hooks';
    import { useDelayedToggle } from '@/shared/hooks';
    import { filterBySearch } from '@/shared/utilities';
    import { computed, ref } from 'vue';
    import ConflictingSectorScreensDialog from './ConflictingSectorScreensDialog.vue';
    import ScreensSelectCardList from './ScreensSelectCardList.vue';
    import ScreensSelectDataTable from './ScreensSelectDataTable.vue';

    function filterByBoundingBox(screens: ScreenResponse[], boundingBox: BoundingBox) {
        const [sw, ne] = boundingBox;

        return screens.filter((s) => {
            return (
                s.position.lat >= sw[1]
                && s.position.lat <= ne[1]
                && s.position.long >= sw[0]
                && s.position.long <= ne[0]
            );
        });
    }

    type Props = {
        excludeSectors?: number[];
    };

    const props = withDefaults(defineProps<Props>(), {
        excludeSectors: undefined,
    });
    const selectedScreens = defineModel<number[]>({ default: [] });

    const visibleBounds = ref<BoundingBox>([[0, 0], [0, 0]]);
    const { isLoading, screens, screenIdsMap } = useScreens();

    const showOnlyVisible = ref(false);
    const search = ref('');
    const activeView = ref<'table' | 'list'>('table');
    const dataTableRef = ref<EasyDataTableFooter>();

    const [conflictingDialogOpen, conflictingDialogVisible] = useDelayedToggle();
    const selectedButExcluded = computed(() => {
        if (!screenIdsMap.value || !screenIdsMap.value.size) return null;
        if (!props.excludeSectors || !props.excludeSectors.length) return null;

        const excludedSelectedIds = selectedScreens.value.filter((id) => {
            const screen = screenIdsMap.value!.get(id);
            return screen && props.excludeSectors?.includes(screen.sectorId);
        });

        return excludedSelectedIds.map((id) => screenIdsMap.value!.get(id)!);
    });
    const hasConflictingScreens = computed(() => !!selectedButExcluded.value?.length);

    const filteredScreens = computed(() => {
        if (!screens.value) {
            return screens.value;
        }

        let filtered = screens.value;

        if (props.excludeSectors && props.excludeSectors.length) {
            filtered = filtered.filter((screen) => !props.excludeSectors?.includes(screen.sectorId));
        }

        if (search.value) {
            filtered = filterBySearch(filtered, search.value, { imageThumbnailUrl: { maxDepth: 0 } });
        }

        if (visibleBounds.value && showOnlyVisible.value) {
            filtered = filterByBoundingBox(filtered, visibleBounds.value);
        }

        return filtered;
    });

    function removeConflictingScreens() {
        if (!selectedButExcluded.value) return;

        selectedScreens.value = selectedScreens.value.filter((id) => {
            const screen = screenIdsMap.value!.get(id);
            return !screen || !props.excludeSectors?.includes(screen.sectorId);
        });
    }
</script>

<template>
    <ConflictingSectorScreensDialog
        v-if="conflictingDialogVisible && selectedButExcluded"
        v-model="conflictingDialogOpen"
        :screens="selectedButExcluded"
    />

    <v-row justify="center" class="h-100 p-relative">
        <AffixedPos
            v-if="selectedButExcluded && selectedButExcluded.length"
            location="top"
            style="z-index: 5000;"
            class="w-100 mt-4"
        >
            <v-alert type="error" color="error-container" elevation="7">
                <v-row align="center">
                    <v-col cols="auto">
                        {{ selectedButExcluded.length }} selected screens are in conflicting industry sectors. Please
                        deselect them to proceed.
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            color="on-error-container"
                            variant="tonal"
                            rounded="md"
                            prepend-icon="mdi-eye"
                            @click="conflictingDialogOpen = true"
                        >
                            View
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            color="on-error-container"
                            variant="tonal"
                            rounded="md"
                            @click="removeConflictingScreens"
                        >
                            Remove
                        </v-btn>
                    </v-col>
                </v-row>
            </v-alert>
        </AffixedPos>
        <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column pr-0 h-100">
            <v-overlay :model-value="hasConflictingScreens" contained scrim="black" persistent />
            <DataCompositeControls
                v-model:search-term="search"
                v-model:active-view="activeView"
                search
                :enabled-views="['table', 'list']"
                class="flex-shrink-0"
            />

            <ScreensSelectCardList
                v-if="activeView === 'list'"
                v-model:selected-ids="selectedScreens"
                :is-loading="isLoading"
                :has-filters="!!search"
                :screens="filteredScreens"
            />
            <ScreensSelectDataTable
                v-else-if="activeView === 'table'"
                v-model:data-footer="dataTableRef"
                v-model:selected-ids="selectedScreens"
                :is-loading="isLoading"
                :has-filters="!!search"
                :screens="filteredScreens"
            />

            <DataCompositeFooter v-if="dataTableRef" :data-table-footer="dataTableRef!" class="flex-shrink-0" />
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
            <v-card class="h-100" rounded="0">
                <ScreensMap
                    v-model:selected-screens="selectedScreens"
                    v-model:show-only-visible="showOnlyVisible"
                    :screens="filteredScreens"
                    @update:visible-bounds="(val) => visibleBounds = val"
                />
            </v-card>
        </v-col>
    </v-row>
</template>
