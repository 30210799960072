<script setup lang="ts">
    import { debounce } from '@/shared/utilities';
    import type { LngLatBoundsLike, LngLatLike } from 'maplibre-gl';
    import { Map } from 'maplibre-gl';
    import { markRaw, nextTick, onBeforeUnmount, onMounted, ref, shallowRef, toRef, watch } from 'vue';

    const defaultStyle = 'https://demotiles.maplibre.org/style.json';

    type Props = {
        center?: LngLatLike | null;
        zoom?: number | null;
        maxBounds?: [[number, number], [number, number]] | LngLatBoundsLike | null;
        mapStyle?: string | null;
    };

    type Emits = {
        'map:load': [value: Map];
        'map:dragend': [value: Map];
        'map:moveend': [value: Map];
        'map:zoomend': [value: Map];
    };

    const emits = defineEmits<Emits>();
    const props = withDefaults(defineProps<Props>(), {
        center: () => [0, 0] as LngLatLike,
        zoom: 2,
        maxBounds: undefined,
        mapStyle: defaultStyle,
    });

    const container = ref<HTMLDivElement>();
    const boundEventHandlers = ref<Record<string, (e: never) => void>>({});

    const initialized = ref(false);
    const map = shallowRef<Map | null>(null);

    let resizeObserver: ResizeObserver | null = null;

    watch(toRef(() => props.mapStyle), (newVal, oldVal) => {
        if (newVal && newVal !== oldVal) {
            map.value?.setStyle(newVal);
        }
    });

    watch(toRef(() => props.center), (newVal, oldVal) => {
        if (newVal && newVal !== oldVal) {
            map.value?.setCenter(newVal);
        }
    });

    watch(toRef(() => props.maxBounds), (newVal, oldVal) => {
        if (newVal && newVal !== oldVal) {
            map.value?.setMaxBounds(newVal);
        }
    });

    function initialize() {
        console.log('initialize', {
            center: props.center,
            zoom: props.zoom,
            maxBounds: props.maxBounds,
            mapStyle: props.mapStyle,
        });

        map.value = markRaw(
            new Map({
                container: 'map-container',
                style: props.mapStyle || defaultStyle,
                center: props.center || undefined,
                zoom: props.zoom || undefined,
                maxBounds: props.maxBounds || undefined,
                attributionControl: false,
            }),
        );
        // automatic re-initialization of map on CONTEXT_LOST_WEBGL
        map.value.getCanvas().addEventListener('webglcontextlost', restart);

        boundEventHandlers.value = {
            load: () => {
                initialized.value = true;
                emits('map:load', map.value!);
            },
            dragend: () => emits('map:dragend', map.value!),
            moveend: () => emits('map:moveend', map.value!),
            zoomend: () => emits('map:zoomend', map.value!),
        };

        const eventNames = Object.keys(boundEventHandlers.value);
        eventNames.forEach((eventName) => {
            // @ts-expect-error TS hates to type object enumeration...
            map.value.on(eventName, boundEventHandlers.value[eventName]);
        });
    }

    function restart() {
        dispose();
        void nextTick(initialize);
    }

    onBeforeUnmount(() => {
        if (resizeObserver) {
            resizeObserver.disconnect();
            resizeObserver = null;
        }

        dispose();
    });

    onMounted(() => {
        initialize();
        resizeObserver = new ResizeObserver(() => map.value?.resize());
        resizeObserver.observe(document.getElementById('map-container')!);
        if (map.value) {
            resizeObserver = new ResizeObserver(debounce(map.value.resize.bind(map.value), 100));
            resizeObserver.observe(container.value as HTMLDivElement);
        }
    });

    function dispose() {
        if (map.value) {
            map.value.getCanvas().removeEventListener('webglcontextlost', restart);
            map.value._controls.forEach((control) => {
                map.value!.removeControl(control);
            });

            // Unsub all events
            const eventNames = Object.keys(boundEventHandlers.value);
            eventNames.forEach((eventName) => {
                // @ts-expect-error TS hates to type object enumeration...
                map.value.off(eventName, boundEventHandlers.value[eventName]);
            });

            map.value.remove();
        }
    }
</script>

<template>
    <div ref="container" class="map-wrapper">
        <v-overlay
            v-if="!initialized"
            :model-value="true"
            scrim="black"
            contained
            class="d-flex justify-center align-center"
        >
            <v-progress-circular color="primary" indeterminate size="64" />
        </v-overlay>
        <div id="map-container" class="map-container" />
    </div>
</template>

<style>
.map-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.map-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
