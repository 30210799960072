<script setup lang="ts">
    import { MapLibreMap } from '@/components/MaplibreMap';
    import type { BoundingBox } from '@/features/gis';
    import { useSynced } from '@/shared/hooks';
    import type { Map } from 'maplibre-gl';
    import { computed, onBeforeUnmount, ref, toRef, watch } from 'vue';

    import { AffixedPos } from '@/components';
    import type { ScreenResponse } from '../api';
    import { useScreensBounds } from '../hooks';
    import { ScreenMapMarker } from '../screenMapMarker';

    type Props = {
        screens: ScreenResponse[] | undefined;
        selectedScreens: number[];
    };

    type Emits = {
        'update:selectedScreens': [selectedScreens: number[]];
        'update:visibleBounds': [bounds: BoundingBox];
    };

    const showOnlyVisible = defineModel<boolean>('showOnlyVisible');
    const props = defineProps<Props>();
    const emits = defineEmits<Emits>();

    const syncedSelectedScreens = useSynced(toRef(() => props.selectedScreens), 'update:selectedScreens', emits);

    const { bounds, center, isLoading } = useScreensBounds();

    const loaded = ref(0);
    const visibleBounds = ref<BoundingBox>([[0, 0], [0, 0]]);

    const markers = ref<ScreenMapMarker[]>([]);

    const screensGeoJson = computed(() => {
        return {
            type: 'FeatureCollection',
            features: props.screens?.map((screen: ScreenResponse) => {
                return {
                    type: 'Feature',
                    properties: {
                        ...screen,
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [screen.position.long, screen.position.lat],
                    },
                };
            }) || [],
        };
    });

    function onLoad(eventMap: Map) {
        loaded.value++;

        for (const feature of screensGeoJson.value.features) {
            const marker = new ScreenMapMarker(
                eventMap,
                feature.geometry.coordinates as [number, number],
                feature as never,
            );

            // Handle selection
            marker.on('selected', ({ id, selected }) => {
                if (selected) {
                    selectScreen(id);
                } else {
                    deSelectScreen(id);
                }
            });

            markers.value.push(marker);
        }

        reCalcBounds(eventMap);
        updateMarkers(syncedSelectedScreens.value);
    }

    const zoom = 3;

    watch(() => props.selectedScreens, (selectedScreens) => {
        updateMarkers(selectedScreens);
    });

    function updateMarkers(selectedScreens: number[]) {
        for (const marker of markers.value) {
            if (selectedScreens.includes(marker.id)) {
                marker.setSelected(true);
            } else {
                marker.setSelected(false);
            }
        }
    }

    function selectScreen(id: number) {
        emits('update:selectedScreens', [...syncedSelectedScreens.value, id]);
    }

    function deSelectScreen(id: number) {
        emits('update:selectedScreens', syncedSelectedScreens.value.filter((s) => s !== id));
    }

    function reCalcBounds(map: Map) {
        if (map) {
            visibleBounds.value = map.getBounds().toArray() as BoundingBox;
            emits('update:visibleBounds', visibleBounds.value);
        }
    }

    function toggleFilterVisible(val: unknown) {
        showOnlyVisible.value = !!val;
    }

    onBeforeUnmount(() => {
        for (const marker of markers.value) {
            marker.dispose();
        }
    });
</script>

<template>
    <div class="p-relative h-100">
        <AffixedPos location="top left" class="mt-2 ml-2">
            <v-btn-toggle
                :model-value="showOnlyVisible ? 0 : undefined"
                color="primary"
                rounded="sm"
                density="compact"
                variant="elevated"
                @update:model-value="toggleFilterVisible"
            >
                <v-btn color="primary" size="small" value="on">
                    Only Visible
                    <template v-slot:prepend>
                        <v-checkbox v-model="showOnlyVisible" hide-details density="compact" color="surface" />
                    </template>
                </v-btn>
            </v-btn-toggle>
        </AffixedPos>
        <MapLibreMap
            v-if="!isLoading"
            ref="map"
            :center="center"
            :zoom="zoom"
            :attribution-control="true"
            :max-bounds="bounds"
            map-style="https://api.maptiler.com/maps/streets/style.json?key=cQX2iET1gmOW38bedbUh"
            language="en"
            @map:load="onLoad"
            @map:dragend="reCalcBounds"
            @map:moveend="reCalcBounds"
            @map:zoomend="reCalcBounds"
        />
    </div>
</template>

<style lang="scss">
.maplibregl-marker {
    cursor: pointer;
}

.maplibregl-popup-content {
    // Remove default styling so it doesn't show
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;

    .maplibregl-popup-close-button {
        display: none;
    }
}

.maplibregl-popup-tip {
    border-top-color: rgb(var(--v-theme-background)) !important;
}
</style>
