<script setup lang="ts">
    import { vMaska } from 'maska';
    import { computed, onMounted, ref, watch } from 'vue';

    type Props = {
        label?: string;
        hint?: string;
        color?: string;
        hideDetails?: boolean;
        loading?: boolean;
        min?: number;
        max?: number;
    };

    const props = withDefaults(defineProps<Props>(), {
        label: '',
        hint: '',
        color: 'primary',
        min: 0,
        max: 0,
    });

    const max = computed(() => {
        if (!props.max) return 1000000;

        return props.max;
    });

    const rules = ref({
        min: [(v: string) => {
            if (!v) return true;
            return Number(v.replace(/[,]/g, '')) >= props.min || `Minimum value is ${props.min}`;
        }],
        max: [(v: string) => Number(v.replace(/[,]/g, '')) <= max.value || `Maximum value is ${max.value}`],
    });

    const modelValue = defineModel<number>({ required: true });

    const masked = ref<string>('');
    const maskOptions = {
        preProcess: (val: string) => {
            return parseInt(val.replace(/[,]/g, ''));
            // return Math.max(Math.min(Number(val.replace(/[,]/g, '')), props.max), props.min);
        },
        postProcess: (val: string) => {
            if (!val) return '';
            return Intl.NumberFormat().format(Number(val));
        },
    };

    watch(max, (newVal, oldVal) => {
        if (newVal !== oldVal && newVal < parseInt(masked.value.replace(/,/g, ''))) {
            masked.value = Intl.NumberFormat().format(newVal);
        }
    });

    watch(modelValue, (newVal, oldVal) => {
        if (newVal !== oldVal && newVal !== parseInt(masked.value.replace(/,/g, ''))) {
            masked.value = Intl.NumberFormat().format(newVal);
        }
    });

    watch(masked, (newVal, oldVal) => {
        if (newVal !== oldVal && newVal) {
            modelValue.value = parseInt(masked.value.replace(/,/g, ''));
        } else {
            modelValue.value = 0;
        }
    });

    onMounted(() => {
        masked.value = Intl.NumberFormat().format(modelValue.value);
    });
</script>

<template>
    <v-text-field
        v-model="masked"
        v-maska:[maskOptions]
        data-maska-reversed
        type="text"
        inputmode="numeric"
        prepend-inner-icon="mdi-currency-usd"
        :label="label"
        variant="outlined"
        :loading="loading"
        :color="color"
        :hint="hint"
        :hide-details="hideDetails"
        :persistent-hint="!!hint"
        :rules="[...rules.min, ...rules.max]"
    >
        <template v-slot:append>
            <slot name="append" />
        </template>
        <template v-slot:append-inner>
            <slot name="append-inner" />
        </template>
    </v-text-field>
</template>
