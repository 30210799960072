import type { ComputedRef, MaybeRef } from 'vue';
import { computed, toValue } from 'vue';
import type { InvoiceStatus } from '../api';
import type { InvoiceUiConfigItem } from '../uiConfig';
import { INVOICE_STATUS_UI_CONFIGS } from '../uiConfig';

type ReturnType<TStatus extends Nullish<InvoiceStatus>> = TStatus extends InvoiceStatus
    ? ComputedRef<InvoiceUiConfigItem>
    : ComputedRef<Nullish<InvoiceUiConfigItem>>;

export function useInvoiceStatusUiConfig<TStatus extends Nullish<InvoiceStatus>>(
    invoiceStatus: MaybeRef<TStatus>,
): ReturnType<TStatus> {
    const config = computed(() => {
        if (!toValue(invoiceStatus)) {
            return null;
        }

        return INVOICE_STATUS_UI_CONFIGS[toValue(invoiceStatus)!] as InvoiceUiConfigItem;
    });

    return config as ReturnType<TStatus>;
}
