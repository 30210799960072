import type { Ref, UnwrapNestedRefs } from 'vue';
import { computed } from 'vue';
import type { DataPaginationFooterData } from './types';
import { isEasyDataTableFooterData } from './types';

export function useDataPagination(
    data: Ref<UnwrapNestedRefs<EasyDataTableFooter> | DataPaginationFooterData | undefined>,
) {
    const currentPageStartIndex = computed(() => {
        if (!data.value) return 0;

        if (isEasyDataTableFooterData(data.value)) {
            return data.value.currentPageFirstIndex;
        }
        return data.value.currentPageStartIndex;
    });

    const currentPageEndIndex = computed(() => {
        if (!data.value) return 0;

        if (isEasyDataTableFooterData(data.value)) {
            return data.value.currentPageLastIndex;
        }
        return data.value.currentPageEndIndex;
    });

    const itemsLength = computed(() => {
        if (!data.value) return 0;

        if (isEasyDataTableFooterData(data.value)) {
            return data.value.clientItemsLength;
        }
        return data.value.itemsLength;
    });

    const itemsPerPage = computed(() => {
        if (!data.value) return 0;

        if (isEasyDataTableFooterData(data.value)) {
            return data.value.rowsPerPageActiveOption;
        }
        return data.value.itemsPerPage;
    });

    const itemsPerPageOptions = computed(() => {
        if (!data.value) return [];

        if (isEasyDataTableFooterData(data.value)) {
            return data.value.rowsPerPageOptions;
        }
        return data.value.itemsPerPageOptions;
    });

    const currentPage = computed(() => {
        if (!data.value) return 0;

        if (isEasyDataTableFooterData(data.value)) {
            return data.value.currentPaginationNumber;
        }
        return data.value.currentPage;
    });

    const paginationData = computed<DataPaginationFooterData>(() => {
        return {
            currentPageStartIndex: currentPageStartIndex.value,
            currentPageEndIndex: currentPageEndIndex.value,
            itemsLength: itemsLength.value,
            itemsPerPage: itemsPerPage.value,
            itemsPerPageOptions: itemsPerPageOptions.value,
            currentPage: currentPage.value,
        };
    });

    return {
        currentPageStartIndex,
        currentPageEndIndex,
        itemsLength,
        itemsPerPage,
        itemsPerPageOptions,
        currentPage,
        paginationData,
    };
}
