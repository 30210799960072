import { useApi } from '@/features/api/hooks';
import type { Ref } from 'vue';
import { computed, toValue } from 'vue';
import type { ScreenResponse } from '../api';

const GC_TIME = 5 * 60 * 1000; // 5mins
const STALE_TIME = 5 * 60 * 1000; // 5mins

/** Retrieves all available screens from teh API
 * @param screenIds - Optional list of screen IDs to filter the results by
 */
export function useScreens(screenIds?: Ref<Nullish<number[]>> | undefined) {
    const { isLoading, data, error } = useApi('screens/GET_SCREENS', (endpoint, fetcher) => {
        return fetcher(endpoint);
    }, { staleTime: STALE_TIME, cacheTime: GC_TIME });

    const screenIdsMap = computed(() => {
        if (!data.value) return null;

        const map = new Map<number, ScreenResponse>();

        data.value.forEach(screen => {
            map.set(screen.id, screen);
        });

        return map;
    });

    const screens = computed(() => {
        if (!data.value) return undefined;
        if (!toValue(screenIds)) return data.value;

        return data.value.filter(screen => toValue(screenIds)!.includes(screen.id));
    });

    return {
        isLoading,
        error,
        screens,
        screenIdsMap,
    };
}
