<script setup lang="ts">
    import RenderResultErrors from '@/components/RenderResultErrors.vue';
    import { useCommand } from '@/features/api';
    import { useDialogWidth } from '@/shared/hooks';

    import { ref } from 'vue';
    import CampaignForm from './CampaignForm.vue';
    import type { CampaignFormData } from './types';

    const modelValue = defineModel<boolean>();
    const dialogWidth = useDialogWidth({ lg: 0.75, xl: 0.75 });

    const { isLoading, mutateAsync, error } = useCommand('campaigns/CREATE_CAMPAIGN', {
        invalidates: ['campaigns/'],
        onSettled(data, error) {
            if (!error) {
                modelValue.value = false;
            }
        },
    });

    const formRef = ref<VuetifyForm>();
    const formData = ref<CampaignFormData>({
        companyId: null,
        name: '',
        description: '',
        duration: {
            startDate: null,
            endDate: null,
        },
        budget: {
            budget: 0,
            kind: 'Monthly',
        },
    });

    async function save() {
        if (!(await formRef.value?.validate())?.valid) return;

        const { startDate, endDate } = formData.value.duration;

        if (!startDate) return;

        await mutateAsync({
            request: {
                name: formData.value.name,
                description: formData.value.description,
                startDate: typeof startDate === 'string' ? startDate : startDate.toISOString(),
                endDate: typeof endDate === 'string' ? endDate : endDate?.toISOString(),
                budget: formData.value.budget.budget,
                budgetKind: formData.value.budget.kind,
                order: undefined,
            },
            params: {
                companyId: formData.value.companyId!,
            },
        });
    }
</script>

<template>
    <v-dialog v-model="modelValue" :width="dialogWidth">
        <v-card>
            <v-card-title class="text-center pt-2 bg-surface-container">
                <span class="text-h2">New Campaign</span>
            </v-card-title>
            <v-divider thickness="2px" />
            <v-card-text style="min-height: 50vh" class="mt-4">
                <CampaignForm v-model="formData" v-model:form-ref="formRef" />
            </v-card-text>
            <v-card-actions>
                <v-btn color="grey" size="large" @click="() => (modelValue = false)">
                    Cancel
                </v-btn>
                <v-spacer />
                <v-btn color="primary" size="large" :loading="isLoading" @click="save">
                    Save
                </v-btn>
            </v-card-actions>
            <RenderResultErrors :result="error" />
        </v-card>
    </v-dialog>
</template>

<style scoped lang="scss">
.fit-content {
    width: fit-content;
}

:deep(.v-data-table__tr:has(input[type="checkbox"]:checked)) {
    background-color: rgb(var(--v-theme-surface-container-low));
}
</style>
