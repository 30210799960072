import type { Ref } from 'vue';
import { computed, toValue } from 'vue';

/** Utility to handle 2-way binding boilerplate for a prop */
export function useSynced<TVal, TEvent extends string, TEmitter extends (evt: TEvent, value: TVal) => void>(
    value: Ref<TVal> | TVal,
    event: TEvent,
    emit: TEmitter,
) {
    const synced = computed<TVal>({
        get() {
            return toValue(value);
        },
        set(newVal) {
            emit(event, newVal);
        },
    });

    return synced;
}
