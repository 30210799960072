import { useApi } from '@/features/api/hooks';
import type { Ref } from 'vue';
import { computed, toValue } from 'vue';

const STALE_TIME = 1000 * 60; // 1 minute

export function useCreative(
    creativeId: string | undefined | Ref<string | undefined>,
    enabled: Ref<boolean> | undefined = undefined,
) {
    const isEnabled = computed(() => {
        if (!toValue(creativeId)) return false;
        if (enabled === undefined) return true;

        return toValue(enabled);
    });

    const { isLoading, data, error } = useApi(['creatives/GET_CREATIVE', creativeId], (endpoint, fetcher) => {
        return fetcher(endpoint, { creativeId: toValue(creativeId)! });
    }, { keepPreviousData: true, staleTime: STALE_TIME, enabled: isEnabled });

    return {
        isLoading,
        error,
        creative: data,
    };
}
