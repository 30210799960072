import { useApi } from '@/features/api/hooks';
import type { Ref } from 'vue';
import { computed, toValue } from 'vue';

const STALE_TIME = 15 * 60 * 1000; // 15 minutes
const GC_TIME = 15 * 60 * 1000; // 15 minutes

export function useDiscountProgression(months: Ref<number | null | undefined>) {
    const enabled = computed(() => !!toValue(months));
    const monthsFloor = computed(() => Math.floor(toValue(months)!));

    const { isLoading, data } = useApi(['pricing/GET_DISCOUNT_PROGRESSION', monthsFloor], (endpoint, fetcher) => {
        return fetcher(endpoint, { months: toValue(monthsFloor)! });
    }, { staleTime: STALE_TIME, cacheTime: GC_TIME, enabled: enabled });

    return {
        isLoading,
        discountProgression: data,
    };
}
