export function formatCurrency(value: Nullish<number | string>) {
    if (value === undefined || value === null) {
        return value;
    }
    let number = value;
    if (typeof number === 'string') {
        number = Number.parseFloat(number);
        if (isNaN(number)) {
            return value;
        }
    }

    let formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumSignificantDigits: 4,
    }).format(number);

    // Remove cents if they are .00
    if (number > 0 && formatted.endsWith('.00')) {
        formatted = formatted.slice(0, -3);
    }

    return formatted;
}
