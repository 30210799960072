import type { Ref } from 'vue';
import { ref, toValue, watchEffect } from 'vue';

export function useDelayedToggle(delay: number | Ref<number> = 300): [Ref<boolean>, Ref<boolean>] {
    const isOpen = ref(false);
    const isVisible = ref(false);

    watchEffect(() => {
        if (!isOpen.value && !isVisible.value) return;

        if (isOpen.value) {
            isVisible.value = true;
        } else {
            setTimeout(() => {
                isVisible.value = false;
            }, toValue(delay));
        }
    });

    return [isOpen, isVisible];
}
