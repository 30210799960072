<script setup lang="ts">
    import { Text } from '@/components';
    import { computed } from 'vue';
    import type { SimpleOrderRunResponse } from '../api/orderRunsApiResponseModels';
    import { ORDER_RUN_PROGRESS_COLORS } from '../config';

    type Props = {
        orderRun: SimpleOrderRunResponse;
        showText?: boolean;
        contentClass?: string;
        backgroundColor?: string;
    };
    const props = defineProps<Props>();

    const expectedProgress = computed(() => {
        return Math.round(props.orderRun.timeProgress * 1000) / 10;
    });

    const actualProgress = computed(() => {
        return Math.round(props.orderRun.playsProgress * 1000) / 10;
    });

    const progressColor = computed(() => {
        if (expectedProgress.value - actualProgress.value <= 5) return ORDER_RUN_PROGRESS_COLORS.OnSchedule;
        if (actualProgress.value >= expectedProgress.value) return ORDER_RUN_PROGRESS_COLORS.OnSchedule;
        return ORDER_RUN_PROGRESS_COLORS.OffSchedule;
    });

    const bufferColor = computed(() => {
        if (props.orderRun.status == 'Completed') return 'green-darken-2';
        return 'grey-darken-1';
    });

    const height = computed(() => {
        return props.showText ? '15' : '8';
    });
</script>

<template>
    <v-tooltip location="top" content-class="bg-surface-container-lowest pa-0">
        <template v-slot:activator="{ props: activatorProps }">
            <v-progress-linear
                :class="contentClass"
                :height="height"
                rounded="sm"
                bg-opacity="0.8"
                :color="progressColor"
                :bg-color="backgroundColor || 'surface-container-lowest'"
                buffer-opacity="1"
                :buffer-color="bufferColor"
                :model-value="actualProgress"
                :buffer-value="expectedProgress"
                v-bind="activatorProps"
            >
                <template v-if="showText" v-slot>
                    <strong class="text-body-1">{{ actualProgress }}%</strong>
                </template>
            </v-progress-linear>
        </template>
        <v-card rounded="0">
            <v-card-title class="text-center py-1">Flight Progress</v-card-title>
            <v-card-subtitle class="text-center">
                <div>
                    {{ $filters.number(orderRun.plays) }} / {{ $filters.number(orderRun.targetPlays) }}
                </div>
                <div>
                    Impression
                </div>
            </v-card-subtitle>
            <v-divider thickness="2" />
            <v-card-text>
                <div>Flight: <Text color="primary">{{ expectedProgress }}%</Text></div>
                <div>Impressions: <Text :color="progressColor">{{ actualProgress }}%</Text></div>
            </v-card-text>
        </v-card>
    </v-tooltip>
</template>
