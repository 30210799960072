import type { MaybeRef } from 'vue';
import { computed, toValue } from 'vue';
import type { DisplayBreakpoint } from 'vuetify';
import { useDisplay } from 'vuetify/lib/framework.mjs';

const dialogWidths: Record<DisplayBreakpoint, number> = {
    xs: 100,
    sm: 100,
    md: 90,
    lg: 80,
    xl: 70,
    xxl: 70,
};

export function useDialogWidth(factor?: MaybeRef<Partial<Record<DisplayBreakpoint, number>> | number | undefined>) {
    const { name: currentBreakpoint } = useDisplay();

    const width = computed(() => {
        const localFactor = toValue(factor);
        if (!localFactor && localFactor !== 0) return dialogWidths[currentBreakpoint.value];

        if (typeof localFactor === 'number') {
            return dialogWidths[currentBreakpoint.value] * localFactor;
        }

        if (localFactor[currentBreakpoint.value]) {
            return dialogWidths[currentBreakpoint.value] * localFactor[currentBreakpoint.value]!;
        }

        return dialogWidths[currentBreakpoint.value];
    });

    const dialogWidth = computed(() => {
        return `${width.value}vw`;
    });

    return dialogWidth;
}
