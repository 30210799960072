import { useAuthStore } from '@/features/auth';
import type { AppRoles } from '@/features/auth/api/authApiResponseModels';
import type { LiteralUnion } from 'type-fest';
import type { App } from 'vue';

export const capabilityPlugin = {
    install(app: App) {
        app.config.globalProperties.$can = (capability: string | undefined) => {
            // Perf: This appears to take ~0.5µs to run once cached, not a performance concern. `useAuthStore()` is half of that time.
            // Without perms caching can() time increases ~5x
            return useAuthStore().userAbility.can(capability as never);
        };
        app.config.globalProperties.$canExclusive = (capability: string) => {
            // Perf: This appears to take ~0.5µs to run once cached, not a performance concern. `useAuthStore()` is half of that time.
            // Without perms caching can() time increases ~5x
            return useAuthStore().userAbility.canExclusive(capability as never);
        };
        app.config.globalProperties.$hasAtLeastRole = (role: LiteralUnion<AppRoles, string>) => {
            // Perf: This appears to take ~0.5µs to run once cached, not a performance concern. `useAuthStore()` is half of that time.
            // Without perms caching can() time increases ~5x
            return useAuthStore().userAbility.hasAtLeastRole(role);
        };
    },
};
