<script setup lang="ts">
    import IndustrySectorIcon from '@/features/industries/components/IndustrySectorIcon.vue';
    import { computed, ref, watch } from 'vue';

    import type { ScreenResponse } from '../../api';

    type Props = {
        isLoading: boolean;
        hasFilters: boolean;
        screens: undefined | ScreenResponse[];
    };

    type Emits = {
        'clear-filters': [];
    };

    defineEmits<Emits>();
    const props = defineProps<Props>();

    const selectedIds = defineModel<number[]>('selectedIds', { default: () => [] });

    const selected = ref<Set<number>>(new Set());
    const sortedScreens = computed(() => {
        if (!props.screens) {
            return [];
        }

        const screens = [...props.screens];
        const selectedScreens = screens.filter(screen => selected.value.has(screen.id));
        const unselectedScreens = screens.filter(screen => !selected.value.has(screen.id));

        return [...selectedScreens, ...unselectedScreens];
    });

    function clickScreen(item: ScreenResponse) {
        if (selected.value.has(item.id)) {
            selected.value.delete(item.id);
        } else {
            selected.value.add(item.id);
        }

        selectedIds.value = Array.from(selected.value);
    }

    watch(selectedIds, (newVal, oldVal) => {
        if (newVal !== oldVal) {
            selected.value = new Set(newVal);
        }
    }, { immediate: true });
</script>

<template>
    <perfect-scrollbar class="h-100">
        <v-data-iterator
            v-if="screens"
            :items="sortedScreens"
            :items-per-page="0"
            class="px-4 my-2 cards-data-iterator d-flex flex-column gap-md"
        >
            <template v-slot="{ items }">
                <div class="d-flex flex-column gap-md">
                    <v-slide-y-transition group class="gap-md">
                        <template v-for="item in items" :key="item.raw.id">
                            <v-card
                                variant="flat"
                                class="screen-card no-select"
                                rounded="md"
                                :class="selected.has(item.raw.id) ? 'selected-screen' : ''"
                                :color="selected.has(item.raw.id) ? 'secondary-container' : 'surface-container-high'"
                                @click="clickScreen(item.raw)"
                            >
                                <v-card-text class="v-row py-2 flex-nowrap">
                                    <v-col cols="2" class="d-flex justify-center align-center">
                                        <IndustrySectorIcon :industries="item.raw.metadata.industries" size="h2" />
                                        <!-- <v-icon size="40" color="primary" icon="mdi-glass-mug-variant" /> -->
                                    </v-col>
                                    <v-col
                                        cols="auto"
                                        class="d-flex flex-column justify-space-between flex-grow-1 flex-shrink-1"
                                    >
                                        <div class="text-h4 text-primary">
                                            {{ item.raw.title }}
                                        </div>
                                        <div class="text-on-tertiary-container text-body-2">
                                            {{ item.raw.metadata.industries[0].sectorName }}
                                        </div>
                                    </v-col>
                                    <v-tooltip
                                        location="right"
                                        content-class="bg-surface-container-highest"
                                        :open-delay="250"
                                    >
                                        <template v-slot:activator="{ props: activatorProps }">
                                            <v-col
                                                cols="auto"
                                                class="d-flex flex-column justify-space-between flex-shrink-0"
                                                v-bind="activatorProps"
                                            >
                                                <div class="text-h4 text-primary">
                                                    {{ $filters.number(item.raw.avgDailyViews) }} /day
                                                </div>
                                                <div class="text-on-tertiary-container text-body-2 text-truncate">
                                                    Impressions
                                                </div>
                                            </v-col>
                                        </template>
                                        <span>Daily Impressions</span>
                                    </v-tooltip>
                                </v-card-text>
                            </v-card>
                            <br>
                        </template>
                    </v-slide-y-transition>
                </div>
            </template>
        </v-data-iterator>
    </perfect-scrollbar>
</template>

<style lang="scss" scoped>
:deep(.cards-data-iterator) {
    br {
        display: none;
    }
}

.screen-card {
    transition: transform 0.2s ease-in-out;
}
.selected-screen {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    border: 2px solid rgb(var(--v-theme-secondary)) !important;
}
</style>
