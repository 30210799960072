/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import { router } from '../router';
import pinia from '../store';
import { capabilityPlugin } from './capabilityPlugin';
import { filtersPlugin } from './filters';
import { globalDialogsPlugin } from './globalDialogsPlugin';
import { sentryPlugin } from './sentryPlugin';
import { toastsPlugin } from './toastsPlugin';
import vuetify from './vuetify';

// Types
import type { App } from 'vue';

export function registerPlugins(app: App) {
    app
        .use(router)
        .use(sentryPlugin)
        .use(vuetify)
        .use(pinia)
        .use(filtersPlugin)
        .use(toastsPlugin)
        .use(globalDialogsPlugin)
        .use(capabilityPlugin);
}
