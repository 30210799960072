<script setup lang="ts">
    import RenderResultErrors from '@/components/RenderResultErrors.vue';
    import { useScreen } from '../hooks';

    type Props = {
        screenId: number;
        canRemove?: boolean;
    };

    type Emits = {
        remove: [void];
    };

    const props = defineProps<Props>();
    const emits = defineEmits<Emits>();

    const { isLoading, screen, errors } = useScreen(props.screenId);
</script>

<template>
    <v-skeleton-loader v-if="isLoading" :loading="isLoading" type="card" class="w-100 mx-auto" />
    <v-card
        v-else-if="screen"
        class="small-screen-card"
        color="surface-container-lowest"
        rounded="0"
        density="comfortable"
    >
        <v-card-title class="text-h5 on-surface-variant d-flex align-center" :title="screen.title">
            <span class="text-truncate">{{ screen.title }}</span>
            <v-spacer />
            <v-btn
                v-if="canRemove"
                color="red"
                icon
                variant="text"
                elevation="0"
                density="compact"
                title="Remove"
                @click="emits('remove')"
            >
                <v-icon icon="mdi-close" color="red" />
            </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="text-body-2 on-surface">
            {{ screen.excerpt }}
        </v-card-text>
    </v-card>
    <RenderResultErrors :errors="errors" />
</template>
