import type { FileStatus } from './api';

export const FILE_STATUS_UI_CONFIG: Record<FileStatus, { color: string; text: string; }> = {
    None: { color: 'grey', text: 'No Data' },
    NoData: { color: 'grey', text: 'No Data' },
    Uploading: { color: 'blue', text: 'Uploading' },
    Uploaded: { color: 'yellow', text: 'Uploaded' },
    WaitingForWorker: { color: 'yellow', text: 'Waiting For Worker' },
    WorkerPickedUp: { color: 'yellow', text: 'Worker Picked Up' },
    ScanningQueued: { color: 'yellow', text: 'Scanning Queued' },
    ScanningCompleted: { color: 'blue', text: 'Scanning Completed' },
    ProcessingQueued: { color: 'yellow', text: 'Processing Queued' },
    Processing: { color: 'yellow', text: 'Processing' },
    ProcessingCompleted: { color: 'green', text: 'Processing Completed' },
    Ok: { color: 'green', text: 'Ok' },
    Error: { color: 'red', text: 'Error' },
};
