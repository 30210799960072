<script setup lang="ts">
    import { computed } from 'vue';

    type Props = {
        currentPageStartIndex: number;
        currentPageEndIndex: number;
        itemsLength: number;

        itemsPerPage: number;
        itemsPerPageOptions: number[];
        currentPage: number;
    };

    type Emits = {
        'update:currentPage': [number];
        'update:itemsPerPage': [number];
    };

    defineEmits<Emits>();
    const props = defineProps<Props>();

    const totalPages = computed(() => Math.ceil(props.itemsLength / props.itemsPerPage));
</script>

<template>
    <v-card color="surface-container-high" rounded="0" variant="flat">
        <v-card-text class="py-2 on-surface-container">
            <v-row align="center">
                <v-spacer />
                <v-col cols="auto" class="text-body-1">
                    {{ currentPageStartIndex }} - {{ currentPageEndIndex }} of {{ itemsLength }}
                </v-col>
                <v-divider vertical thickness="2" class="my-2 ml-1" />
                <v-col cols="auto">
                    <v-pagination
                        :model-value="currentPage"
                        :length="totalPages"
                        :total-visible="3"
                        density="compact"
                        @update:model-value="$emit('update:currentPage', $event)"
                    />
                </v-col>
                <v-divider vertical thickness="2" class="my-2 mr-1" />
                <v-col cols="auto" class="text-body-1">
                    <v-select
                        variant="outlined"
                        class="bg-shaded-1"
                        color="secondary"
                        density="compact"
                        hide-details
                        :items="itemsPerPageOptions"
                        :model-value="itemsPerPage"
                        @update:model-value="$emit('update:itemsPerPage', $event)"
                    >
                        <template v-slot:append>
                            /Page
                        </template>
                    </v-select>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
