<script setup lang="ts">
    import { useDialogWidth } from '@/shared/hooks';
    import { ref } from 'vue';
    import ProfileSettings from './ProfileSettings.vue';

    type View = 'profile' | 'notifications';

    const modelValue = defineModel<boolean>();
    const dialogWidth = useDialogWidth({ xl: 0.8 });

    const window = ref<View>('profile');

    function close() {
        modelValue.value = false;
    }
</script>
<template>
    <v-dialog v-model="modelValue" :width="dialogWidth" max-width="850px" min-height="75vh" scrollable>
        <v-card class="bg-surface-container" rounded="md">
            <v-card-title class="">
                Settings
            </v-card-title>
            <v-card-subtitle class="mb-1">
                Manage your account settings and e-mail preferences
            </v-card-subtitle>
            <v-divider thickness="2" />
            <v-card-text class="bg-surface d-flex">
                <v-row class="flex-nowrap flex-grow-1">
                    <v-col cols="auto" class="d-flex flex-column">
                        <v-list
                            density="comfortable"
                            class="gap-xs d-flex flex-column"
                            color="blue"
                            style="min-width: 200px;"
                        >
                            <v-list-item
                                :active="window === 'profile'"
                                rounded="md"
                                class=""
                                color="on-surface-container"
                                title="Profile"
                                prepend-icon="mdi-account"
                                @click="window = 'profile'"
                            />
                            <v-list-item
                                :active="window === 'notifications'"
                                rounded="md"
                                class=""
                                color="on-surface-container"
                                title="Notifications"
                                prepend-icon="mdi-bell"
                                @click="window = 'notifications'"
                            />
                        </v-list>
                    </v-col>
                    <v-col cols="auto">
                        <v-divider vertical thickness="3" />
                    </v-col>
                    <!-- Settings Content -->
                    <v-col cols="auto" class="flex-grow-1 flex-shrink-1">
                        <v-window v-model="window" direction="vertical">
                            <v-window-item value="profile">
                                <ProfileSettings />
                            </v-window-item>

                            <v-window-item value="notifications">
                                <v-card class="d-flex justify-center align-center">
                                    <span class="text-h2">No Settings here yet</span>
                                </v-card>
                            </v-window-item>
                        </v-window>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="grey" size="large" @click="close">
                    Close
                </v-btn>
            </v-card-actions>
            <!-- <RenderResultErrors :result="error" /> -->
        </v-card>
    </v-dialog>
</template>
