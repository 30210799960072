<script setup lang="ts">
    import { AdDurationSelection } from '@/features/campaigns/components/inputs';
    import type { FileMediaKind } from '@/features/files';
    import type { FileDropzoneModel } from '@/features/files/components/types';
    import { useDialogWidth } from '@/shared/hooks';
    import { computed, onErrorCaptured, onMounted, ref, watch } from 'vue';

    import type { NewCreativeResponse } from '../../api';
    import MediaKindChip from '../MediaKindChip.vue';
    import CreateCreative_01InitialCreate from './CreateCreative_01InitialCreate.vue';
    import CreateCreative_ProcessFile from './CreateCreative_ProcessFile.vue';
    import CreateCreative_Start from './CreateCreative_Start.vue';
    import CreateCreative_UploadFile from './CreateCreative_UploadFile.vue';

    type Props = {
        companyId?: number;
    };

    type Emits = {
        'creative-created': [value: string]; // CreativeId
        cancelled: [];
    };

    const props = defineProps<Props>();
    const emits = defineEmits<Emits>();

    // Used internally if there is no companyId prop
    const formCompanyId = ref<number | undefined>(undefined);
    const resolvedCompanyId = computed(() => props.companyId || formCompanyId.value);

    const fileModel = defineModel<FileDropzoneModel | null>('fileModel');
    const isInFileSelectMode = computed(() => !fileModel.value || !resolvedCompanyId.value);
    const dialogWidthFactor = computed(() => isInFileSelectMode.value ? 0.6 : undefined);

    const file = computed(() => fileModel.value?.file);
    const fileObjectUrl = computed(() => fileModel.value?.fileObjectUrl);

    const mediaKind = computed<FileMediaKind>(() => {
        if (!file.value) return 'Image';

        return file.value.type.includes('video') ? 'Video' : 'Image';
    });

    const modelValue = defineModel<boolean>();

    const dialogWidth = useDialogWidth(dialogWidthFactor);
    const mediaDuration = ref<number | null>(null);
    const matchedDuration = ref(false);
    const formRef = ref<VuetifyForm>();

    const form = ref({
        name: '',
        duration: 0,
        notes: '',
        s3FileId: null as string | null,
    });

    const rules = ref({
        name: [
            (v: string) => !!v || 'Creative name is required',
        ],
    });

    const startUpload = ref(false);
    const isUploading = ref<boolean | null>(null);
    const uploadingFinished = ref(false);

    const startInitCreative = ref(false);
    const initCreativeFinished = ref(false);

    const startFileProcessing = ref(false);
    const fileProcessingFinished = ref(false);

    const initializedCreative = ref<NewCreativeResponse | null>(null);

    const canCancelProcess = computed(() => {
        if (fileProcessingFinished.value) return false;
        if (startFileProcessing.value) return false;
        if (startUpload.value && isUploading.value === null) return true;
        if (startUpload.value && isUploading.value) return false;
        if (startUpload.value && !isUploading.value) return true;

        return true;
    });

    // We start the creative creation process when we click the "upload" button
    async function clickUpload() {
        if (!(await formRef.value?.validate())?.valid) return;

        startInitCreative.value = true;
    }

    function clickCancel() {
        // TODO: Notify API that we are cancelling in case a file needs to be deleted
        emits('cancelled');
        modelValue.value = false;
    }

    function creativeInitialized(creative: NewCreativeResponse) {
        initializedCreative.value = creative;
        initCreativeFinished.value = true;
        if (!startUpload.value) startUpload.value = true;
    }

    function creationComplete() {
        if (!initializedCreative.value) throw new Error('No initialized creative found');

        fileProcessingFinished.value = true;

        emits('creative-created', initializedCreative.value.id);
        modelValue.value = false;
    }

    function uploadingComplete() {
        uploadingFinished.value = true;
        startFileProcessing.value = true;
    }

    function detectVideoDuration() {
        if (!file.value || !fileObjectUrl.value) return;
        if (!file.value.type.includes('video')) return;

        var video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function() {
            mediaDuration.value = video.duration;

            switch (Math.round(video.duration)) {
                case 10:
                    form.value.duration = 10;
                    break;
                case 15:
                    form.value.duration = 15;
                    break;
                case 30:
                    form.value.duration = 30;
                    break;
                default:
                    form.value.duration = 0;
            }

            // Disable selection if we matched
            if (form.value.duration != 0) {
                matchedDuration.value = true;
            }

            video.remove();
        };

        video.src = fileObjectUrl.value;
    }

    watch(fileModel, (newVal, oldVal) => {
        if (newVal && !oldVal) {
            detectVideoDuration();
        }
    });

    onMounted(() => {
        console.log('CreateCreativeDialog mounted');
        detectVideoDuration();
    });

    onErrorCaptured((error) => {
        console.error('Error CreateCreativeDialog', error);
    });
</script>

<template>
    <v-dialog v-model="modelValue" :width="dialogWidth" persistent>
        <v-card class="p-relative">
            <v-card-text>
                <v-form v-if="fileModel && file && resolvedCompanyId" ref="formRef" lazy-validation class="">
                    <v-alert v-if="!resolvedCompanyId" dense type="error" variant="outlined">
                        <span>Error: No Company ID provided during render</span>
                    </v-alert>
                    <v-row v-else justify="center">
                        <v-col cols="12" lg="6" class="p-relative">
                            <v-card color="transparent">
                                <div class="absolute-top-left">
                                    <MediaKindChip :value="mediaKind" shade="dark" variant="elevated" />
                                    <v-chip class="ma-2 font-weight-bold" color="primary" variant="elevated">
                                        {{ $filters.bytes(file.size!) }}
                                    </v-chip>
                                </div>

                                <v-img v-if="mediaKind === 'Image'" :src="fileObjectUrl" />
                                <video v-else-if="mediaKind === 'Video'" controls class="w-100">
                                    <source :src="fileObjectUrl" :type="file.type">
                                </video>
                            </v-card>
                            <v-overlay
                                v-if="startInitCreative"
                                contained
                                persistent
                                model-value
                                :opacity="0.7"
                                scrim="black"
                                class="uploading-overlay"
                                content-class="w-100 h-100 d-flex align-center"
                            >
                                <v-list class="bg-transparent" color="transparent">
                                    <CreateCreative_01InitialCreate
                                        :file="file"
                                        :company-id="resolvedCompanyId"
                                        :creative-info="form"
                                        :start="startInitCreative && !initCreativeFinished"
                                        @creative-initialized="creativeInitialized"
                                    />
                                    <v-divider
                                        vertical
                                        class="flex-grow-1"
                                        thickness="4px"
                                        style="opacity: 0.5; padding-left:34px; height: 2rem;"
                                    />
                                    <CreateCreative_UploadFile
                                        :file="file"
                                        :initial-creative="initializedCreative"
                                        :company-id="resolvedCompanyId"
                                        :start="startUpload && !uploadingFinished"
                                        @update:file-id="form.s3FileId = $event"
                                        @upload-complete="uploadingComplete"
                                        @is-uploading="isUploading = $event"
                                    />
                                    <v-divider
                                        vertical
                                        class="flex-grow-1"
                                        thickness="4px"
                                        style="opacity: 0.5; padding-left:34px; height: 2rem;"
                                    />
                                    <CreateCreative_ProcessFile
                                        :creative-id="initializedCreative?.id"
                                        :s3-file-id="form.s3FileId"
                                        :start="startFileProcessing && !fileProcessingFinished"
                                        @processing-complete="creationComplete"
                                    />
                                </v-list>
                            </v-overlay>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="form.name"
                                        label="Name"
                                        variant="outlined"
                                        color="secondary"
                                        :rules="rules.name"
                                    />
                                </v-col>

                                <v-col cols="12">
                                    <v-textarea
                                        v-model="form.notes"
                                        label="Notes"
                                        rows="4"
                                        variant="outlined"
                                        color="secondary"
                                    />
                                </v-col>

                                <v-col v-if="matchedDuration || !mediaDuration" cols="12" class="p-relative">
                                    <div class="text-h2 text-center font-weight-regular mb-2">
                                        Ad Duration
                                    </div>
                                    <AdDurationSelection
                                        v-model="form.duration"
                                        size="large"
                                        :disabled="matchedDuration"
                                    />
                                </v-col>

                                <v-col v-else cols="12" class="p-relative">
                                    <v-alert dense type="warning" variant="outlined">
                                        <span>Invalid duration. Video duration must be 10, 15, or 30 seconds.</span>
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
                <CreateCreative_Start v-else v-model:file-model="fileModel" v-model:company-id="formCompanyId" />
            </v-card-text>
            <v-card-actions class="my-2">
                <v-row no-gutters align="center">
                    <v-col cols="4" />
                    <v-col cols="4">
                        <v-btn
                            v-if="!startInitCreative"
                            :loading="startUpload && !fileProcessingFinished"
                            :disabled="!form.duration"
                            color="primary"
                            prepend-icon="mdi-cloud-upload"
                            size="large"
                            variant="elevated"
                            block
                            @click="clickUpload"
                        >
                            Upload
                        </v-btn>

                        <v-btn
                            v-else
                            size="large"
                            color="primary"
                            variant="elevated"
                            block
                            :disabled="canCancelProcess || !fileProcessingFinished"
                            @click="modelValue = false"
                        >
                            Close
                        </v-btn>
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto" class="d-flex">
                        <v-btn
                            v-if="!fileProcessingFinished"
                            size="large"
                            color="grey"
                            :disabled="!canCancelProcess"
                            @click="clickCancel"
                        >
                            Cancel
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped lang="scss">
:deep(.uploading-overlay .v-overlay__scrim) {
    opacity: 0.9;
}

.absolute-top-left {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
</style>
