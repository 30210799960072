import { useApi } from '@/features/api';
import type { Ref } from 'vue';
import { computed, toValue, watchEffect } from 'vue';

const GC_TIME = 2 * 60 * 1000; // 2mins
const STALE_TIME = 1 * 60 * 1000; // 1min

export function useBudget(
    companyId: Ref<number | null | undefined>,
    campaignId: Ref<string | null | undefined> | null = null,
) {
    const isEnabled = computed(() => {
        if (campaignId === null) return !!toValue(companyId);
        return !!toValue(companyId) && !!toValue(campaignId);
    });

    const { isLoading, data, error } = useApi(
        toValue(campaignId)
            ? ['budget/GET_COMPANY_CAMPAIGN_BUDGET', companyId, campaignId]
            : ['budget/GET_COMPANY_BUDGET', companyId],
        (endpoint, fetcher) => {
            return fetcher(endpoint, { companyId: toValue(companyId)!, campaignId: toValue(campaignId)! });
        },
        { staleTime: STALE_TIME, cacheTime: GC_TIME, enabled: isEnabled },
    );

    watchEffect(() => {
        console.log(
            'useBudget',
            companyId.value,
            campaignId?.value,
            isEnabled.value,
            isLoading.value,
            data.value,
            error.value,
        );
    });

    return {
        isLoading,
        error,
        companyBudget: data,
    };
}
