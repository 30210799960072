<script setup lang="ts">
    import { useDialogWidth } from '@/shared/hooks';
    import ViewOrderCard from '../ViewOrderCard/ViewOrderCard.vue';

    type Props = {
        orderId: string;
    };

    const props = defineProps<Props>();

    const modelValue = defineModel<boolean>();
    const dialogWidth = useDialogWidth({ md: 0.75, lg: 0.75, xl: 0.65 });

    function close() {
        modelValue.value = false;
    }
</script>
<template>
    <v-dialog v-model="modelValue" :width="dialogWidth" scrollable>
        <ViewOrderCard :order-id="props.orderId" @close="close" />
    </v-dialog>
</template>
