import { useCommand } from '@/features/api';
import { getBlobBase64Buffer } from '@/shared/utilities';

import { computed, ref } from 'vue';
import type { GetPresignedUrlResponse } from '../api';

import type { UploadState } from '@/shared/utilities/upload';
import { Upload } from '@/shared/utilities/upload';
import { toast } from 'vuetify-sonner';

// Non-reactive props
type Props = {
    companyId: number;
    onSuccess?: () => void;
};

export function useFileUploader({ companyId, onSuccess }: Props) {
    let upload: Upload | null = null;

    const presignedUrlInternal = ref<GetPresignedUrlResponse | null>(null);
    const uploadSpeed = ref(0);
    const uploadProgress = ref(0);
    const uploadState = ref<UploadState>('none');
    const isUploading = ref(false);
    const error = ref<string | null>(null);

    const fileId = computed(() => presignedUrlInternal.value?.fileId);

    const {
        error: getPresignedUrlError,
        mutateAsync: getPresignedUrl,
    } = useCommand('files/GET_PRESIGNED_URL', {
        onError: () => {
            toast.error('Failed to get file upload URL.');
        },
    });

    const { mutateAsync: notifyUploadStarted } = useCommand('files/NOTIFY_UPLOAD_STARTED');
    const { mutateAsync: notifyUploadCompleted } = useCommand('files/NOTIFY_UPLOAD_COMPLETED');
    const { mutateAsync: notifyUploadFailed } = useCommand('files/NOTIFY_UPLOAD_FAILED');

    async function getOrSetPresignedUrl(file: File) {
        if (!presignedUrlInternal.value) {
            const b64Buffer = await getBlobBase64Buffer(file);

            const presignedUrlResult = await getPresignedUrl({
                request: {
                    companyId,
                    fileName: file.name,
                    b64Buffer,
                    fileKind: 'Media',
                },
            });

            if (!presignedUrlResult) {
                return null;
            }

            presignedUrlInternal.value = presignedUrlResult;
        }

        return presignedUrlInternal.value;
    }

    function onProgress(progressValue: number) {
        uploadProgress.value = Math.floor(progressValue * 100);
        uploadSpeed.value = upload?.speed || 0;
    }

    function onState(state: UploadState) {
        uploadState.value = state;
    }

    function cancelUpload() {
        if (upload) {
            upload.abort();
        }

        isUploading.value = false;
    }

    async function uploadFile(file: File, initialPresignedUrl: GetPresignedUrlResponse | null = null) {
        try {
            if (isUploading.value) throw new Error('File is already uploading');

            uploadState.value = 'none';
            isUploading.value = true;
            const presignedUrl = initialPresignedUrl ? initialPresignedUrl : await getOrSetPresignedUrl(file);

            if (!presignedUrl) {
                return false;
            }

            upload = new Upload({
                form: file,
                url: presignedUrl.url,
                headers: presignedUrl.headers,
            });
            upload.on('progress', onProgress);
            upload.on('state', onState);

            await notifyUploadStarted({
                params: {
                    fileId: presignedUrl.fileId,
                },
            });

            const result = await upload.upload();

            console.log('Upload File Result: ', result);

            if (result.status === 200) {
                await notifyUploadCompleted({
                    params: {
                        fileId: presignedUrl.fileId,
                    },
                });
                isUploading.value = false;
                toast.success('File successfully uploaded');
                onSuccess?.();
                return true;
            }

            await notifyUploadFailed({
                request: `Failed with status ${result.status?.toString()}`,
                params: {
                    fileId: presignedUrl.fileId,
                },
            });

            console.error('Error uploading file: ', result);

            toast.error('Failed to upload file. Please try again.');

            isUploading.value = false;
            uploadState.value = 'failed';
            return false;
        } catch (err) {
            toast.error('Failed to upload file. Please try again.');
            console.error('Error uploading file: ', err);
            isUploading.value = false;
            uploadState.value = 'failed';
            return false;
        }
    }

    return {
        uploadFile,
        cancelUpload,
        isUploading,
        uploadProgress,
        uploadSpeed,
        uploadState,
        fileId,
        error,
        getPresignedUrlError,
    };
}
