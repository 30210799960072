const palettes = {
    primary: {
        '0': '#000000',
        '5': '#181000',
        '10': '#251A00',
        '15': '#312400',
        '20': '#3E2E00',
        '25': '#4C3900',
        '30': '#5A4400',
        '35': '#684F00',
        '40': '#775A00',
        '50': '#957200',
        '60': '#B48B00',
        '70': '#D5A400',
        '80': '#F6BF00',
        '90': '#FFDF96',
        '95': '#FFEFD1',
        '98': '#FFF8F1',
        '99': '#FFFBFF',
        '100': '#FFFFFF',
    },
    secondary: {
        '0': '#000000',
        '5': '#181000',
        '10': '#251A00',
        '15': '#302404',
        '20': '#3C2F0D',
        '25': '#473A17',
        '30': '#544521',
        '35': '#60512C',
        '40': '#6D5D37',
        '50': '#87754D',
        '60': '#A28F64',
        '70': '#BDA97D',
        '80': '#DAC496',
        '90': '#F7E0B0',
        '95': '#FFEFD1',
        '98': '#FFF8F1',
        '99': '#FFFBFF',
        '100': '#FFFFFF',
    },
    tertiary: {
        '0': '#000000',
        '5': '#001503',
        '10': '#002107',
        '15': '#052D0E',
        '20': '#123818',
        '25': '#1D4322',
        '30': '#294F2C',
        '35': '#355B37',
        '40': '#416742',
        '50': '#598159',
        '60': '#729B71',
        '70': '#8CB68A',
        '80': '#A6D2A4',
        '90': '#C2EEBF',
        '95': '#D0FCCC',
        '98': '#EBFFE6',
        '99': '#F6FFF1',
        '100': '#FFFFFF',
    },
    neutral: {
        '0': '#000000',
        '5': '#13110C',
        '10': '#1E1B16',
        '15': '#282520',
        '20': '#33302A',
        '25': '#3F3B35',
        '30': '#4A4640',
        '35': '#56524B',
        '40': '#625E57',
        '50': '#7B766F',
        '60': '#959088',
        '70': '#B0AAA2',
        '80': '#CCC5BD',
        '90': '#E9E1D9',
        '95': '#F7F0E7',
        '98': '#FFF8F1',
        '99': '#FFFBFF',
        '100': '#FFFFFF',
    },
    'neutral-variant': {
        '0': '#000000',
        '5': '#151006',
        '10': '#201B10',
        '15': '#2B251A',
        '20': '#363024',
        '25': '#413B2E',
        '30': '#4D4639',
        '35': '#595244',
        '40': '#655E4F',
        '50': '#7E7667',
        '60': '#999080',
        '70': '#B4AA99',
        '80': '#D0C5B4',
        '90': '#ECE1CF',
        '95': '#FBEFDD',
        '98': '#FFF8F1',
        '99': '#FFFBFF',
        '100': '#FFFFFF',
    },
    error: { // DO NOT PASTE OVER: The generator does not generate the error palette
        '0': '#000000',
        '5': '#2D0001',
        '10': '#410002',
        '15': '#540003',
        '20': '#690005',
        '25': '#7E0007',
        '30': '#93000A',
        '35': '#A80710',
        '40': '#BA1A1A',
        '50': '#DE3730',
        '60': '#FF5449',
        '70': '#FF897D',
        '80': '#FFB4AB',
        '90': '#FFDAD6',
        '95': '#FFEDEA',
        '99': '#FFFBFF',
        '100': '#FFFFFF',
    },
} as const;
type PaletteKey = keyof typeof palettes;
type ShadeKey = keyof typeof palettes[PaletteKey];

const generatedPaletteColors = {
    ...generatedPaletteColors2(),
};

function generatedPaletteColors2() {
    const colors: Record<string, string> = {};
    Object.keys(palettes).forEach((paletteKey) => {
        Object.keys(palettes[paletteKey as PaletteKey]).forEach((shadeKey) => {
            const colorKey = `${paletteKey}-${shadeKey}`;
            colors[colorKey] = palettes[paletteKey as PaletteKey][shadeKey as ShadeKey];
        });
    });

    console.log('colors', colors);

    return colors;
}

export const M3LightTheme = {
    name: 'M3LightTheme',
    dark: false,
    colors: {
        primary: palettes.primary['40'],
        'surface-tint': palettes.primary['40'],
        'on-primary': palettes.primary['100'],
        'primary-container': palettes.primary['90'],
        'on-primary-container': palettes.primary['10'],
        secondary: palettes.secondary['40'],
        'on-secondary': palettes.secondary['100'],
        'secondary-container': palettes.secondary['90'],
        'on-secondary-container': palettes.secondary['10'],
        tertiary: palettes.tertiary['40'],
        'on-tertiary': palettes.tertiary['100'],
        'tertiary-container': palettes.tertiary['90'],
        'on-tertiary-container': palettes.tertiary['10'],
        error: palettes.error['40'],
        'on-error': palettes.error['100'],
        'error-container': palettes.error['90'],
        'on-error-container': palettes.error['10'],
        background: palettes.neutral['98'], // Was the same for surface
        'on-background': palettes.neutral['10'],
        surface: palettes.neutral['98'], // Was 98 but that's teh same for surface bright, so the guide may be wrong?
        'on-surface': palettes.neutral['10'],
        'on-surface-variant': palettes['neutral-variant']['30'],
        'on-surface-container-lowest': palettes['neutral-variant']['10'],
        'on-surface-container-low': palettes['neutral-variant']['10'],
        'on-surface-container': palettes['neutral-variant']['30'],
        'on-surface-container-high': palettes['neutral-variant']['30'],
        'on-surface-container-highest': palettes['neutral-variant']['30'],
        'surface-variant': palettes['neutral-variant']['80'],
        outline: '#8B9297',
        'outline-variant': palettes['neutral-variant']['80'],
        shadow: '#000000',
        scrim: '#000000',
        'inverse-surface': palettes.neutral['20'],
        'inverse-on-surface': palettes.neutral['95'],
        'inverse-primary': palettes.primary['80'],
        'primary-fixed': palettes.primary['90'],
        'on-primary-fixed': palettes.primary['10'],
        'primary-fixed-dim': palettes.primary['80'],
        'on-primary-fixed-variant': palettes.primary['30'],
        'secondary-fixed': palettes.secondary['90'],
        'on-secondary-fixed': palettes.secondary['10'],
        'secondary-fixed-dim': palettes.secondary['80'],
        'on-secondary-fixed-variant': palettes.secondary['30'],
        'tertiary-fixed': palettes.tertiary['90'],
        'on-tertiary-fixed': palettes.tertiary['10'],
        'tertiary-fixed-dim': palettes.tertiary['80'],
        'on-tertiary-fixed-variant': palettes.tertiary['30'],
        'surface-dim': '#E3D9C8',
        'surface-bright': '#FFF8F1',
        'surface-container-lowest': '#FFFFFF',
        'surface-container-low': '#FDF2E1',
        'surface-container': '#F7EDDB',
        'surface-container-high': '#F2E7D6',
        'surface-light': '#F2E7D6', // Same as container high
        'surface-container-highest': '#ECE1D0',
        // 'surface-dim': palettes.neutral[80],
        // 'surface-bright': palettes.neutral[98],
        // 'surface-container-lowest': '#FFFFFF',
        // 'surface-container-low': '#F0F4F8',
        // 'surface-container': '#EBEEF3',
        // 'surface-container-high': '#E5E8ED',
        // 'surface-container-highest': '#DFE3E7',
        'shaded-base': '#EEEEEE',
        'brighten-base': '#000000',
        ...generatedPaletteColors,
    },
};

const darkMaterialColors = {
    success: '#39cf3f',
    green: '#39cf3f',
};

export const M3DarkTheme = {
    name: 'M3DarkTheme',
    dark: true,
    colors: {
        ...darkMaterialColors,
        primary: palettes.primary['80'],
        'surface-tint': palettes.primary['80'],
        'on-primary': palettes.primary['20'],
        'primary-container': palettes.primary['30'],
        'on-primary-container': palettes.primary['90'],
        secondary: palettes.secondary['80'],
        'on-secondary': palettes.secondary['20'],
        'secondary-container': palettes.secondary['30'],
        'on-secondary-container': palettes.secondary['90'],
        tertiary: palettes.tertiary['80'],
        'on-tertiary': palettes.tertiary['20'],
        'tertiary-container': palettes.tertiary['30'],
        'on-tertiary-container': palettes.tertiary['90'],
        error: palettes.error['60'],
        'on-error': palettes.error['20'],
        'text-on-error': palettes.error['20'],
        'error-container': palettes.error['30'],
        'on-error-container': palettes.error['90'],
        'text-on-error-container': palettes.error['90'],
        background: '#171309',
        // background: '#0F1417',
        'on-background': palettes.neutral[80],
        surface: '#171309',
        // surface: '#0F1417',
        'on-surface': palettes.neutral[80],
        'on-surface-bright': palettes.neutral[90],
        'surface-variant': palettes['neutral-variant']['60'],
        'on-surface-variant': '#C1C7CD',
        'on-surface-container-lowest': '#C1C7CD',
        'on-surface-container-low': '#C1C7CD',
        'on-surface-container': '#cfd4d8',
        'on-surface-container-high': '#dadee2',
        'on-surface-container-highest': '#dadee2',
        outline: palettes['neutral-variant']['60'],
        'outline-variant': palettes['neutral-variant']['30'],
        shadow: '#000000',
        scrim: '#000000',
        'inverse-surface': palettes.neutral[90],
        'inverse-on-surface': palettes.neutral[20],
        'inverse-primary': palettes.primary[40],
        'primary-fixed': palettes.primary[90],
        'on-primary-fixed': palettes.primary[10],
        'primary-fixed-dim': palettes.primary[80],
        'on-primary-fixed-variant': palettes.primary[30],
        'secondary-fixed': palettes.secondary[90],
        'on-secondary-fixed': palettes.secondary[10],
        'secondary-fixed-dim': palettes.secondary[80],
        'on-secondary-fixed-variant': palettes.secondary[30],
        'tertiary-fixed': palettes.tertiary[90],
        'on-tertiary-fixed': palettes.tertiary[10],
        'tertiary-fixed-dim': palettes.tertiary[80],
        'on-tertiary-fixed-variant': palettes.tertiary[30],
        'surface-dim': '#171309',
        'surface-bright': '#3E392D',
        'surface-container-lowest': '#120E05',
        'surface-container-low': '#201B11',
        'surface-container': '#241F14',
        'surface-container-high': '#2F291E',
        'surface-light': '#2F291E', // Same as container high
        'surface-container-highest': '#3A3428',
        'surface-container-highest-1': '#524c3f',
        // 'surface-dim': '#0F1417',
        // 'surface-bright': '#353A3D',
        // 'surface-container-lowest': '#0A0F12',
        // 'surface-container-low': '#181C1F',
        // 'surface-container': '#1C2023',
        // 'surface-container-high': '#262B2E',
        // 'surface-container-highest': '#313539',
        'shaded-base': '#000000',
        'brighten-base': '#EEEEEE',
        ...generatedPaletteColors,
    },
};

export const DarkTheme = {
    name: 'DarkTheme',
    dark: true,
    colors: {
        primary: '#1e88e5', // Built in
        secondary: '#7c4dff', // Built in
        info: '#03c9d7', // Built in
        success: '#05b187', // Built in
        accent: '#fc4b6c', // Built in
        warning: '#fec90f', // Built in
        error: '#fc4b6c', // Built in
        containerBg: '#1a223f', // Main container background
        lightprimary: '#29314f',
        lightsecondary: '#29314f',
        lightsuccess: '#143c33',
        lighterror: '#f9d8d8',
        lightwarning: '#fff8e1',
        darkprimary: '#1565c0',
        darksecondary: '#4527a0',
        darkText: '#d7dcec',
        lightText: '#bdc8f0',
        borderLight: '#404968',
        inputBorder: '#5f698d',
        surface: '#111936',
        background: '#111936',
        'on-surface-variant': '#111936',
        facebook: '#4267b2',
        twitter: '#1da1f2',
        linkedin: '#0e76a8',
        gray100: '#19203c',
        primary200: '#90caf9',
        secondary200: '#b39ddb',
    },
} as const;

export const LightTheme = {
    name: 'LightTheme',
    dark: false,
    variables: {
        'border-color': '#1e88e5',
        'carousel-control-size': 10,
    },
    colors: {
        primary: '#1e88e5',
        secondary: '#e57b1e',
        info: '#03c9d7',
        success: '#00C853',
        accent: '#FFAB91',
        warning: '#FFC107',
        error: '#D84315',
        lightprimary: '#eef2f6',
        lightsecondary: '#ede7f6',
        lightsuccess: '#b9f6ca',
        lighterror: '#f9d8d8',
        lightwarning: '#fff8e1',
        darkText: '#072D77',
        lightText: '#616161',
        darkprimary: '#072D77',
        darksecondary: '#072D77',
        borderLight: '#d0d0d0',
        inputBorder: '#787878',
        containerBg: '#F5FCFF',
        surface: '#fff',
        'on-surface-variant': '#fff',
        facebook: '#4267b2',
        twitter: '#1da1f2',
        linkedin: '#0e76a8',
        gray100: '#fafafa',
        primary200: '#90caf9',
        secondary200: '#b39ddb',
    },
};

export const DARK_THEME_VARIATIONS = {
    colors: Object.keys(DarkTheme.colors),
    lighten: 5,
    darken: 5,
} as const;

export const LIGHT_THEME_VARIATIONS = {
    colors: Object.keys(LightTheme.colors),
    lighten: 5,
    darken: 5,
} as const;
