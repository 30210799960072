import type { Ref } from 'vue';
import { computed } from 'vue';
import { usePricingConfig } from './usePricingConfig';
import type { FlightDurationParams } from './utils';
import { resolveDuration } from './utils';

/** The computed discount (As a multiple) based on the months commitment for an order */
export function useDiscountMultiple(params: Ref<FlightDurationParams | undefined | null>) {
    const { isLoading, pricingConfig } = usePricingConfig();

    const discountMultiple = computed(() => {
        if (!params.value || !pricingConfig.value) return 1;

        // TODO: #61 - Handle Orders that run for less than a month, and for partial months
        const months = resolveDuration(params.value);
        if (months < 1) return 1;

        return 1 - pricingConfig.value.baseCommitmentDiscounts[months];
    });

    return {
        isLoading,
        discountMultiple,
    };
}
