export interface DataPaginationFooterData {
    currentPageStartIndex: number;
    currentPageEndIndex: number;
    itemsLength: number;

    itemsPerPage: number;
    itemsPerPageOptions: number[];
    currentPage: number;
}

export function isEasyDataTableFooterData(
    obj: EasyDataTableFooter | DataPaginationFooterData,
): obj is EasyDataTableFooter {
    return 'clientItemsLength' in obj;
}
