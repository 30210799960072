<script setup lang="ts">
    import type { MetadataIndustryResponse } from '@/features/metadata/metadataResponse';
    import { computed } from 'vue';

    type Props = {
        sector?: string;
        industry?: MetadataIndustryResponse;
        /** Uses the first industry item. Helper for entities that will only be constrained to a single Sector in their metadata */
        industries?: MetadataIndustryResponse[];
        size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    };

    const props = withDefaults(defineProps<Props>(), {
        size: 'h2',
        sector: undefined,
        industry: undefined,
        industries: undefined,
    });

    const sectorName = computed(() =>
        props.sector || props.industry?.industryName || props.industries?.at(0)?.sectorName || 'NotProvided'
    );

    // const iconsMap: Record<string, string> = {
    //     Category: 'Icon',
    //     'Accounting & Financial Services': 'bank-circle-outline',
    //     'Arts & Culture': 'drama-masks',
    //     'Automotive & Transportation': 'train-car',
    //     'Aviation & Aerospace': 'airplane',
    //     'Beauty & Personal Care': 'spa',
    //     'Business & Legal Services': 'scale-balance',
    //     'Construction & Real Estate': 'home-city',
    //     'Consumer Goods & Retail': 'cart-variant',
    //     'Creative Arts & Design': 'palette-advanced',
    //     'Education & Research': 'school-outline',
    //     'Entertainment & Leisure': 'youtube-tv || television-classic',
    //     'Health & Wellness Facilities': 'bottle-tonic-plus-outline',
    //     'Healthcare & Medical Services': 'medical-bag',
    //     'Hospitality & Food Services': 'food-fork-drink',
    //     'Industrial & Manufacturing': 'factory',
    //     'Information Technology & Services': 'cellphone-information',
    //     'Public Sector & Nonprofit': 'earth',
    //     'Science & Technology': 'flask',
    //     'Telecommunications & Media': 'multimedia',
    //     'Travel and Tourism': 'wallet-travel',
    //     'Agriculture & Farming': 'home-silo-outline',
    //     'Energy and Utilities': 'lightning-bolt-circle',
    //     'Community & Recreation': 'soccer-field',
    // };

    const iconsMap2: Record<string, string> = {
        Category: 'Icon',
        'Accounting & Financial Services': '🏛️',
        'Arts & Culture': '🎭',
        'Automotive & Transportation': '🚗',
        'Aviation & Aerospace': '✈️',
        'Beauty & Personal Care': '💄',
        'Business & Legal Services': '⚖️',
        'Construction & Real Estate': '🏠',
        'Consumer Goods & Retail': '🛒',
        'Creative Arts & Design': '🎨',
        'Education & Research': '🎓',
        'Entertainment & Leisure': '🎬',
        'Health & Wellness Facilities': '💪',
        'Healthcare & Medical Services': '🏥',
        'Hospitality & Food Services': '🍽️',
        'Industrial & Manufacturing': '🏭',
        'Information Technology & Services': '💻',
        'Public Sector & Nonprofit': '🌎',
        'Science & Technology': '🔬',
        'Telecommunications & Media': '📡',
        'Travel and Tourism': '🧳',
        'Agriculture & Farming': '🌾',
        'Energy and Utilities': '⚡',
        'Community & Recreation': '🤽‍♂️',
    };

    const className = computed(() => `text-${props.size}`);

    // const icon = ref<string>(`mdi-${iconsMap2[props.categoryName]}` || 'mdi-help-rhombus-outline');
    const icon = computed(() => iconsMap2[sectorName.value] || '❓');
</script>

<template>
    <slot :icon="icon">
        <span :class="className">{{ icon }}</span>
    </slot>
    <!-- <v-icon :size="props.size" :color="props.color" :icon="icon" /> -->
</template>
