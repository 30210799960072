const PLURAL_MAP: Record<string, string> = {
    company: 'companies',
};

export function pluralize(singular: string, count: number) {
    if (!count || count <= 1) return singular;
    if (PLURAL_MAP[singular]) return PLURAL_MAP[singular];

    return singular + 's';
}
