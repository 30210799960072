// Composables
import { useAuthStore, useLoginStore } from '@/features/auth';
import { createRouter, createWebHistory } from 'vue-router';
import { routes } from './routes';

export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// eslint-disable-next-line @typescript-eslint/require-await
router.beforeEach(async (to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    // auth/change-password is excluded because it's a special case
    const publicPages = [
        '/auth/login',
        '/auth/register',
        '/auth/forgot-password',
        '/auth/reset-password',
        '/auth/reset-check-email',
        '/auth/register-check-email',
        '/auth/verify-email',
    ];
    const loginPage = '/auth/login';
    const authRequired = !publicPages.includes(to.path);
    const { authenticated, changePasswordRequired, initPromise } = useAuthStore();
    const { setReturnUrl } = useLoginStore();

    await Promise.all([initPromise]);

    const goNext = () => {
        if (to.path === loginPage && authenticated) {
            return next('/');
        }

        next();
    };

    console.log('router guard', { authenticated, changePasswordRequired });

    if (changePasswordRequired) {
        if (to.path !== '/auth/change-password') return next('/auth/change-password');
        return goNext();
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (authRequired && !authenticated) {
            setReturnUrl(to.fullPath);
            return next('/auth/login');
        } else goNext();
    } else {
        goNext();
    }
});
