<script setup lang="ts">
    import { useDialogWidth } from '@/shared/hooks';
    import ScreensSelectView from './ScreensSelectView';

    type Props = {
        excludeSectors?: number[];
    };

    withDefaults(defineProps<Props>(), {
        excludeSectors: undefined,
    });

    const modelValue = defineModel<boolean>();
    const selectedScreensModel = defineModel<number[]>('screenIds', { required: true });

    const dialogWidth = useDialogWidth();
</script>

<template>
    <v-dialog v-model="modelValue" :width="dialogWidth" height="90vh">
        <v-card class="h-100">
            <v-card-title class="text-center truly-center--grid gap-sm bg-surface-container-high">
                <div />
                <span class="text-h2">Select Locations</span>
                <v-btn
                    color="grey"
                    icon="mdi-close"
                    variant="text"
                    density="compact"
                    size="large"
                    @click="() => (modelValue = false)"
                />
            </v-card-title>
            <v-card-text class="main-body">
                <ScreensSelectView v-model="selectedScreensModel" :exclude-sectors="excludeSectors" />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" size="large" variant="tonal" @click="() => (modelValue = false)">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style lang="scss" scoped>
.v-card-text.main-body {
    height: calc(100% - 70px - 56px);
}
</style>
