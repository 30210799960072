<script setup lang="ts">
    import { computed, ref, toRef, watch } from 'vue';

    import { useCreativeDownloadUrl } from '../hooks';

    type Props = {
        creativeId: string;
        isDownload?: boolean;
        disabled?: boolean;
        size?: string | number;
    };

    type Emits = {
        linkOpened: [];
    };

    const props = defineProps<Props>();
    const emits = defineEmits<Emits>();
    const enable = ref(false);
    const isDownloadBehavior = computed(() => props.isDownload || false);

    const { isLoading, url } = useCreativeDownloadUrl(toRef(() => props.creativeId), isDownloadBehavior.value, enable);

    function clickDownload() {
        if (!enable.value) {
            enable.value = true;
        }

        if (url.value) {
            openLink(url.value);
        }
    }

    function openLink(localUrl: string) {
        window.open(localUrl, '_blank');
        emits('linkOpened');
    }

    watch(url, (newUrl) => {
        if (newUrl && enable.value) {
            openLink(newUrl);
        }
    });
</script>

<template>
    <slot :loading="isLoading" :url="url" :disabled="disabled" :click-download="clickDownload">
        <v-btn
            icon="mdi-download"
            :loading="isLoading"
            :disabled="disabled"
            color="primary"
            :size="size"
            @click="clickDownload"
        />
    </slot>
</template>
