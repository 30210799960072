<script setup lang="ts">
    import ConfirmPopover from '@/components/ConfirmPopover.vue';
    import { useMyCompany } from '@/features/companies/hooks';
    import { toRef } from 'vue';
    import type { InvoiceResponse } from '../../api';
    import { useInvoiceStripeUrls } from '../../hooks';
    import ActionButton from './ActionButton.vue';

    type Props = {
        invoice: InvoiceResponse;
        loading: boolean;
    };

    type Emits = {
        'click:close': [];
        'click:play': [];
        'click:markPaid': [];
    };

    const props = defineProps<Props>();
    defineEmits<Emits>();

    const { isRoot } = useMyCompany();
    const { isLoading, invoiceStripeUrls } = useInvoiceStripeUrls(
        toRef(() => props.invoice.id),
    );
</script>

<template>
    <v-card-actions class="pa-3 gap-sm">
        <ActionButton color="grey" @click="$emit('click:close')">Close</ActionButton>
        <!-- Unpaid Invoice -->
        <template v-if="invoice.status == 'Open'">
            <ActionButton
                v-if="!isRoot"
                color="green-lighten-1"
                prepend-icon="mdi-arrow-top-right-thick"
                :disabled="!$can('campaign:manage')"
                :loading="loading || isLoading"
                :href="invoiceStripeUrls?.hostedUrl"
                target="_blank"
            >
                Pay ${{ $filters.number(invoice.amountDue) }}
            </ActionButton>

            <ConfirmPopover action-color="orange" @confirm="$emit('click:markPaid')">
                <ActionButton
                    v-if="isRoot"
                    color="green-lighten-1"
                    prepend-icon="mdi-check"
                    :disabled="!$can('campaign:manage:any')"
                    :loading="loading"
                    @click.stop=""
                >
                    Mark Paid
                </ActionButton>
            </ConfirmPopover>
        </template>
        <slot />
    </v-card-actions>
</template>
