import type { FlightDurationParams } from '@/features/pricing/hooks';
import { usePricesPerPlay } from '@/features/pricing/hooks';
import type { Ref } from 'vue';
import { computed, toRefs } from 'vue';
import { useBudget } from './useBudget';

const EMPTY_DURATIONS = {
    10: 0,
    15: 0,
    30: 0,
};

interface Props {
    companyId: number | undefined | null;
    campaignId: string | undefined | null;
    flightTime: FlightDurationParams | null;
}

/** Reactively calculates the number of plays an order can have for each duration based on the campaign || company budget */
export function useBudgetablePlays(props: Ref<Props>) {
    const { companyId, campaignId, flightTime } = toRefs(props.value);

    const { pricesPerPlay } = usePricesPerPlay(flightTime);

    const { isLoading: budgetLoading, companyBudget } = useBudget(companyId, campaignId);

    const isLoading = computed(() => budgetLoading.value);
    const hasBudget = computed(() => companyBudget.value && companyBudget.value.hasBudget);

    const playsLimits = computed(() => {
        if (!companyBudget.value || !pricesPerPlay.value || !companyBudget.value.hasBudget) {
            return EMPTY_DURATIONS;
        }

        return {
            10: Math.floor(companyBudget.value.remainingBudget / pricesPerPlay.value[10]),
            15: Math.floor(companyBudget.value.remainingBudget / pricesPerPlay.value[15]),
            30: Math.floor(companyBudget.value.remainingBudget / pricesPerPlay.value[30]),
        };
    });

    const hasPlaysLimits = computed(() => {
        if (budgetLoading.value || !hasBudget.value) return false;

        return !!playsLimits.value[10]; // If there is a limit for 10s, there is a limit for all
    });

    return {
        isLoading,
        playsLimits,
        hasBudget,
        hasPlaysLimits,
    };
}
