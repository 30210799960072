<script setup lang="ts">
    import { computed, toRef } from 'vue';

    import LoaderOverlay from '@/components/LoaderOverlay.vue';
    import { useDiscountProgression } from '../hooks';

    type Group = {
        discount: number;
        savings: number;
        start: number;
        end: number;
    };

    type Props = {
        months: number;
        rawMonthlySpend: number;
    };

    const props = defineProps<Props>();

    const { isLoading, discountProgression } = useDiscountProgression(toRef(() => props.months));

    const monthsWhole = computed(() => {
        return Math.floor(props.months);
    });

    const monthFraction = computed(() => {
        return props.months % 1;
    });

    // The final, fractional, month if it exists
    const finalMonthCost = computed(() => {
        if (!discountProgression.value) return null;
        if (!monthFraction.value) return null;
        if (!avgDiscount.value) return null;

        const discount = avgDiscount.value;

        return {
            spend: getSpend(discount) * monthFraction.value,
            discount,
            savings: getSavings(discount) * monthFraction.value,
        };
    });

    const progression = computed(() => {
        if (!discountProgression.value) return [];

        return discountProgression.value;
    });

    const groupedProgressions = computed(() => {
        if (!progression.value) return [];

        // Group by discount, with the start & end month for that discount
        return progression.value.reduce<Group[]>((acc, discount, index) => {
            if (index === 0) {
                acc.push({
                    discount,
                    savings: getSavings(discount),
                    start: index + 1,
                    end: index + 1,
                });
            } else if (discount === progression.value[index - 1]) {
                acc[acc.length - 1].end = index + 1;
            } else {
                acc.push({
                    discount,
                    savings: getSavings(discount),
                    start: index + 1,
                    end: index + 1,
                });
            }

            return acc;
        }, []);
    });

    const spends = computed(() => {
        return progression.value.map((discount) => getSpend(discount));
    });

    const savings = computed(() => {
        return progression.value.map((discount) => getSavings(discount));
    });

    const totalSpend = computed(() => {
        return spends.value.reduce((acc, spend) => acc + spend, 0) + (finalMonthCost.value?.spend ?? 0);
    });

    const totalSavings = computed(() => {
        return savings.value.reduce((acc, saving) => acc + saving, 0) + (finalMonthCost.value?.savings ?? 0);
    });

    const avgDiscount = computed(() => {
        const discount = progression.value.reduce((acc, discount) => acc + discount, 0)
            / progression.value.length;

        return Math.round(discount * 100) / 100;
    });

    const discountQuartiles = computed(() => {
        if (!progression.value || !avgDiscount.value) {
            return null;
        }

        // const avg = avgDiscount.value;

        // Not actual quartiles since we are using avg, and not a median with the set itself
        // return {
        //     lowerQuartile: Math.floor(avg / 2),
        //     upperQuartile: Math.ceil(avg + (avg / 2)),
        //     avg: avg,
        // };

        const sortedDiscounts = [...new Set(progression.value)].sort((a, b) => a - b);

        const medianIndex = Math.floor(sortedDiscounts.length / 2);
        const median = sortedDiscounts[medianIndex];

        const lowerHalf = sortedDiscounts.slice(0, medianIndex);
        const upperHalf = sortedDiscounts.slice(medianIndex + 1);

        const lowerQuartileIndex = Math.floor(lowerHalf.length / 2);
        const lowerQuartile = lowerHalf[lowerQuartileIndex];

        const upperQuartileIndex = Math.floor(upperHalf.length / 2);
        const upperQuartile = upperHalf[upperQuartileIndex];

        const discountMinMax = [lowerQuartile, median, upperQuartile];

        return {
            lowerQuartile,
            median,
            upperQuartile,
            discountMinMax,
        };
    });

    const colors = computed(() => {
        if (!progression.value || !discountQuartiles.value) return [];

        const { lowerQuartile, upperQuartile, median } = discountQuartiles.value;

        return progression.value.map((discount) => {
            if (discount === 0) {
                return 'text-orange';
            } else if (discount < lowerQuartile) {
                return 'text-yellow';
            } else if (discount >= lowerQuartile && discount < median) {
                return 'text-lime';
            } else if (discount == median) {
                return 'text-lime';
            } else if (discount > median && discount < upperQuartile) {
                return 'text-light-green';
            } else {
                return 'text-green';
            }
        });
    });

    function getSavings(discount: number) {
        if (!progression.value) return 0;

        const spend = getSpend(discount);

        return props.rawMonthlySpend - spend;
    }

    function getSpend(discount: number) {
        if (!progression.value) return 0;

        const percent = discount / 100;
        const spend = (1 - percent) * props.rawMonthlySpend;

        return spend;
    }
</script>

<template>
    <v-card
        v-if="isLoading"
        variant="outlined"
        rounded="sm"
        color="surface-variant"
        class="p-relative w-75"
        height="175px"
    >
        <LoaderOverlay :active="true" />
    </v-card>
    <v-card v-else variant="outlined" rounded="sm" color="surface-variant">
        <v-table v-if="progression.length <= 12" density="compact" class="text-caption billing-matrix-table">
            <thead>
                <tr>
                    <th class="text-center">
                        Month
                    </th>
                    <th v-for="(discount, monthIndex) in progression" :key="monthIndex" class="text-center">
                        {{ monthIndex + 1 }}
                    </th>
                    <th v-if="finalMonthCost" class="text-center">
                        <div>(Prorate)</div>
                        <div>{{ monthsWhole + 1 }}</div>
                    </th>
                    <th class="text-center font-weight-bold text-h4">
                        Total
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center">
                        Spend
                    </td>
                    <td v-for="(discount, monthIndex) in progression" :key="monthIndex" class="text-center">
                        ${{ $filters.number(spends[monthIndex]) }}
                    </td>
                    <th v-if="finalMonthCost" class="text-center">
                        ${{ $filters.number(finalMonthCost.spend) }}
                    </th>
                    <td class="text-center font-weight-bold text-h4">
                        ${{ $filters.number(totalSpend) }}
                    </td>
                </tr>
                <tr>
                    <td class="text-center">
                        Savings
                    </td>
                    <td
                        v-for="(discount, monthIndex) in progression"
                        :key="monthIndex"
                        class="text-center"
                        :class="colors[monthIndex]"
                    >
                        ${{ $filters.number(savings[monthIndex]) }}
                    </td>
                    <th v-if="finalMonthCost" class="text-center">
                        ${{ $filters.number(finalMonthCost.savings) }}
                    </th>
                    <td class="text-center font-weight-bold text-h4">
                        ${{ $filters.number(totalSavings) }}
                    </td>
                </tr>
                <tr>
                    <td class="text-center">
                        Discount
                    </td>
                    <td
                        v-for="(discount, monthIndex) in discountProgression"
                        :key="monthIndex"
                        :class="colors[monthIndex]"
                    >
                        <v-chip size="small">
                            {{ discount }}%
                        </v-chip>
                    </td>
                    <th v-if="finalMonthCost" class="text-center">
                        <v-chip size="small">
                            {{ finalMonthCost.discount }}%
                        </v-chip>
                    </th>
                    <td class="text-center font-weight-bold text-green text-h4">
                        {{ avgDiscount }}%
                    </td>
                </tr>
            </tbody>
        </v-table>
        <v-table v-else density="compact" class="text-caption billing-matrix-table">
            <thead>
                <tr>
                    <th class="text-center">
                        Month
                    </th>
                    <th v-for="group in groupedProgressions" :key="group.discount" class="text-center">
                        <span v-if="group.start != group.end">{{ group.start }}-> {{ group.end }}</span>
                        <span v-else>{{ group.end }}</span>
                    </th>
                    <th v-if="finalMonthCost" class="text-center">
                        <div>(Prorate)</div>
                        <div>{{ monthsWhole + 1 }}</div>
                    </th>
                    <th class="text-center font-weight-bold text-h4">
                        Total
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center">
                        Spend
                    </td>
                    <td v-for="group in groupedProgressions" :key="group.discount" class="text-center">
                        ${{ $filters.number(getSpend(group.discount)) }}
                    </td>
                    <th v-if="finalMonthCost" class="text-center">
                        ${{ $filters.number(finalMonthCost.spend) }}
                    </th>
                    <td class="text-center font-weight-bold text-h4">
                        ${{ $filters.number(totalSpend) }}
                    </td>
                </tr>
                <tr>
                    <td class="text-center">
                        Savings
                    </td>
                    <td
                        v-for="group in groupedProgressions"
                        :key="group.discount"
                        class="text-center"
                        :class="colors[group.start - 1]"
                    >
                        ${{ $filters.number(group.savings) }}
                    </td>
                    <th v-if="finalMonthCost" class="text-center">
                        <v-chip size="small">
                            ${{ $filters.number(finalMonthCost.savings) }}
                        </v-chip>
                    </th>
                    <td class="text-center font-weight-bold text-h4">
                        ${{ $filters.number(totalSavings) }}
                    </td>
                </tr>
                <tr>
                    <td class="text-center">
                        Discount
                    </td>
                    <td
                        v-for="group in groupedProgressions"
                        :key="group.discount"
                        class="text-center"
                        :class="colors[group.start - 1]"
                    >
                        <v-chip size="small">
                            {{ group.discount }}%
                        </v-chip>
                    </td>
                    <th v-if="finalMonthCost" class="text-center">
                        <v-chip size="small">
                            {{ finalMonthCost.discount }}%
                        </v-chip>
                    </th>
                    <td class="text-center font-weight-bold text-green text-h4">
                        {{ avgDiscount }}%
                    </td>
                </tr>
            </tbody>
        </v-table>
    </v-card>
</template>

<style scoped lang="scss">
.v-table.billing-matrix-table table {
    thead {
        th:first-child {
            border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
        }

        th:last-child {
            border-left: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
            border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
        }

        tr > th {
            padding: 0 12px !important;
            white-space: nowrap;
        }
    }
    tbody {
        td:first-child {
            border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
        }

        td:last-child {
            border-left: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
            border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
        }

        tr > td {
            padding: 0 8px !important;
        }
    }
}
</style>
