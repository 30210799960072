<script setup lang="ts">
    import { computed, ref, watch } from 'vue';

    type Props = {
        label?: string;
        min?: Date | string | null;
        clearable?: boolean;
        hint?: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rules?: ((value: any) => string | boolean)[];
    };

    defineProps<Props>();

    const modelValue = defineModel<Date | string | null>();

    const datePickerOpen = ref(false);
    const datePickerValue = ref<Date | null>(null);

    const fieldValue = computed(() => {
        if (!modelValue.value) return null;
        return new Date(modelValue.value).toLocaleDateString();
    });

    function selectDate(date: Date | string | null) {
        datePickerOpen.value = false;
        modelValue.value = date;
        datePickerValue.value = modelValue.value ? new Date(modelValue.value) : null;
    }

    watch(modelValue, (newVal, oldVal) => {
        if (newVal !== oldVal) {
            datePickerValue.value = modelValue.value ? new Date(modelValue.value) : null;
        }
    }, { immediate: true });
</script>

<template>
    <v-menu v-model="datePickerOpen" location="bottom center" :close-on-content-click="false">
        <template v-slot:activator="{ props }">
            <v-text-field
                :model-value="fieldValue"
                :label="label"
                readonly
                color="primary"
                variant="outlined"
                prepend-inner-icon="mdi-calendar"
                v-bind="props"
                :clearable="clearable"
                :hint="hint"
                :persistent-hint="!!hint"
                :rules="rules"
            />
        </template>
        <v-date-picker
            :model-value="datePickerValue"
            :close-on-content-click="false"
            :min="min"
            color="primary"
            class="bg-surface-lighten-1"
            @update:model-value="selectDate"
        />
    </v-menu>
</template>
