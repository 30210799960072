import vuetify from '@/plugins/vuetify';
import { TinyEmitter } from '@/shared/utilities';
import type { Feature } from 'maplibre-gl';
import maplibregl from 'maplibre-gl';
import { v4 } from 'uuid';
import type { App, Component} from 'vue';
import { createApp } from 'vue';
import type { ScreenResponse } from './api';
import ScreenMapPopup from './components/ScreenMapPopup.vue';

type ScreenFeature = Feature & {
    properties: ScreenResponse;
};

type Events = {
    selected: {
        id: number;
        selected: boolean;
    };
};

export class ScreenMapMarker extends TinyEmitter<Events> {
    private DEFAULT_COLOR = '#00B0F6';
    private SELECTED_COLOR = '#072D77';

    private _map: maplibregl.Map;
    private _popup: maplibregl.Popup;
    private _marker!: maplibregl.Marker;
    private _componentInstance: App<Element> | null = null;
    private _coordinates: [number, number];
    private _feature: ScreenFeature;
    private _uuid: string;

    private selected = false;

    constructor(map: maplibregl.Map, coordinates: [number, number], feature: ScreenFeature) {
        const markerId = v4();
        super(`ScreenMapMarker-${markerId}`, true);
        this._uuid = markerId;

        const popupId = `popup-${this._uuid}`;
        this._map = map;
        this._feature = feature;
        this._coordinates = coordinates;

        const titleLength = feature.properties.title.length;
        const maxCharWidthDefault = 24;
        const additionalWidth = Math.max(titleLength - maxCharWidthDefault, 0) * 16;

        this._popup = new maplibregl.Popup({ offset: 25, maxWidth: `${240 + additionalWidth}px` })
            .setHTML(`<div id="${popupId}"></div>`);

        this.createMarker();
    }

    public get id() {
        return this._feature.properties.id;
    }

    public setSelected(selected: boolean) {
        this.selected = selected;
        this.createMarker();
    }

    /** Dispose & cleanup allocated resources */
    public dispose() {
        super.dispose();
        this._componentInstance?.unmount();
        this._popup.remove();
        this._marker.remove();
    }

    private select() {
        this.setSelected(!this.selected);
        this.emit('selected', { id: this.id, selected: this.selected });
    }

    private createMarker() {
        if (this._marker) {
            this.closePopup();
            this._marker.remove();
        }

        const color = this.selected ? this.SELECTED_COLOR : this.DEFAULT_COLOR;
        const marker = new maplibregl.Marker({ color: color })
            .setLngLat(this._coordinates)
            .setPopup(this._popup)
            .addTo(this._map);

        marker.addClassName(`marker-${this._uuid}`);

        marker._element.addEventListener('mouseenter', () => {
            this.openPopup();
        });

        marker._element.addEventListener('mouseleave', () => {
            this.closePopup();
        });

        marker._element.addEventListener('click', (event) => {
            event.stopPropagation();

            this.select();
        });

        this._marker = marker;
    }

    private openPopup() {
        const popupId = `popup-${this._uuid}`;
        const app = createApp(ScreenMapPopup as Component, {
            onClose: () => {
                this.closePopup();
            },
            screen: this._feature.properties,
        });
        app.use(vuetify);
        this._marker.togglePopup();
        app.mount(`#${popupId}`);

        this._componentInstance = app;
    }

    private closePopup() {
        this._componentInstance?.unmount();
        this._marker.togglePopup();
    }
}
