import type { Ref } from 'vue';
import { ref, toRaw, watch } from 'vue';
import type { SimpleCreativeResponse } from '../api';
import { useCreative } from './useCreative';

export function useEditableCreative(creativeId: Ref<string>) {
    const { isLoading, creative } = useCreative(creativeId);

    const editable = ref<SimpleCreativeResponse | null>(null);

    watch(creative, (newVal) => {
        if (newVal && editable.value === null) {
            editable.value = structuredClone(toRaw(newVal));
        }
    }, { immediate: true });

    return {
        isLoading,
        creative: editable,
    };
}
