import type { Ref } from 'vue';
import { ref, watch } from 'vue';
import type { EditMetadataRequest } from '../metadataRequests';
import type { MetadataResponse } from '../metadataResponse';

/**
 * Hook to setup the editable metadata object from the metadata response
 * @param metaData The metadata response
 */
export function useEditableMetaData(metaData: Ref<MetadataResponse | undefined>) {
    const editable = ref<EditMetadataRequest | null>(null);

    // Ensure we setup editable once we have the metadata, and only once
    watch(metaData, (newVal) => {
        if (newVal && editable.value === null) {
            editable.value = {
                industryIds: newVal.industries.map((industry) => industry.industryId),
            };
        }
    }, { immediate: true });

    return editable;
}
